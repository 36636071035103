


.smsf-download {
  background-image: url(/static/images/onboarding-bg.jpg);
  padding: 0px 0 300px 0;



  h1 {
    margin-top: 0px;
  }

  .white-box {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1px;
    padding: 50px 130px;
    margin-top: 50px;
    text-align: center;
    font-weight: 400;
    color: #666;

    .please-verify {
      font-size: 22px;
    }
  }
}