@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.twoWaysTabs {
  background: white;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: $measurement--standard-border-radius;
  overflow: hidden;
}

.twoWaysTabPanel {
  text-align: left;
  padding: ($measurement--grid-unit * 8) $measurement--side-gutter;
  display: flex;
  flex-flow: column nowrap;

  > * {
    align-items: stretch;
  }

  > p {
    order: 0;
  }
}

.imageParagraphsContainer {
  display: flex;
  flex-direction: column;
}

.twoWaysTabPanelImage {
  order: 1;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.buildMyOwnPanel__tabPanelImage {
  max-width: 200px;
}

.tickedBulletList {
  list-style: none;
  padding: ($measurement--grid-unit * 2) 0 0;
  margin: 0;
}

.tickedBulletListItem {
  margin: 0 0 ($measurement--grid-unit * 1) ($measurement--grid-unit * 1);
  padding: 0 0 0 ($measurement--grid-unit * 7);
  position: relative;
  line-height: $measurement--body-list-line-height;

  &:before {
    font-family: $font--brickicons;
    content: "tick";
    position: absolute;
    left: 0;
    color: $color--ticked-bullet-list-icon;
  }
}

.twoWaysTabPanelCTAButtons {
  display: flex;
  flex-flow: column nowrap;
  margin: ($measurement--grid-unit * 8) 0 0;
}

.primaryCta {
  width: 100%;
  margin-bottom: $measurement--grid-unit * 6;
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .twoWaysTabPanel {
    display: block;
    padding: ($measurement--grid-unit * 18) ($measurement--grid-unit * 24);

    > p {
      &:first-of-type {
        margin-top: ($measurement--grid-unit * 10);
      }
    }
  }

  .twoWaysTabPanelHading {
    max-width: 580px;
  }

  .imageParagraphsContainer {
    display: block;
  }

  .twoWaysTabPanelImage {
    float: right;
    width: 350px;
    max-width: 100%;
    margin-left: $measurement--grid-unit * 15;
    margin-bottom: $measurement--grid-unit * 18;
  }

  .buildMyOwnPanel__tabPanelImage {
    width: 210px;
  }

  .tickedBulletList {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  .tickedBulletListItem {
    flex-basis: 48%;
  }

  .twoWaysFinePrint {
    margin-top: $measurement--grid-unit * 3;
    text-align: center;
  }

  .twoWaysTabPanelCTAButtons {
    align-items: center;
    margin-top: $measurement--grid-unit * 11;
  }

  .primaryCta {
    width: auto;
    flex: 0 0 auto;
  }

}
