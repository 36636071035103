

#premium-price-first-alert-modal, #premium-price-second-alert-modal {

  .cancel-button {
    margin-right: 15px;
  }

  @media (max-width: $small) {
    .cancel-button {
      border: 0;
      padding: 0;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
      display: block;
      margin-right: 0;
      span {
        text-decoration: underline;
      }
    }
    .confirm-high-price {
      text-align: center;
      width: 100%;
      display: block;
      padding: 8px 0;
    }
  }
}