@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.button {
  background: transparent;
  border: 1px solid transparent;
  border-radius: var(--bx-ms---button-border-radius);
  cursor: pointer;
  display: inline-block;
  font-family: "Calibre", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  transition-duration: 250ms;
  transition-property: background-color, transform, box-shadow;
  transition-timing-function: ease-in-out;
}

.button:hover, .button:active, .button:focus {
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transform: translateY(-2px);
}

.button[disabled], .button[disabled]:hover {
  box-shadow: none;
  cursor: not-allowed;
  transform: none;
}

.button:focus {
  outline: 0;
}

.extraSmallSize {
  font-size: 20px;
  padding: 8px 16px;
}

.smallSize {
  padding: 12px 20px;
}

.normalSize {
  padding: 16px 32px;
}

@media (min-width: 768px) {
  .extraSmallSize--breakpoint-small {
    font-size: 20px;
    padding: 8px 16px;
  }
  .smallSize--breakpoint-small {
    padding: 12px 20px;
  }
  .normalSize--breakpoint-small {
    padding: 16px 32px;
  }
}

@media (min-width: 992px) {
  .extraSmallSize--breakpoint-medium {
    font-size: 20px;
    padding: 8px 16px;
  }
  .smallSize--breakpoint-medium {
    padding: 12px 20px;
  }
  .normalSize--breakpoint-medium {
    padding: 16px 32px;
  }
}

@media (min-width: 1100px) {
  .extraSmallSize--breakpoint-large {
    font-size: 20px;
    padding: 8px 16px;
  }
  .smallSize--breakpoint-large {
    padding: 12px 20px;
  }
  .normalSize--breakpoint-large {
    padding: 16px 32px;
  }
}

.fullWidth {
  padding-left: 4px;
  padding-right: 4px;
  white-space: normal;
  width: 100%;
}

.addArrow {
  position: relative;
}

.addArrow:after {
  content: "chevron_right";
  font-family: "brickicons";
  font-weight: 400;
  padding-left: 4px;
  vertical-align: bottom;
}

.pulsing :global {
  animation: pulsing 300ms linear 0s infinite alternate;
}

.selectionButton {
  border: 1px solid #d7d7dc;
  color: #57575c;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  padding: 16px;
}

.selectionButton:after {
  color: #d7d7dc;
}

.selectionButton:hover {
  border-color: #fac800;
  color: #41414b;
}

.selectionButton:hover:after {
  color: #fac800;
}
