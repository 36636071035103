.up-down {
  margin: 0 !important;
}

.account-portfolio {
  font-weight: 400;

  .portfolio-holdings-box {
    margin-bottom: 30px;
  }

  .portfolio-summary {
    margin-bottom: 20px;
    border: 1px solid $grey-100;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    .up-down.up {
      color: $orange;

      .fa {
        color: $green;
      }
    }

    @media (max-width: $mobile-large) {
      padding: 20px 0;

      .gray-box:last-child {
        margin: 0;
      }
    }
  }

  @media (max-width: $ipad) {
    .summary-box.col-no-padding-left {
      padding-right: 12px;
    }

    .summary-box.col-no-padding-right {
      padding-left: 12px;
    }

    #portfolio-holdings {
      position: relative;

      .scrolling-cursor {
        position: absolute;
        padding: 8px 10px;
        background-color: rgba(0, 0, 0, 0.40);
        top: 43%;
        right: 0;
        z-index: 1;

        .triangle-right {
          content: " ";
          width: 0;
          height: 0;
          border-left: 10px solid #FFF;
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
        }
      }
    }

    .portfolio-holdings-box {
      overflow-x: scroll;

      .portfolio-holdings {
        min-width: 780px;
        padding-left: 77px;
        font-size: 14px;

        .footer {
          background-color: $white;
          color: $charcoal;
        }

        .sub-header {
          background-color: $grey-50 !important;
          line-height: 50px !important;
          padding: 0 10px !important;

          &.cell {
            text-align: left !important;
          }

          .visible-xs {
            display: inline-block !important;
          }
        }

        .cell {
          text-align: left !important;
          border-right: 0;
        }

        .properties {
          position: absolute;
          z-index: 1;
          top: 1px;
          left: -1px;
          width: 80px;

          .header {
            border-right: 0;
          }

          .sub-header {
            background-color: $grey-50;
          }

          .cell {
            border-right: 0;
            border-color: $white;
            background-color: $pink-50;
          }

          .footer {
            background-color: $salmon;
            color: $white;
            border-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: $mobile-large) {
    #portfolio-holdings {
      margin-right: -15px;
      margin-left: -15px;
    }

    .component-title {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .summary-box.col-no-padding-left {
      padding-right: 2px;
    }

    .summary-box.col-no-padding-right {
      padding-left: 2px;
    }
  }

  .portfolio-holdings {
    border: 0;
    border-top-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    border-color: $grey-100;

    .holdings,
    .properties,
    .yields {
      padding: 0;
    }

    .properties {
      border-left: 1px solid $grey-100;

      .header {
        background-color: $salmon;
        color: white;
        text-align: left;
      }

      .sub-header {
        padding: 0 15px !important;
        background-color: $light-grey-2;
        text-align: left !important;
      }

      .cell {
        text-align: left;
      }
    }

    .holdings {
      .header {
        background-color: $pink-100;
      }

      .sub-header {
        padding: 0;
        background-color: $pink-50;
      }
    }

    .yields {
      .header {
        background-color: $yellow-100;
      }

      .sub-header {
        padding: 0 10px !important;
        background-color: $yellow-50;
      }
    }

    .header {
      padding: 15px;
      color: $charcoal;
      font-size: 18px;
      text-align: center;
      border-right: 1px solid $grey-100;
      border-bottom: 1px solid $grey-100;
    }

    .sub-header {
      line-height: 70px;
      color: $charcoal;
      text-align: center !important;

      span {
        line-height: 16px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .cell {
      padding: 12px 15px;
      border-right: 1px solid $grey-100;
      border-bottom: 1px solid $grey-100;
      background-color: #FFF;
      text-align: right;

      a {
        color: $charcoal;
        text-decoration: underline;
      }
    }

    .footer {
      background-color: $light-grey-2;
      font-weight: 500;
    }
  }

  .portfolio-holdings-table {
    text-align: center;
    font-weight: 400;
    color: $dark-blue;
    vertical-align: middle !important;

    tr:first-child th:first-child,
    tr:first-child th:last-child {
      border-radius: 0 !important;
    }

    .header {
      color: #FFF;
      font-size: 18px;

      th {
        padding: 20px !important;
      }

      .properties {
        background-color: $dark-blue;
        float: none;
      }

      .holdings {
        background-color: #3F7794;
        float: none;
      }

      .yields {
        background-color: $orange;
        float: none;
      }
    }

    .header2 {
      th {
        text-align: center;
        padding: 5px !important;
        vertical-align: middle !important;

        &:first-child {
          padding: 20px !important;
        }
      }

      .properties {
        background-color: $light-grey-2;
        text-align: left;
      }

      .holdings {
        background-color: $light-blue-2;
      }

      .yields {
        background-color: $light-orange;
      }
    }

    tbody {
      tr td:first-child {
        text-align: left;
        padding: 8px 20px;
      }

      tr td {
        vertical-align: middle !important;
        text-align: right;
        padding: 8px;
      }
    }

    tfoot {
      background-color: $light-grey-2;
      font-weight: 500;

      td:first-child {
        padding: 8px 20px;
        text-align: left;
      }

      td {
        padding: 8px;
        text-align: right;
        vertical-align: middle !important;
      }
    }
  }

  .no-portfolio {
    text-align: center;

    .fa {
      font-size: 180px;
      color: #eee;
      margin: 40px 0 20px;
    }

    p {
      margin: 20px 0;
      color: #777;
      font-weight: 400;
    }
  }

  .box-financials {
    .box-financials-value {
      font-size: 50px;
    }
  }

  @media (max-width: $mobile-large) {
    .portfolio-summary {
      border: 0;

      .portfolio-summary-roi {
        padding: 4px !important;
        border: 1px solid $grey-100;

        .icn {
          margin-right: 3px !important;
          padding: 12px !important;
        }
      }

      .portfolio-summary-bricks-value {
        padding: 4px !important;
        border: 1px solid $grey-100;

        .icn {
          margin-right: 3px !important;
          padding: 12px !important;
        }
      }
    }

    .box-financials {
      .gray-box {
        padding: 10px 5px;
        font-size: 10px;
      }

      .box-financials-value {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
}
