//** Custom: Buttons **//
.btn {
  border-radius:36px;
  box-shadow:none;
  font-weight: 500;;
  text-shadow:none;
}

.btn-primary {
  color: #fff;
  background-color: $orange;
  border-color: $orange;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: $orange-hover;
  border-color: $orange-hover;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
  background-color:$orange-hover;
  border-color: $orange-hover;
}
.btn-primary.disabled,
.btn-primary[disabled], {
  background-color:$orange-hover;
  border-color: $orange-hover;
}

.btn-default {
  color: #333;
}
