@import "~src/styles/measurements";
@import "~src/styles/breakpoints";
@import "~src/styles/colors";

.sqmResearchRatingSection {
  padding-top: ($measurement--grid-unit * 20);
  padding-bottom: ($measurement--grid-unit * 20);
}

.sqmResearchRatingSection--inner {
  display: flex;
  flex-flow: column nowrap;
}

.sqmResearchRatingSection--textContent {
  flex: 1;
}

.sqmResearchRatingSection--img {
  margin-bottom: $measurement--grid-unit * 7;
  align-self: flex-start;
  mix-blend-mode: multiply;
}

.sqmResearchRatingSection--disclaimer {
  border-top: 1px solid $color--line-on-white;
  padding-top: $measurement--grid-unit * 4;
}

.sqmResearchRatingSection--textContent--finePrint {
  color: $color--body-text-on-light-bg;
  margin-bottom: $measurement--grid-unit * 4;
  max-width: $measurement--thin-page-container-max-width;
}

.sqmResearchRatingSection--textContent--title > em {
  font-style: normal;
  font-weight: 400;
}

.sqmResearchRatingSection--modal {
  text-align: center;
  max-width: 580px;
}

.sqmResearchRatingSection--modal--greyArea {
  background: $grey-100;
  display: flex;
  padding: $measurement--side-gutter;
  border-radius: $measurement--standard-border-radius;
  text-align: left;
}

.sqmResearchRatingSection--modal--sqmLogo {
  width: 140px;
  margin-right: $measurement--side-gutter;
  mix-blend-mode: multiply;
}

.sqmResearchRatingSection--modal--greyArea--content {
  flex: 1;
  margin: auto 0;
}

.sqmResearchRatingSection--modal--notFinancialAdviserErrorText {
  color: $red;
}

@media (min-width: $breakpoint--small) {
  .sqmResearchRatingSection {
    padding-top: ($measurement--grid-unit * 30);
    padding-bottom: ($measurement--grid-unit * 30);
  }

  .sqmResearchRatingSection--inner {
    flex-flow: row wrap;
  }

  .sqmResearchRatingSection--img {
    width: 146px;
    margin-bottom: 0;
    margin-right: $measurement--grid-unit * 10;
  }
}
