


.media-quote {
  text-align: center;


  @media (max-width: $mobile-large) {
    img {
      margin-bottom: 20px;
    }
  .inner-box {
    height: auto !important;
      margin-bottom: 10px;
  }
  }

  .quote {
  color: #999;
  font-style: italic;
  .inner-box {
    height: 170px;
  }
  }

  img {
    height: 50px;
    &.zoomed {
      height: 64px;
      margin-top: -7px;
    }
  }
}
