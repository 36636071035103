@import "~src/styles/colors";
@import "~src/styles/measurements";

.features {
  display: flex;
}

.amenityList {
  display: flex;
  padding: 0;
  margin: 0;
}

.amenityItem {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: ($measurement--grid-unit * 2);
}

.amenityIcon {
  color: $color--pink-amenity-icon;
}

.amenityIconBlue {
  color: $color--blue-amenity-icon;
}

.amenityAmount {
  font-weight: 500;
  margin-right: ($measurement--grid-unit * 2);
}

.propertyType {
  border-left: 1px solid $color--grey-line;
  padding-left: ($measurement--grid-unit * 4);
  text-transform: capitalize;
}
