

.features-list-new {
  .feature {
    color:$off-black;
    font-size:15px;
    line-height:40px;
    @media (max-width: $small) {
      font-size:13px;
      line-height:26px;
      }
  }
}

.amenities {
  min-height: 411px;
  ul {
    padding-left: 20px;
  }
}


// TO DELETE THIS
.features-list {
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  li {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    margin-top: 10px;
    padding: 6px 10px;
  }
}
