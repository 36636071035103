@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/fonts';

.styledLink {
  color: $color--hyperlink-text;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: $color--hyperlink-text;
    text-decoration: underline;
  }
}

.StyledBodylink {
  font-size: $measurement--body-text-size;
}

.greyLink {
  color: $color--button-cancel-text;
}

.charcoalLink {
  color: $charcoal;
}

.fullWidth {
  display: block;
  text-align: center;
}

.withArrow {

  &:after {
    content: "arrow_forward";
    display: inline-block;
    font-family: $font--brickicons;
    font-size: 90%;
    margin-left: $measurement--grid-unit * 1.5;
    vertical-align: middle;
  }
}
