


.no-bricks-currently-available-box {
  background-color: $light-orange;
  border: 1px solid #CCCCCD;
  font-weight: 500;
  position: relative;
  padding: 12px 4px;
  .the-padding {
    padding: 6px;
    display: inline-flex;
  }
  .icn-brick-orange-large {
    padding: 9px 15px;
    margin-top: 3px;
    @media (max-width: $mobile-large) {
      display: inline-block;
    }
  }
  .no-bricks {
    font-size: 15px;
    margin-top: 6px;
    padding-left: 10px;
  }
}
