


.brick-valuation-bar {
  .brick-valuation-title {
    font-weight: bold;
    font-size: 16px;
  }
  .brick-valuation-value {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    font-weight: 400;
    text-align: right;
  }
  .valuation-date {
  }
  .left-side-no-icon {
    position: relative;
    padding-left: 0;
  }

}
.no-icon {
  padding-left: 15px;
  padding-right: 15px;
}