@import "~src/styles/breakpoints";

.propertiesListWrapper {
  padding-left: 0;
  padding-right: 0;
}

.smartInvestSignupDriverDesktop {
  display: none;
}

.smartInvestSignupDriverMobileTablet {
  margin-top: 30px;
  margin-bottom: 30px;
}

.morePropertiesHintMobileTablet {
  margin: 30px 0;
}

.propertiesListPageFilterAndSort--container {
}

.propertiesListPageFilterAndSort--container:last-child {
}

.propertiesListPageFilterAndSort--label {
}

.propertiesListPageFilterAndSort--selectContainer.propertiesListPageFilterAndSort--selectContainer.propertiesListPageFilterAndSort--selectContainer {
}

.propertiesListPageFilterAndSort--label,
.propertiesListPageFilterAndSort--select.propertiesListPageFilterAndSort--select.propertiesListPageFilterAndSort--select {
  font-size: 1em;
}

@media (min-width: 576px) {
  .propertiesListPageFilterAndSort--container {
  }

  .propertiesListPageFilterAndSort--label {
    flex: 1;
    margin-bottom: 0;
  }

  .propertiesListPageFilterAndSort--selectContainer.propertiesListPageFilterAndSort--selectContainer.propertiesListPageFilterAndSort--selectContainer {
    flex: 4;
  }
}

@media (min-width: 668px) {
  .propertiesListPageFilterAndSort--container {
    padding: 30px 10px;
  }
}

@media (min-width: $breakpoint--small) {
  .morePropertiesHintMobileTablet {
    display: none;
  }
}

@media (min-width: $breakpoint--medium) {
  .smartInvestSignupDriverMobileTablet {
    display: none;
  }

  .smartInvestSignupDriverDesktop {
    display: block;
    margin-bottom: 30px;
  }
}
