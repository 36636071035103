@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.pinkSpacerBubbleConainer {
  background: $color--pink-spacer-bubble-bottom-background;
  position: relative;
  padding: 0 ($measurement--side-gutter);

  &:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 50%;
    background: $color--pink-spacer-bubble-top-background;
  }
}

.pinkBubble {
  position: relative;
  background: $color--pink-spacer-bubble;
  border-radius: $measurement--standard-border-radius;
  margin: 0 auto;
  padding: ($measurement--grid-unit * 15) ($measurement--grid-unit * 12);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  max-width: $measurement--wide-page-container-max-width;
}

.heading {
  color: white;
  flex: 1 1 auto;
}

.getStartedButton {
  margin-top: $measurement--grid-unit * 10;
}

@media (min-width: $breakpoint--small) {
  .pinkBubble {
    flex-flow: row nowrap;
    text-align: left;
    padding: ($measurement--grid-unit * 17) ($measurement--grid-unit * 15);
  }
  .getStartedButton {
    margin-top: 0;
  }
}
