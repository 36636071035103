

.reset-password-background {
  background-color: white;
  height: 100%;
}
.reset-password {
  color: $dark-blue;
  font-size: 16px;
  .white-box {
    background-color: #FFF;
    margin-bottom: 20px;
    padding: 35px;
    padding-top: 0;
    text-align: center;
    form {
      margin: 20px 0 40px;
      .input-box {
        margin-bottom: 15px;
      }
      input {
        padding: 12px;
      }
      .button {
        font-size: 16px;
        margin-top: 20px;
        width: 100%;
        .fa {
          font-size: 22px;
        }
      }
    }
    a {
      text-decoration: underline;
    }
  }
}
