@import '~src/styles/measurements';

.termsPage {
  padding-bottom: $measurement--side-gutter;
}

.termsList {

  li {
    line-height: 1.5;
    margin-bottom: $measurement--grid-unit * 4;
    padding-left: $measurement--grid-unit * 4;
  }
}

.termsSubList {
  padding-top: $measurement--grid-unit * 4;
}
