@import 'src/styles/measurements';
@import 'src/styles/breakpoints';

.pageContainer {
  padding-top: $measurement--grid-unit * 10;
  text-align: center;
}

@media (min-width: $breakpoint--small) {
  .pageContainer {
    padding-top: $measurement--grid-unit * 30;
  }

  .heading br {
    display: none;
  }
}
