.property-returns-calculator-modal {
  text-align: left;
}

.property-returns-calculator-modal__title-property-code {
  color: $property-code-title-color;
}

.property-returns-calculator {
  text-align: center;

  .modal-title {
    font-size: 18px;

    @media(max-width: $mobile-large) {
      font-size: 16px;
      font-weight: bold;
      margin-right: 40px; // force property code onto next line
    }
  }

  .modal-body {
    padding: 0 !important;
  }
}

.property-returns-calculator__title {
  font-size: 15px;
  font-weight: bold;
  padding: 0 0 5px 0;
}

.property-returns-calculator__annual-growth {
  padding: 10px 10px;
  padding-bottom: 0;
}

.property-returns-calculator__annual-growth-input {

  .slider-group {
    text-align: left;

    .percent-input {

      input {

      }

      &::after {
        background-color: $light-gray;
      }
    }
  }
}

.property-returns-calculator__investment-amount {
  padding: 10px 10px;
  padding-bottom: 0;

  .dollar-input {
    display: flex;
    justify-content: center;
    width: 160px;
    margin: 0 auto;
  }

  .dollar-input::before {
    min-width: 30px;
  }
}

.property-returns-calculator__maximal-investment-amount-warning {
  padding-top: 20px;
}

.property-returns-calculator__holding-period {
  padding: 10px 10px;
}

.property-returns-calculator--select-box {
  font-weight: 400;
  color: $dark-blue;
}
.property-returns-calculator--select-box::after {
  border-color: $dark-blue transparent transparent transparent;
}

.property-returns-calculator--select-box-options {
  background: $light-gray;
  border-color: $grey;
  height: auto;
  color: $dark-blue;
  border-radius: 0;
  min-width: 0;
  width: 160px;
}

.property-returns-calculator__view-breakdown-link {
  font-weight: bold;
  padding: 30px 0 20px 0;
  cursor: pointer;
}

.property-returns-calculator__estimated-return {
  background-color: $financial-stat-box-background;
  margin: 10px 0;
  border-radius: var(--bx-radius);
}

.property-returns-calculator__view-breakdown-link--enabled {
  text-decoration: underline;
}

.property-returns-calculator__view-breakdown-link--disabled {
  color: $dark-gray;
  cursor: default;
}
