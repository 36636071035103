//** Custom: Theme **//

@import "~styles/public/fonts";
@import "~styles/public/colors";

/*******************
  Breakpoints
*******************/

$mobile-galaxy-s5: 360px;
$mobile-medium: 375px;
$mobile-large: 425px;
$mobile-landscape: 667px;
$small: 768px;
$medium: 979px;
$ipad: 991px;
$medium-large: 1024px;
$very-large: 1440px;
$extra-large: 1920px;
$huge: 2560px;
$large: 1200px;

$mobile-small-plus-1: 321px;
$mobile-medium-plus-1: 376px;
$mobile-large-plus-1: 426px;
$small-plus-1: 769px;
$ipad-plus-1: $ipad + 1px;

$less-then-iphone-seven: 374px;

// Extra small devices (portrait phones, more than 575px)

$mobile-small: 320px;
$mobile-iphone: 375px;
$mobile-iphone-plus: 414px;
$extra-small--up: 576px;
$mobile-landscape--up: 668px;

// Small devices (landscape phones, more than 767px)
$small--up: 768px;

// Medium devices (tablets, more than 991px)
$medium-device--up: 992px;

// Large devices (desktops, more than 1199px)
$large--up: 1200px;

$very-large--up: 2000px;

$just-below-bootstrap-container-smallest: 767px;

// bootstrap compatibility
$bootstrap-small-max-width: 750px;

$bootstrap-medium: 992px;
$bootstrap-medium-max-width: 970px;

$bootstrap-large: 1200px;
$bootstrap-large-max-width: 1170px;

/*******************
  Common Values
*******************/

$standard-radius: 0;
$standard-border: 1px solid #d5d9dd;
$standard-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
$standard-transition: 0.35s $transition--standard-function;

$property-breadcrumbs-height: 80px;
$property-breadcrumbs-height-mobile: 45px;

$tinyVerticalMargin: 8px;
$smallVerticalMargin: 16px;

$standard-line-height: 1.5;

/*******************
  Global Settings
*******************/
.link-no-hover-style:hover,
.link-no-hover-style:focus {
    color: inherit;
    text-decoration: none;
}

.white-background {
    background-color: #fff;
}
.orange-background {
    background-color: $orange;
}
.orange-background-2 {
    background-color: $pink-100;
}
.grey-background {
    background-color: #f7f8f9;
}
.dark-blue-background {
    background-color: $very-dark-blue;
}

.navbar-brand:focus {
    background: transparent !important;
    outline: none;
}

.capitalize {
    text-transform: capitalize;
}
.text-left {
    text-align: left !important;
}
.text-asterisk {
    font-size: 12px;
    color: #777;
    a {
        text-decoration: underline;
    }
}
.col-no-padding {
    padding: 0px;
}
.col-no-padding-mobile {
    @media (max-width: $small) {
        padding: 0;
    }
}
.col-no-padding-left {
    padding-left: 0px !important;
}
.col-no-padding-right {
    padding-right: 0px !important;
}
.no-xs-padding {
    @media (max-width: $mobile-large) {
        padding: 0;
    }
}
.bold {
    font-weight: 500;
}
.text-underline {
    text-decoration: underline;
}
.text-orange {
    color: $orange !important;
}
.text-red {
    color: #d00 !important;
}
.text-dark-blue {
    color: $dark-blue !important;
}
.text-blue {
    color: $blue !important;
}
.text-light-blue {
    color: $light-blue !important;
}

.grey-line {
    height: 1px;
    background-color: #ddd;
}
.black-line {
    height: 1px;
    background-color: #000;
}

/*******************
  Layout Settings
*******************/

.stack {
    display: flex;
    flex-direction: column;
}

.stack-row {
    display: flex;
    flex-direction: row;
}

.stack-center {
    justify-content: center;
    align-items: center;
}

.stack-between {
    justify-content: space-between;
    align-items: center;
}

/*******************
  Buttons
*******************/

.button {
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: $grey-400;
    padding: 10px 28px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $dark-gray;
    font-size: 1.6rem;
    font-weight: bold;
    outline: 0;
    cursor: pointer;
    .fa {
        position: relative;
        top: 2px;
    }
    transition: all 0.2s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.button-sm {
    padding: 2px 10px;
    font-size: 1.5rem;
}
.right-arrow-button:after {
    content: "\F105";
    font-family: FontAwesome;
    font-size: 20px;
    margin-left: 10px;
    line-height: 0;
    position: relative;
    // top: 2px;
    font-weight: bold;
}
.button:hover {
    transform: translateY(-2px);
}
.button[disabled] {
    cursor: not-allowed;
    border-color: #999;
    color: #999;
}
.button[disabled]:hover {
    color: #999 !important;
}
.button:active {
    filter: brightness(0.9);
}
.button:focus {
    outline: 0;
}
// //// orange
.orange-button {
    background-color: $orange;
    color: #fff;
    border-color: $orange;
}
// .orange-button:hover {
//   background-color: rgba(0,0,0,0);
//   color: $orange !important;
// }
.orange-button[disabled] {
    background-color: rgba(0, 0, 0, 0);
    border-color: $orange;
    color: $orange !important;
}
// .orange-button[disabled]:hover {
//   border-color: #999;
// }
.orange-button:focus {
    color: #fff;
    text-decoration: none;
}
// //// white
.white-button {
    background-color: #fff;
    color: $very-dark-blue;
    border-color: #fff !important;
    &.right-arrow-button:after {
        color: $orange;
    }
}
// red
.red-button {
    background-color: $red;
    color: #fff;
    border-color: $red;
}
.red-button:hover {
    background-color: rgba(0, 0, 0, 0);
    color: $red !important;
    border-color: $red;
}
.red-button[disabled] {
    background-color: rgba(0, 0, 0, 0);
    border-color: $red !important;
    color: $red !important;
}
.red-button[disabled]:hover {
    color: $red !important;
}
// blue
.blue-button {
    background-color: $light-blue;
    color: #fff;
    border-color: $light-blue;
}
.blue-button[disabled] {
    background-color: rgba(0, 0, 0, 0);
    border-color: $light-blue;
    color: $light-blue !important;
}
// very-very-dark blue
.very-very-dark-blue-button {
    background-color: $very-very-dark-blue;
    color: white;
    border-color: $very-very-dark-blue;
}
.very-very-dark-blue-button[disabled] {
    background-color: rgba(0, 0, 0, 0);
    border-color: $very-very-dark-blue;
    color: $very-very-dark-blue !important;
}
// white empty
.white-empty-button {
    background-color: none;
    color: #fff;
    border-color: #fff;
}
.white-empty-button:hover {
    background-color: rgba(0, 0, 0, 0);
    border-color: $orange;
    color: $orange !important;
}
// light blue 1
.light-blue-bright-button {
    background-color: $light-blue-bright;
    color: $dark-blue;
    border-color: $light-blue-bright;
}
.light-blue-bright-button:hover {
    background-color: rgba(0, 0, 0, 0);
    border-color: $dark-blue;
    color: $dark-blue !important;
}
.light-blue-bright-button[disabled] {
    color: #999;
    background: none;
    border-color: #999;
}
// salmon
.salmon-button {
    background-color: $salmon;
    color: $dark-blue;
    border-color: $salmon;
}
.salmon-button[disabled] {
    color: #999;
    background: none;
    border-color: #999;
}
// light blue
.light-blue-button {
    background-color: $light-blue;
    color: $dark-blue;
    border-color: $light-blue;
}
.light-blue-button[disabled] {
    color: #999;
    background: none;
    border-color: #999;
}
// grey empty
.grey-empty-button {
    background-color: none;
    color: #999;
    border-color: #999;
}
.grey-empty-button:hover {
    background-color: rgba(0, 0, 0, 0);
    border-color: $orange;
    color: $orange !important;
}
.grey-empty-button[disabled] {
    &:hover {
        color: #999 !important;
        border-color: #999;
    }
}
.button.facebook-button {
    background-color: cornflowerblue;
    border: 0;
    display: block;
    margin-top: 15px;
    padding: 10px 15px 10px 14px;
    color: #fff;

    &::before {
        content: "\F09A";
        font-family: FontAwesome;
        font-size: 20px;
        line-height: 0;
        position: relative;
        top: 4px;
    }

    &::after {
        content: "Share via Facebook";
        margin-left: 15px;
        position: relative;
        top: 1px;
    }

    &:hover {
        color: #fff !important;
    }
}

// --+-- Buton Style 2: Circle Button --+--

.circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: rgb(236, 236, 236);
    border-radius: 50%;
    i.fa {
        font-size: 3rem;
    }
    &:hover {
        cursor: pointer;
        background-color: rgb(231, 231, 231);
    }
}

// BUTTON LAYOUT

.button-group {
    .button {
        margin-right: 10px;
    }
}

// END BUTTONS

#root {
    background: white;
    height: 100%;
}

h1 {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 300;
    color: $dark-blue;
    @media (max-width: $small) {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 35px;
    }
}

@mixin property-icons {
    ul {
        font-weight: bold;
        margin-left: 0px;
        padding-left: 0px;
        margin-bottom: 0px;
        li {
            display: inline-block;
            margin-right: 20px;
            padding-bottom: 0px;
        }
    }
}

@mixin blur($radius: 3px) {
    -webkit-filter: blur($radius);
    filter: blur($radius);
}

// FORMS
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
    padding: 10px 14px;
    background-color: #f3f4f5;
    border: 1px solid #cdcecf;
    border-radius: var(--bx-radius);
    width: 100%;
    transition: all 0.2s;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
    background-color: #fff;
}

input.large,
select.large {
    font-size: 22px;
    padding: 14px 36px 14px 14px;
    border: 1px solid #d7d7dc;
    background-color: transparent;
    height: unset;
    font-weight: normal;
    width: 100%;
    line-height: inherit;
    -webkit-appearance: auto;
    appearance: auto;
}

input.large::placeholder {
    color: var(--bx-color---grey-500);
}

.decimal-input {
    &::after {
        content: ".00";
        background-color: #f3f4f5;
        border: 1px solid #cdcecf;
        display: inline-block;
        border-left: 0;
        padding: 8px;
        width: 15%;
        text-align: center;
    }

    input {
        width: 85%;
        text-align: right;
    }
}

.percent-input {
    float: right;
    width: 80px;
    display: flex;
    flex-flow: row nowrap;

    &::after {
        content: "%";
        background-color: #f3f4f5;
        border: 1px solid #cdcecf;
        border-left: none;
        padding: 8px 0;
        width: 30%;
        text-align: center;
    }

    input {
        width: 70%;
        text-align: left;
        border-radius: 0;
    }
}

.dollar-input {
    &::before {
        content: "$";
        background-color: #f3f4f5;
        border-radius: 8px 0 0 8px;
        border: 1px solid #cdcecf;
        display: inline-block;
        border-right: 0;
        padding: 10px 4px 10px 4px;
        width: 20%;
        text-align: center;
    }

    input {
        width: 80%;
        border-radius: 0 8px 8px 0;
    }

    &.decimal-input {
        input {
            width: 75%;
        }
    }
}

.leftButton {
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
}

.rightButton {
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

star:after {
    content: "*";
    color: $orange;
    vertical-align: -webkit-baseline-middle;
    line-height: 0;
    font-size: 24px;
    font-weight: 500;
}
select {
    min-width: 200px;
    padding: 10px 15px;
    padding-right: 25px;
    line-height: 1;
    border: 1px solid $grey-300;
    background-color: $white;
    height: 47px;
    -webkit-appearance: none;
    appearance: none;
    font-weight: 500;
    border-radius: var(--bx-radius);
}
.select-box {
    position: relative;
    display: inline-block;
}
.select-box::after {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: $grey-400;
    position: absolute;
    top: 45%;
    right: 10px; // (25 - 5) * 0.5 = 10px
}
.checkbox {
    position: relative;
    padding-left: 25px;
    display: block;
    margin-bottom: 20px;
    input[type="checkbox"] {
        visibility: hidden;
    }
    label {
        padding-left: 14px;
    }
    label.substitute {
        cursor: pointer;
        position: absolute;
        width: 22px;
        min-height: 22px;
        top: -3px;
        left: 0;
        padding: 0;
        background-color: #fff;
        border: 2px solid #999;
        border-radius: 1px;
        @media (max-width: $small) {
            top: 3px;
        }
    }
    input[type="checkbox"]:checked + label.substitute {
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 7px;
            background: transparent;
            top: 4px;
            left: 3px;
            border: 3px solid $dark-blue;
            border-top: none;
            border-right: none;
            border-radius: 2px;
            transform: rotate(-45deg);
        }
    }
    &:hover {
        label.substitute {
            border-color: #bbb;
        }
    }
    input[disabled] + label {
        border-color: #bbb;
    }
    input[disabled] + label.substitute + label {
        cursor: not-allowed !important;
    }
    input[disabled] + label {
        cursor: not-allowed !important;
    }
}

ul.alerts li.alert b {
    text-transform: capitalize;
}

.with-arrow-down {
    position: relative;
    &:after {
        content: " ";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $orange;
        position: absolute;
        bottom: -10px;
        left: 48%;
    }
}

@media (min-width: $extra-large) {
    .col-xlg-8 {
        width: 66.66666667%;
        &.col-xlg-offset-2 {
            margin-left: 16.66666667%;
        }
    }
    .col-xlg-10 {
        width: 83.333333%;
        &.col-xlg-offset-1 {
            margin-left: 8.3888888%;
        }
    }
}

.wrap-the-col {
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

.left-side-icon {
    position: relative;
    padding-left: 40px;
    img {
        position: absolute;
        top: 2px;
        left: 0;
        width: 30px;
    }
}
.left-side-checkbox {
    position: relative;
    padding-left: 24px;
    line-height: 1.3;
    input[type="checkbox"] {
        position: absolute;
        left: 0;
    }
    label {
        display: inline;
    }
}

.modal.in {
    .modal-dialog {
        @media (max-width: $small) {
            padding: 0;
        }
    }
}

// login-form
.login-form {
    input[disabled] {
        background-color: #eee;
        color: #555;
    }
}
