


.as-seen-in {
  background-color: #FFF;
  padding: 13px 0 20px !important;
  text-align: center;
  @media (max-width: $mobile-large) {
    padding: 13px 0 0 !important;

    .my-col-20-percent {
      margin-bottom: 20px;
      width: 33%;
      &.afr-logo {
        img {
          height: 37px;
        }
      }
    }
  }
  .title {
    color: #999;
    font-weight: 500;
    margin-bottom: 10px;
  }
  img {
    height: 42px;
    margin-right: 30px;
  }
  .my-col-20-percent {
    display: inline-block;
    padding: 0 15px;
    text-align: center;
    width: 14%;
    img {
      width: 100%;
    }
    &.afr-logo {
      img {
        height: 70px;
        width: auto;
      }
    }
  }
}