

.sub-header {
  background-color: $dark-blue;
  color: #FFF;
  font-weight: 400;
  padding: 20px 0px;
  .title {
    font-size: 25px;
    span {
      color: $light-blue;
      text-transform: capitalize;
    }
  }
  .content {
    text-align: right;
    .box {
      font-size: 13px;
      font-weight: 500;
      display: inline-block;
      padding: 0px 18px;
      border-right: 1px solid $light-blue;
      vertical-align: middle;
      &:last-child {
        border: none;
      }
      .value {
        display: block;
        color: $orange;
        font-size: 16px;
        text-decoration: underline;
        .icn {
          padding: 1px 8px;
          margin-left: 4px;
        }
      }
      .boxed-value {
        padding: 10px 18px;
        background-color: $very-dark-blue;
        border-radius: 18px;
        margin-left: 8px;
        position: relative;
        top: 1px;
        font-size: 15px;
      }
    }

  }
}
