.icn {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline;
}
.icn-small {
  padding: 4px 10px;
}
.icn-medium {
  padding: 5px 18px;
}

.icn-coins {
  background-image: url(/static/images/icons/icn_coins.png);
}

// .icn-coins-orange {
//   background-image: url(/static/images/icons/icn_stackcoins_orange.png);
// }

.icn-aquisition-costs {
  background-image: url(/static/images/icons/icn_acquisition_costs.png);
}

// .icn-brick-border-orange {
//   background-image: url(/static/img/bx-logo-x-only.svg);
// }

.icn-brick-border {
  background-image: url(/static/img/bx-logo-x-only.svg);
}

.icn-brick-orange {
  background-image: url(/static/img/bx-logo-x-only.svg);
}

.icn-brick-orange-large {
  background-image: url(/static/img/bx-logo-x-only.svg);
}

.icn-debt {
  background-image: url(/static/images/icons/icn_debt.png);
}

.icn-equity {
  background-image: url(/static/images/icons/icn_equity.png);
}

.icn-house {
  background-image: url(/static/images/icons/icn_house.png);
}

// .icn-house-orange {
//   background-image: url(/static/images/icons/icn_house_orange.png);
// }

.icn-roi {
  background-image: url(/static/images/icons/icn_roi.png);
}

.icn-calculator {
  background-image: url(/static/images/icons/icn_calc.png);
}

.icn-monitor-bar-chart {
  background-image: url(/static/images/icons/icn_monitor_bar_chart.png);
}

.icn-bar-chart {
  background-image: url(/static/images/icons/icn_bar_chart.png);
}

.icn-bar-confirm {
  background-image: url(/static/images/icons/icn_confirm.png);
}

.icn-bar-confirm {
  background-image: url(/static/images/icons/icn_confirm2.png);
}

.icn-cross {
  background-image: url(/static/images/icons/icn_cross.png);
}

.icn-doc-locked {
  background-image: url(/static/images/icons/icn_doc_locked.png);
}

.icn-file-chart {
  background-image: url(/static/images/icons/icn_file_chart.png);
}

.icn-gear-white {
  background-image: url(/static/images/icons/icn_prop_gear.png);
}

.icn-gear {
  background-image: url(/static/images/icons/icn_gear.png);
}

.icn-geared {
  background-image: url(/static/images/icons/icn_geared.png);
}

.icn-info {
  background-image: url(/static/images/icons/icn_info.png);
}

.icn-icn-investor-utility {
  background-image: url(/static/images/icons/icn_Investor_utility.png);
}

.icn-note {
  background-image: url(/static/images/icons/icn_note.png);
}

.icn-pending-grey {
  background-image: url(/static/images/icons/icn_pending_grey.png);
}

.icn-pending-pre-orders {
  background-image: url(/static/images/icons/icn_pending_pre_orders.png);
}

.icn-pending-sell {
  background-image: url(/static/images/icons/icn_pending_sell.png);
}

.icn-opened-folder {
  background-image: url(/static/images/icons/icn_opened_folder.png);
}

.icn-tick {
  background-image: url(/static/images/icons/icn_tick.png);
}

.icn-viewdoc {
  background-image: url(/static/images/icons/icn_viewdoc.png);
}

.icn-webinar {
  background-image: url(/static/images/icons/icn_webinar.png);
}

.icn-pin-point {
  background-image: url(/static/images/icons/icn_pin_point.png);
}

.icn-coins-grey {
  background-image: url(/static/images/icons/icn_coins_grey.png);
}

.icn-coins-white {
  background-image: url(/static/images/icons/icn_coins_white.png);
}

.icn-details-grey {
  background-image: url(/static/images/icons/icn_details_grey.png);
}

.icn-details-white {
  background-image: url(/static/images/icons/icn_details_wht.png);
}

.icn-details-blue {
  background-image: url(/static/images/icons/icn_details_blue.png);
}

.icn-file-line-chart-grey {
  background-image: url(/static/images/icons/icn_file_line_chart_grey.png);
}

.icn-file-line-chart-white {
  background-image: url(/static/images/icons/icn_file_line_chart_wht.png);
}

.icn-house-grey {
  background-image: url(/static/images/icons/icn_house_grey.png);
}

.icn-house-white {
  background-image: url(/static/images/icons/icn_house_wht.png);
}

.icn-line-chart_grey {
  background-image: url(/static/images/icons/icn_line_chart_grey.png);
}

.icn-line-chart-white {
  background-image: url(/static/images/icons/icn_line_chart_wht.png);
}

.icn-line-chart-blue {
  background-image: url(/static/images/icons/icn_line_chart_blue.png);
}

.icn-bath {
  background-image: url(/static/images/icons/icn_prop_bath.png);
}

.icn-bed {
  background-image: url(/static/images/icons/icn_prop_bed.png);
}

.icn-car {
  background-image: url(/static/images/icons/icn_prop_car.png);
}

.icn-gear {
  background-image: url(/static/images/icons/icn_gear.png);
}

.icn-house2 {
  background-image: url(/static/images/icons/icn_prop_house.png);
}

.icn-unit {
  background-image: url(/static/images/icons/icn_prop_unit.png);
}

.icn-signup-pc {
  background-image: url(/static/images/icons/icn_signup_pc.png);
}

.icn-pending-white {
  background-image: url(/static/images/icons/icn_pending.png);
}

.icn-tag-white {
  background-image: url(/static/images/icons/icn_tags.png);
}

.icn-quotes-open {
  background-image: url(/static/images/icons/quotes_open.png);
}

.icn-quotes-close {
  background-image: url(/static/images/icons/quotes_close.png);
}

.icn-dollar-border {
  background-image: url(/static/images/icons/icn_dollar_border.png);
}

.icn-chat {
  background-image: url(/static/images/icons/icn_chat.png);
}

.icn-email {
  background-image: url(/static/images/icons/icn_email.png);
}

.icn-phone {
  background-image: url(/static/images/icons/icn_phone.png);
}

.icn-book {
  background-image: url(/static/images/icons/icn_book.png);
}

.icn-investment {
  background-image: url(/static/images/icons/investment.png);
}

.icn-strategy {
  background-image: url(/static/images/icons/icn_strategy.png);
}

.icn-calendar {
  background-image: url(/static/images/icons/icn_calendar.png);
}

.icn-camera {
  background-image: url(/static/images/icons/icn_camera_pos_trimmed.png);
}

.icn-open {
  background-image: url(/static/images/icons/icn_open_trimmed.png);
}

.icn-farm {
  background-image: url(/static/images/icons/icn_farm.png);
}

.icn-farm-2 {
  background-image: url(/static/images/icons/icn_farm_wht.png);
}

.icn-com {
  background-image: url(/static/images/icons/icn_com.png);
}

.icn-com-2 {
  background-image: url(/static/images/icons/icn_com_wht.png);
}

.icn-indu {
  background-image: url(/static/images/icons/icn_indu.png);
}

.icn-indu-2 {
  background-image: url(/static/images/icons/icn_indu_wht.png);
}

.icn-area {
  background-image: url(/static/images/icons/icn_area.png);
}

.icn-area-2 {
  background-image: url(/static/images/icons/icn_area_wht.png);
}