@import 'src/styles/measurements';
@import 'src/styles/breakpoints';

.moreAboutBrickxPropertiesContainer {
  margin: 0 $measurement--side-gutter;
}

.item:first-child {
  margin-top: 0;
}

.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: $measurement--grid-unit * 4;
}

@media (min-width: $breakpoint--small) {
  .itemContainer {
    display: flex;
    justify-content: space-between;
  }

  .item {
    flex: 1;
    margin-right: $measurement--grid-unit * 4;
    margin-top: 0;
  }

  .item__image {
    max-height: 190px;
  }

  .item__description {
    flex: 1;
  }
}
