.modal-dialog {
  @media (max-width: $mobile-large) {
    padding-top: 0 !important;
  }

  .modal-content {
    border-radius: var(--bx-radius) !important;
    overflow: hidden;
  }

  .close-panel {
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    position: relative;
    .close-icon {
      margin-top: 5px;
      margin-right: 5px; 
      background-color: rgba($color: #424242, $alpha: 0.5);
      border-radius: 50%;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      text-align: center;
      width: 40px;
      height: 40px;
    }
  }

  .close-panel:hover {
    color: $light-blue;

    .close-icon {
      opacity: 0.9;
    }
  }

  .modal-header {
    background-color: $very-dark-blue;
    color: #FFF;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      width: 100%;
      font-weight: bold !important;
    }

    .close-icon {
      margin-top: 0px;
      margin-right: 0px; 
    }
  }

  .modal-body {
    position: relative;

    .close-panel {
      position: absolute;
      right: 0;
      top: 0;
      .close-icon {
        right: 5px;
        top: 5px;
      }
    }
  }
}

.modal-info {
  padding-right: 0 !important;

  .modal-dialog {
    background: none;
    height: 100%;
    margin: 0;
    width: 100%;

    .modal-content {
      background: rgba(255, 255, 255, 0.95);
      border-radius: 0;
      box-shadow: none;
      height: 100%;
      position: relative;

      .modal-body {
        .close-modal {
          a {
            color: $red;
            font-size: 24px;
            position: absolute;
            right: 0;
            top: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .header {
          h2 {
            font-weight: normal;
            margin: 0;
            text-align: center;
          }

          h3 {
            color: #777;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 5px 0 40px;
            text-align: center;
          }
        }

        .body {
          margin-top: 20px;

          .form-group {
            margin-bottom: 20px;

            .form-control-static {
              line-height: 30px;

              .up-down {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

.no-padding {
  .modal-body {
    padding: 0;
  }
}

.dark-background {
  .modal-content {
    background: $grey-900;
  }
}

.modal-brickx {
  .modal-header {
    background: #E6E6E6;
    border-bottom: none;
    font-size: 21px;
    padding: 30px;

    h4 {
      color: #676767;
    }
  }

  .modal-content {
    border-radius: 2px;

    .modal-body {
      .panel-choose {
        border: none;
        box-shadow: none;
        margin-bottom: 0;

        .panel-heading {
          background: none;

          h3 {
            line-height: 50px;
            text-align: center;
          }
        }

        .panel-body {
          font-size: 13px;
          font-weight: 400;
          line-height: 28px;
          @media (max-width: 1179px) {
            line-height: 24px;
          }

          .requirement-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 20px;
          }

          ul:last-child {
            border-bottom: solid 1px #e8e8e8;
          }

          form.push {
            margin-top: 255px;
            @media (max-width: $small) {
              margin-top: 0;
            }
          }

          form {
            .radio {
              line-height: 20px;
            }
          }

          .radio-action {
            margin-bottom: 30px;
            margin-top: 40px;
            padding-left: 30px;
          }
        }
      }
    }
  }
}
