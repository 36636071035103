#media {
  @media(max-width: $mobile-large) {
    h1 {
      font-size: 28px !important;
    }
  }
  .media {
    display: flex;
    background-color: rgb(255, 255, 255);
    color: $very-dark-blue;
    padding: 30px;
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    .img {
      background-position: center;
      background-size: cover;
      display: block;
      width: 270px;
      height: 220px;
      margin-right: 20px;
      border-radius: var(--bx-radius);
    }
    .title {
      color: $dark-blue;
      display: block;
      font-size: 30px;
      font-weight: 800;
      line-height: 30px;
    }
    .source-name {
      color: $very-dark-blue;
    }
    .date {
      color: var(--bx-color---azure);
      font-size: 20px;
      font-weight: 800;
    }
    .read-more {
      display: block;
      color: var(--bx-color---primary);
      font-weight: 800;
      font-size: 20px;
      margin-top: 5px;
    }
    ul {
      font-weight: 500;
      margin: 10px 0;
      padding: 0;
      li {
        border-right: 2px solid #BBB;
        display: inline-block;
        line-height: 1;
        padding: 0 15px;
      }
      li:first-child {
        padding-left: 0;
      }
      li:last-child {
        border-right: none;
      }
    }
  }
}

@media(max-width: $ipad) {
  #media {
    .media {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        margin: 20px 0;
        width: 60vw;
      }
      .title {
        font-size: 20px;
        line-height: 1.2;
        margin-top: 10px;
      }
      ul {
        li {
          font-size: 12px;
          &.category {
            border-right: none;
          }
          &:last-child {
            display: block;
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media(max-width: $mobile-large) {
  #media {
    .media {
      padding: 20px 0;
      .lhs {
        width: 100%;
        .img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}