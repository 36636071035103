.survey-terms-and-conditions {
  margin-bottom: 20px;
  padding: 20px 5%;
}

.survey-terms-and-conditions-details {
  text-align: center;
}

.survey-terms-and-conditions-details__title {
  color: #1e4861;
  font-size: 30px;
  // font-family: GothamRounded-Light;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
}

.survey-terms-and-conditions-details__items {
  margin-top: 40px;
  padding-left: 20px;
  text-align: left;
}

.survey-terms-and-conditions-details__sub-items {
  list-style-type: lower-alpha;
  margin-top: 10px;
}

.survey-terms-and-conditions-details__item {
  margin-bottom: 10px;
}

.survey-terms-and-conditions-winner {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
}

.survey-terms-and-conditions-winner__name {
  flex: 1;
  font-size: 20px;
  // font-family: GothamRounded-Light;
}

.survey-terms-and-conditions-winner__date {
  flex: 1;
}

@media (min-width: $mobile-large-plus-1) {
  .survey-terms-and-conditions {
    margin-bottom: 40px;
    padding: 40px 15%;
  }

  .survey-terms-and-conditions-details__title {
    font-size: 40px;
  }

  .survey-terms-and-conditions-winner__name {
    font-size: 25px;
  }
}
