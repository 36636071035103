@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.breadcrumbs {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.breadcrumbs--chevrons {
  color: $color--light-text-on-light-bg;
}

.breadcrumbs--chevron-left {
  margin-right: $measurement--grid-unit * 2;
}

.breadcrumbs--chevron-right {
  margin-left: $measurement--grid-unit * 2;
}

.streamersIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: $measurement--grid-unit * 18;
}

.heading {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.tfnInnerContainer {
  padding: ($measurement--grid-unit * 20) $measurement--side-gutter ($measurement--grid-unit * 24);
}

@media (min-width: $breakpoint--medium) {
  .streamersIcon {
    margin-top: $measurement--grid-unit * 14;
  }

  .heading {
    font-size: $measurement--grid-unit * 12;
  }
}
