@import '~src/styles/measurements';


.dateOfBirthInput_container {
  display: flex;
}

.dateOfBirth_input {
  flex: 3 1 0px;
}

.dateOfBirth_year_input {
  flex: 4 1 0px;
}

.dateOfBirth_input + .dateOfBirth_input {
  margin-left: $measurement--grid-unit * 2;
}
