.brickx-react-markdown {
    h1,
    h2,
    h3,
    h4 {
        margin: 0.5em 0 !important;
        background-color: transparent !important;
        border-left: $orange solid 5px;
        border-bottom: none !important;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        padding-top: 0.3em;
        padding-left: 1em;
        text-indent: -0.7em;
    }

    li:before {
        content: "• ";
        color: $orange;
    }
}
