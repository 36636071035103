@import "../dashboard/Widget";

.my-documents {
  background-color: #FFF;
  color: $dark-blue;
  padding-bottom: 70px;
  h1 {
    font-size: 35px;
    margin-bottom: 40px;
    color: $dark-blue;
  }
}

.document-type-widget {
  border-bottom: 1px solid #ddd;
  padding: 40px 0;
  &:last-child {
    border-bottom: none;
  }
  .title {
    text-decoration: underline;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    color: $blue;
  }
  .description {
    text-align: left;
    font-weight: 400;
    margin-top: 5px;
  }
  .fa {
    font-size: 80px;
    margin-top: 40px;
  }
  .title:hover {
    color: $orange;
  }
}

.document-type-widget.inactive {

  .title, .title:hover {
    color: $dark-blue;
    text-decoration: none;
    cursor: auto;
  }

}
