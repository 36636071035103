@import "~src/styles/colors";
@import "~src/styles/measurements";

$normalCircleSizeMultiplier: 1;
$largeCircleSizeMultiplier: 1.5;

.listItemNumber__counterReset {
  counter-reset: list-item-counter;
}

.listItemNumber {
  display: flex;

  &::before {
    counter-increment: list-item-counter;
    content: counter(list-item-counter);
    display: flex;
    justify-content: center;
    align-items: center;
    width: #{$normalCircleSizeMultiplier}em;
    border-radius: $measurement--circle-border-radius;
    padding: 1px;
    box-sizing: content-box;
    font-size: 16px;
    line-height: $normalCircleSizeMultiplier;
    font-style: normal;
    margin: auto;
  }
}

.listItemNumber--salmon {
  &::before {
    color: white;
    background-color: $color--brand-salmon;
  }
}

.listItemNumber--blue {
  &::before {
    color: white;
    background-color: $color--brand-azure;
  }
}

.listItemNumber--darkblue {
  &::before {
    color: white;
    background-color: $blue-800;
  }
}

.listItemNumber--yolk {
  &::before {
    color: white;
    background-color: $color--brand-yolk;
  }
}

.listItemNumber--charcoal {
  &::before {
    color: white;
    background-color: $color--brand-charcoal;
  }
}

.listItemNumber--blue {
  &::before {
    color: white;
    background-color: $color--brand-blue;
  }
}

.listItemNumber--size-large {
  &::before {
    font-size: 22px;
    width: #{$largeCircleSizeMultiplier}em;
    line-height: $largeCircleSizeMultiplier;
  }
}
