@import 'src/styles/colors';
@import 'src/styles/measurements';

.contentContainer {
  padding: ($measurement--grid-unit * 8) 0 ($measurement--grid-unit * 10);
}

.inputAndButtonContainer {
  align-items: flex-start;
  display: flex;
  margin-top: $measurement--grid-unit * 4;
}

.input {
  flex: 1;
  margin-right: $measurement--grid-unit * 2;
}
