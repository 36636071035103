


.pds-page-latest {
  .row {
    padding-bottom: 20px;
  }
  .formula {
    hr {
      border-top-color: black;
      margin: 5px;
    }
  }
  .color-red {
    color: #FF0000;
  }
  .other-disclosure-documents {
    .documents {
      margin-top: 20px;
      text-align: left;
      text-decoration: underline;
      .document {
        color: $blue;
        font-size: 15px;
        line-height: 40px;
        @media (max-width: $small) {
          font-size:13px;
          line-height:26px;
        }
        .fa {
          color: $blue;
          margin-right: 10px;
        }
      }
      .inactive {
        color: #aaa;
        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }
    }
    .notes{
      .note {
        color: $off-gray;
      }
      a {
        color: $orange;
      }
    }
  }
}

