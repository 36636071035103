.button {
  padding: 0;
}

.button:hover,
.button:active,
.button:focus {
  box-shadow: none;
  transform: none;
}
