@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.welcomeWidget {
  margin: ($measurement--grid-unit * 4) 0;
}

.innerContainer {
  margin: 0 auto;
  max-width: 550px;
  padding: 0 $measurement--grid-unit * 4;
  padding-bottom: $measurement--grid-unit * 8;
  text-align: center;
}

.welcomeWidget--header-image {
  margin: ($measurement--grid-unit * 8) 0 ($measurement--grid-unit * 5);
}

.directDebitInfo {
  border-bottom: solid 1px $color--line-on-white;
  border-top: solid 1px $color--line-on-white;
  padding: 0;
  text-align: left;
}

.directDebitInfoListItem {
  display: flex;
  flex-flow: row nowrap;
  padding: ($measurement--grid-unit * 3);
}

.directDebitInfoLabel {
  color: $color--welcome-widget-term-label-text;
  flex: 0 0 40%;
  font-weight: 400;
}

.directDebitInfoValue {
  color: $color--dark-text-on-light-bg;
  flex: 1 1 60%;
  padding-left: 15%;
}


.highlightedRow {
  background: $color--welcome-widget-alternate-row-background;
  border-bottom: solid 1px $color--line-on-white;
  border-top: solid 1px $color--line-on-white;
}

.welcomeWidget--ctasRow {
  display: flex;
  flex-flow: column nowrap;

  :first-child {
    margin-bottom: $measurement--grid-unit * 2;
  }
}



@media (min-width: $breakpoint--small) {
  .welcomeWidget--header-image {
    margin: ($measurement--grid-unit * 11) 0 ($measurement--grid-unit * 8);
  }
  .innerContainer {
    padding-bottom: $measurement--grid-unit * 11;
  }

  .welcomeWidget--ctasRow {
    flex-flow: row nowrap;
    justify-content: center;

    :first-child {
      margin-bottom: 0;
      margin-right: $measurement--grid-unit * 2;
    }
  }
}
