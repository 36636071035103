#login {

  .button {
    width: 100%;
  }

  .forgot-link {
    margin: 20px 0 -10px;
    text-align: center;
    text-decoration: underline;
  }

  .dont-have-account-box {
    // border: 1px solid #DDD;
    font-size: 20px;
    padding: 20px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto 10px;
      width: 100px;
    }

    a {
      display: block;
      font-size: 16px;
      text-decoration: underline;
    }
  }
}
