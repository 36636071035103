


.math-formula {
  margin-bottom: 20px;
  margin-top: 15px;
  text-align: center;

  .math-formula-box {
    display: inline-block;
    text-align: center;
  }
  .math-formula-line {
    background-color: #000;
    height: 1px;
  }
}
