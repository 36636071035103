.sg-help--font-spacing {
  line-height: $standard-line-height;
  white-space: pre-wrap;
}

.sg-help--text {
  color: $color-body-text;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}
