.flex-row {
	display: flex;
	flex-direction: column;
	margin-left: -15px;
	margin-right: -15px;
}

.flex-col {
	display: flex;
	flex-direction: row;
	@media (min-width: $small--up) {
		flex-direction: column;
	}
}

.flex-col-inner {
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-right: 15px;
	margin-left: 15px;
}

.flex-col-inner--centered {
	justify-content: center;
}

@media (min-width: $ipad-plus-1) {
	.flex-row {
		flex-direction: row;
	}

	.flex-col-1 {
		flex: 1 1 0;
	}

	.flex-col-2 {
		flex: 2 1 0;
	}

	.flex-col-3 {
		flex: 3 1 0;
	}
}
