@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.sectionHeading {
  font-size: $measurement--small-heading-text-size;
}

@media (min-width: $breakpoint--small) {
  .sectionHeading {
    font-size: $measurement--heading-text-size;
  }
}
