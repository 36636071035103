@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

// BrickX Testimonials
// --------------------------------------------------------------------------------

.brickXTestimonials {
  background: $color--testimonials-row-background;
}

.innerContainer {
  display: flex;
  flex-flow: column nowrap;
  max-width: $measurement--wide-page-container-max-width;
  margin: 0 auto;
  padding: 0 ($measurement--grid-unit * 6);
  height: 100%;
}

.getStartedButton {
  align-self: center;
}

.testimonialSlide {
  text-align: center;
  padding: 0 $measurement--side-gutter;
}

.testimonialQuote {
  max-width: 600px;
  margin: 0 auto ($measurement--grid-unit * 4);
}

.testimonialCarousel {
  margin: ($measurement--grid-unit * 14) 0 ($measurement--grid-unit * 10);
}

$dot-size: $measurement--grid-unit * 2;

.testimonialDots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.testimonialDot {
  border: none;
  padding: 0;
  width: $dot-size;
  height: $dot-size;
  margin-right: $dot-size;
  background: $color--testimonials-dot-background;
  border-radius: 50%;

  &:global(.active) {
    background: $color--carousel-active-dot-background;
  }
}
