

.properties-map {
  .properties-map-title {
    margin: 9px 0 4px 0;
  }

  .properties-map-container {
    height:350px;
  }

  .property__map-large {
    position: relative;
  }

  .map__location__stats {
    background: $blue;
    box-shadow: 0px 1px 1px 0px rgba(150,150,150,0.30);
    color: $white;
    position: absolute;
    top: 160px;
    width: 240px;
    z-index: 2;
  }

  .map__location__stats__header  {
    border-bottom: 1px solid #aaa;
    color: $white;
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    margin: 0;
    padding: 10px;
    text-align: center;

    span {
      display: block;
      margin-top: 5px;
    }
  }

  .map__location__stats__data {
    color: $white;
    overflow: hidden;
    padding: 10px 20px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;

      .value {
        font-size: 20px;
      }
    }
  }
}
