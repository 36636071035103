


.glossary-page {
  font-weight: 400;
  h1 {
    color: $dark-blue;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 40px;
    margin-top: 50px;
    text-align: center;
    @media (max-width: $small) {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }
  .intro {
    border-bottom: 1px solid #DDD;
    border-top: 1px solid #DDD;
    padding: 10px 0;
    .icn {
      margin-right: 6px;
    }
  }

  .glossary {
    border: 1px solid #DDD;
    margin-bottom: 50px;
    margin-top: 20px;
    padding: 15px;
    .definition:nth-child(even) {
      background-color: #fafafa;
    }
  }

  .anchor-links {
    ul {
      border-bottom:solid 1px #e8e8e8;
      border-top:solid 1px #e8e8e8;
      list-style-type: none;
      margin: 0;
      margin-bottom: 10px;
      padding: 0;
      li {
        display: inline-block;
        a {
          color: $orange;
          display: inline-block;
          font-weight: 500;
          padding: 10px 15px;
          @media (max-width: $large) {
            padding: 10px 18px;
          }
          &:hover {
            background: #fafafa;
            color: $orange-hover;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
  .definition {
    border-bottom: solid 1px #e8e8e8;
    padding: 20px;
    &:last-child {
      border-bottom: none;
    }

    h4 {
      color: #222;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
    p {
      color: $off-gray;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;
    }
    ul {
      li {
        font-weight: 500;
        margin-bottom: 20px;
        small {
          color: #777;
          font-weight: 400;
        }
      }
    }
  }
  .old-divider {
    background: rgba(6, 51, 82, 0.9);
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
    margin-top: -1px;
    padding: 5px 20px;
    @media (max-width: $mobile-large) {
      border-left: none;
      border-right: none;
    }
  }
}
