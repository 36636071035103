#info-box {
  background-color: #DEF2FE;
  padding: 15px;
  padding-left: 50px;
  position: relative;
  .info-icon {
    height: 22%;
    left: 10px;
    min-height: 30px;
    position: absolute;
      top: 39%;
  }
  .description-info {
    color: $blue;
    font-size: 13px;
    // font-family:$font--gotham-medium;
    font-weight: 500;
  }
}

.settings-blue-notification {
  background-color: #DEF2FE;
  color: $blue;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 18px;
  padding-left: 0;

  &.small {
    padding: 8px;
    padding-left: 0;

    .col-info-icon {
      width: 75px;

      .icn {
        padding: 12px;
      }
    }
  }

  > div {
    display: flex;
  }

  .col-info-icon {
    width: 100px;

    @media (max-width: $mobile-large) {
      width: 80px;
    }

    .icn {
      margin: auto;
      padding: 27px;
    }
  }

  .col-info-description {
    width: 70%;

    @media (max-width: $medium) {
      width: 60%;
    }

    .inner {
      margin: auto;
    }
  }
}
