.flag {
  $open-market-flag-color: #18bc69;
  color: #fff;
  font-weight: 400;
  line-height: 13px;
  padding: 5px;
  padding-bottom: 7px;
  padding-top: 12px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 0px;
  width: 90px;

  div {
    font-size: 18px;
  }

  &.pre-order-flag {
    font-size: 18px;
    div {
      font-size: 11px;
    }
  }

  .triangles-box {
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
    text-align: left;
    top: 7px;
    .lx-triangle {
      border-style: solid;
      border-width: 18px 45px 0px 0px;
      height: 0;
      position: absolute;
      width: 0;
    }
    .rx-triangle {
      border-style: solid;
      border-width: 0 46px 15px 0;
      height: 0;
      margin-left: 44px;
      position: absolute;
      width: 0;
    }
  }
  &.open-market-flag {
    background-color: $open-market-flag-color;
    .lx-triangle {
      border-color: $open-market-flag-color transparent transparent transparent;
    }
    .rx-triangle {
      border-color: transparent $open-market-flag-color transparent transparent;
    }
  }
  &.new-property-flag {
    background-color: $orange;
    .lx-triangle {
      border-color: $orange transparent transparent transparent;
    }
    .rx-triangle {
      border-color: transparent $orange transparent transparent;
    }
  }
  &.coming-soon-flag {
    background-color: $coming-soon-property-color;
    color: #fff;
    .lx-triangle {
      border-color: $coming-soon-property-color transparent transparent
        transparent;
    }
    .rx-triangle {
      border-color: transparent $coming-soon-property-color transparent
        transparent;
    }
  }
  &.pre-order-flag {
    background-color: $blue;
    .lx-triangle {
      border-color: $blue transparent transparent transparent;
    }
    .rx-triangle {
      border-color: transparent $blue transparent transparent;
    }
  }
  &.sold-property-flag {
    background-color: $grey;
    .lx-triangle {
      border-color: $grey transparent transparent transparent;
    }
    .rx-triangle {
      border-color: transparent $grey transparent transparent;
    }
  }
}
