@import '~src/styles/measurements';
@import '~src/styles/embellishments';

$transition--standard-duration: 250ms;
$transition--slow-duration: 350ms;
$transition--standard-function: ease-in-out;
$transition--standard-transition: $transition--standard-duration $transition--standard-function;

@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from { opacity: 0.5; }
  to { opacity: 0.2; }
}

@keyframes eggTimer {
  0% { transform: rotate(180deg); }
  40% { transform: rotate(180deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes wipeDownwards {
  0% { height: 100%; }
  40% { height: 0%; }
  60% { height: 0%; }
  100% { height: 100%; }
}

@keyframes scaleBulge {
  from { transform: scale(1); }
  to { transform: scale(1.3); }
}

@keyframes bobUpAndDown {
  from { transform: translateY(-$measurement--grid-unit); }
  to { transform: translateY($measurement--grid-unit); }
}

@mixin animation--infinite-pulsing() {
  :global {
    animation: pulsing 300ms linear 0s infinite alternate;
  }
}

@mixin animation--egg-timer-spin() {
  :global {
    animation: eggTimer 1.25s linear 0s infinite alternate;
  }
}

@mixin animation--wipe-downwards() {
  :global {
    animation: wipeDownwards 2.5s linear -0.55s infinite;
  }
}

@mixin animation--gentle-scale-bulge() {
  :global {
    animation: scaleBulge 0.9s $transition--standard-function 0s infinite alternate;
  }
}

@mixin animation--bob-up-and-down() {
  :global {
    animation: bobUpAndDown 500ms $transition--standard-function 0s infinite alternate;
  }
}

@mixin animation--move-up-and-fade-in($delay: 0ms, $duration: $transition--standard-duration) {
  :global {
    animation: moveUpAndFadeIn $duration $transition--standard-function;
    animation-delay: $delay;
    animation-fill-mode: both;
  }
}

@mixin animation--move-down-and-fade-out($delay: 0ms, $duration: $transition--standard-duration) {
  :global {
    animation: moveDownAndFadeOut $duration $transition--standard-function;
    animation-delay: $delay;
    animation-fill-mode: both;
  }
}
