.carousel {
  z-index: auto;
  font-size: 20px;
  &:hover {
    .carousel__nav-prev, .carousel__nav-next {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

.carousel__nav-prev, .carousel__nav-next {
  position: absolute;
  top: 45%;
  padding: 8px;
  border: none;
  background: rgba(0, 0, 0, 0);
  color: $white;
  opacity: 1;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  width: 48px;
  height: 48px;
  transition: background .3s;
  &.disabled {
    display: none;
  }

}

@media (max-width: 1200px) {
  .carousel__nav-prev, .carousel__nav-next {
    background: rgba(0, 0, 0, 0.5);
  }
}

.carousel__nav-prev {
  .fa {
    transform: translateX(-1px);
  }
}

.carousel__nav-next {
  .fa {
    transform: translateX(1px);
  }
}

.carousel__nav-prev {
  left: 0;
}

.carousel__nav-next {
  right: 0;
}

.carousel-background-image-slide {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.carousel-image-slide {
  width: 100%;
  height: 60vh;
  max-height: 620px;
  object-fit: cover;
  object-position: center;
}

.carousel--not-loaded > *:not(:first-child) {
  display: none;
}

@media (min-width: $mobile-large-plus-1) {
  .carousel-background-image-slide {
    height: 300px;
  }
}

@media (min-width: $small-plus-1) {
  .carousel__nav-prev, .carousel__nav-next {
    top: 45%;
  }
}


/*******************
  Owl Carousel Hacks
*******************/

.owl-hidden {
  opacity: inherit !important;
}
