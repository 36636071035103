@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.toasts {
  align-items: center;
  bottom: 24px;
  display: flex;
  flex-direction: column-reverse;
  left: 24px;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  right: 24px;
  top: 65px;
  user-select: none;
  z-index: 100001;
}

.toast {
  background: #41414b;
  border-radius: var(--bx-ms---standard-border-radius);
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  list-style: none;
  margin-bottom: 16px;
  max-width: 350px;
  padding: 16px;
  pointer-events: auto;
  position: relative;
  user-select: auto;
  width: 85%;
}

.toast :global {
  animation: moveUpAndFadeIn 250ms ease-in-out;
  animation-delay: 0ms;
  animation-fill-mode: both;
}

.toast:first-child {
  margin: 0;
}

.icon {
  margin-right: 16px;
}

.icon-error {
  color: #ff6560;
}

.icon-warning {
  color: #6eb4f0;
}

.icon-success {
  color: #2fc13f;
}

.exitAnimation :global {
  animation: moveDownAndFadeOut 250ms ease-in-out;
  animation-delay: 0ms;
  animation-fill-mode: both;
}

.toastText {
  flex: 1 1 auto;
}

.dismissToastButton {
  color: #b3b3b8;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 16px;
}

.dismissToastButton:hover {
  color: white;
  text-decoration: none;
}

.dismissToastButton:active {
  color: #ff8282;
}

@media (min-width: 768px) {
  .toasts {
    align-items: flex-start;
  }
  .toast {
    max-width: 400px;
  }
}
