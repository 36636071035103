@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.faqListing {
  margin-top: $measurement--grid-unit * 12;
}

.faqItem {
  display: flex;
  flex-flow: column nowrap;
  padding: ($measurement--grid-unit * 8) 0;
  border-top: 1px solid $color--line-on-white;
}

.faqItem--title {
  flex: 1;
  margin-bottom: $measurement--grid-unit * 4;
}

.faqItem--paragraph {
  flex: 1;
}

@media (min-width: $breakpoint--small) {
  .faqListing {
    margin-top: $measurement--grid-unit * 10;
  }

  .faqItem {
    flex-flow: row nowrap;
  }

  .faqItem--title {
    max-width: 300px;
    margin-bottom: 0;
  }
}
