@import '~src/styles/colors';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';
@import '~src/styles/measurements';

.infoBox {
  border: 2px solid $blue-400;
  background-color: $blue-50;
  display: flex;
  flex-flow: row nowrap;
  padding: ($measurement--grid-unit * 5);
  padding-left: 0;
  border-radius: var(--bx-ms---widget-border-radius);

}

.infoBox--critical {
  border: 2px solid $color--input-border-error;
  background-color: $pink-50;
}

.description {
  color: $color--info-box-text;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  font-size: $measurement--body-text-size;
}

.icon {
  color: $color--info-box-icon;
  display: flex;
  flex: 0 0 auto;
  font-size: 32px;
  width: 80px;
}

.icon--critical {
  color: $color--input-border-error;
}

.centered {
  margin: auto;
}

.noLeftMargin {
  margin-left: 0;
}
