@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.heroVideoSection {
  background: $color--hero-video-background;
  height: calc(100vh - #{$measurement--header-height});
  display: flex;
  padding: 0 ($measurement--side-gutter);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: url('/static/img/photo-group-of-smart-investors.jpg') center center no-repeat;
    background-size: cover;
    opacity: 0.2;
  }
}

.innerContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
}

.title {
  color: white;
  margin: ($measurement--grid-unit * 2) 0 ($measurement--grid-unit * 5);
}

.subtitle {
  color: $color--hero-video-subtitle;
}

@media (min-width: $breakpoint--small) {
  .heroVideoSection {
    height: 650px;
  }
  .title {
    margin: ($measurement--grid-unit * 2) 0 ($measurement--grid-unit * 10);
  }
}

@media (min-width: $breakpoint--large) {
  .heroVideoSection {
    height: calc(100vh - $measurement--header-height--breakpoint-large);
  }
}
