


#media-enquiries {
  padding-bottom: 40px;


  @media (max-width: $small) {
    .me-image {
      margin-bottom: 15px;
    }
  }
  .me-image {
    background-image: url("/static/images/media-enquiries.jpg");
    background-position: center;
    background-size: cover;
    height: 275px;
  }
}
               