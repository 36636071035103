.feedback {
  margin: 30px 0px;
  padding: 15px;
}

.feedback__thumbs-icon {
  font-size: 50px;
  cursor: pointer;
  margin: 0 15px;
  transition: all .2s $transition--standard-function;
}

.feedback__thumbs-icon:hover {
  transform: scale(1.1);
}

.feedback__thumbs-icon--up {
  color: $blue;
}

.feedback__thumbs-icon--down {
  color: $blue;
}
