@import '~src/styles/colors';
@import '~src/styles/fonts';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.vedaRetryContainer {
  display: flex;
  flex-flow: column nowrap;
  max-width: 760px;
}

@media (min-width: $breakpoint--small) {
  .vedaRetryContainer {
    align-items: flex-start;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .vedaRetryContainer--leftColumn {
    flex: 1;
  }

  .vedaRetryContainer--rightColumn {
    flex: 1;
    margin-bottom: 0;
    margin-left: $measurement--side-gutter;
    margin-top: $measurement--grid-unit * 6;
  }

  .divider {
    display: none;
  }
}
