.react-modal-wrapper {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, .65);
}

.react-modal {
    position: relative;
    top: 30px;
    display: block;
    max-width: 1140px;
    margin: 0 auto;
    color: white;
    background: $very-dark-blue;
}

.react-modal--light {
  background: none;
}

.react-modal__header {
    border-bottom: 1px solid #e8e8e8;
}

.react-modal__close-icon {
  position: absolute;
  right: 0;
  z-index: 1;
  margin-right: 16px;
  margin-top: 16px;
  color: $light-blue;
  font-size: 24px;
  line-height: 1;
  opacity: 0.5;
  cursor: pointer;
}

.react-modal__close-icon--light {
  color: #41414b;
}

@media (max-width: 1140px) {
    .react-modal {
        margin: 10px;
        top: 10px;
    }
}
