.property-breadcrumbs {
  background-color: $dark-blue;
  font-weight: 400;
  // height: $property-breadcrumbs-height;
  color: #fff;
  overflow: hidden;

  .badge-price-lable {
  }
  .badge-price {
    margin: 0 10px 0 5px;
    border-radius: 18px;
    position: relative;
    top: 1px;
    font-size: 15px;
  }
  .icn {
    margin-right: 6px;
    padding: 14px;
  }
  .arrow.arrow-left {
    float: left;
    left: -10px;
  }
  .arrow-right {
    float: right;
    left: 10px;
  }
  .arrow {
    color: #fff;
    font-size: 30px;
    padding: 0 4px;
    position: relative;
  }

  .property-breadcrumbs-wrapper {
    background: $dark-blue;
    &.affix {
      position: fixed !important;
      top: 0;
      width: 100%;
      z-index: 100;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }
  }
  .property-address-box-mobile {
    margin-top: 18px;
    display: flex;
    align-items: center;
    .return-button {
      font-size: 3rem;
      margin-right: 10px;
      color: $white;
    }
    .property-code {
      color: $light-blue;
      font-size: 2rem;
    }
    @media (max-width: $mobile-large) {
      margin-top: 2px;
      .property-code {
        color: $light-blue;
        font-size: inherit;
      }
    }
  }
  .property-address-box {
    margin-top: 12px;
    .all-properties {
      font-size: 12px;
      margin-bottom: 4px;
      a {
        color: #fff;
        text-decoration: underline;
        .arrow-left {
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 7px solid $orange;
          display: inline-block;
          margin-right: 5px;
          position: relative;
          top: 1px;
        }
      }
    }
    .property-code,
    .property-address {
      font-size: 21px;
    }
    .property-code {
      color: $light-blue;
    }
    .property-address {
      margin-left: 10px;
    }
    .sub-row::after {
      content: " ";
      display: table;
      clear: both;
    }
    @media (max-width: $medium) {
      margin-top: 17px;
      .property-code,
      .property-address {
        font-size: 15px;
      }
    }
    @media (max-width: $mobile-large) {
      margin-top: 7px;
      .property-code {
        font-size: 20px;
      }
      .property-address {
        font-size: 11px;
      }
      .arrow.arrow-left {
        top: -6px;
      }
    }
  }

  .properties-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 25px;
  }

  .num-of-investors {
    .fa {
      margin-right: 5px;
    }
    border-right: 1px solid #9fb7c6;
    margin-right: 12px;
    padding: 1px 12px;
    font-weight: 500;
  }

  @media (max-width: $mobile-large) {
    // height: $property-breadcrumbs-height-mobile;
    .badge-price {
      font-size: 13px;
    }
    .property-breadcrumbs-wrapper {
    }
    .properties-info {
      padding-top: 8px;
    }
    .num-of-investors {
      display: block;
      padding: 0;
      margin: 0;
      padding-right: 46px;
      padding-bottom: 2px;
    }
    .brick-price {
      display: block;
    }
  }

  @media (max-width: $small) {
    .brick-price {
      padding-right: 15px;
    }
  }

  .brick-price {
    display: flex;
    align-items: center;
    font-weight: 500;
    .icn-brick-orange-large {
      vertical-align: middle;
      position: static;
      display: inline-block;
    }
    @media (max-width: $medium) {
      .arrow.arrow-right {
        top: -9px !important;
      }
    }
    @media (max-width: $mobile-large) {
      .arrow.arrow-right {
        top: -9px !important;
      }
    }
  }

  .share-button {
    float: right;
    border-radius: 20px;
  }

  .property-address-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .share-button-mobile {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      .fa {
        font-size: 1.3rem;
        transform: translate(1px, -1px);
      }
    }
  }
}
