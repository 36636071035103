@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.announcementsContainer {
  padding: ($measurement--grid-unit * 20) $measurement--side-gutter;
  display: flex;
  flex-flow: column nowrap;
}

.subtitle {
  color: $color--announcments-subtitle;
}

.image {
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-bottom: ($measurement--grid-unit * 6);
}

@media (min-width: $breakpoint--small) {
  .announcementsContainer {
    padding-top: 180px;
    padding-bottom: 220px;
  }
  .image {
    width: auto;
    height: 230px;
    margin-right: auto;
  }
}

@media (min-width: $breakpoint--medium) {
  .announcementsContainer {
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
  }

  .content {
    max-width: 500px;
    flex: 1 1 60%;
    margin-right: ($measurement--grid-unit * 20);
  }

  .image {
    margin: auto;
    height: 300px;
  }
}
