

.account-settings {
  color: $dark-blue;


  .settings-notification {
    border: solid 1px $red;
    color: $red;
    padding: 12px 12px 8px 12px;
    background: #fff2f3;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }

  clear {
    clear: both;
    display: block;
  }
  .account-note {
    padding: 12px 12px 8px 12px;
    a {
      color: $orange-active;
      text-decoration: underline;
      &:hover {
        color: $orange-active-hover;
      }
    }
    @media (max-width: $small) {
        margin-top: 20px;
    }
  }
  .form-row {
    padding: 30px 0;
    border-top: 1px solid #DDD;
    .form-message__container .form-message__item {
      margin-top: 0;
      margin-bottom: 8px;
    }
    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 400;
    }
  }

  .details-table {
    tr td, div tr td {
      padding: 4px 8px;
      border: 0;
      &:first-child {
        font-weight: 500;
      }
    }
  }

  form {
    .sub-label {
      color: #777;
      font-size: 12px;
    }
    .form-input {
      margin-bottom: 10px;

      input[type=text], input[type=number] {
        padding: 8px 14px;
        background-color: #F3F4F5;
        border: 1px solid #CDCECF;
        width: 100%;
      }
      input[type=text]:focus, input[type=number]:focus {
        background-color: #FFF;
      }
    }
    .button {
      padding: 7px 22px;
      font-weight: 500;
    }

  }
}
