@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.pdsButton {
  margin-bottom: $measurement--grid-unit * 4;
}

@media (min-width: $breakpoint--small) {
  .buttonContainer {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .pdsButton {
    margin-bottom: 0;
    margin-right: $measurement--grid-unit * 4;
  }
}
