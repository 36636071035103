/*******************
  Login Page
*******************/
.login {
  height: 100%;
  margin-top:40px;
  position: relative;
  text-align: center;
  h4 {
    margin-bottom:40px;
    margin-top:20px;
    span {
      border-bottom:solid 1px #ddd;
      padding-bottom:10px;
      padding-left:20px;padding-right:20px;
    }
  }
  .btn {
    line-height:24px;
  }
}





.signup-background {
  background-position: center;
  background-size: cover;
  min-height: 100%;
  padding-bottom: 30px;
}





.verify-content {
  p {
    line-height: 26px;
    margin-bottom: 40px;
    text-align: justify;
    &:last-child {
      font-weight: 500;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  form {
    button {
      border-radius: 36px;
      line-height: 40px;
      width: 200px;
    }
  }
}

.login__logo {
  display: block;
  height:50px;
  margin: 40px auto 40px;
}

.login__form {
  margin: 0 auto;
  text-align: center;

  .form-group {
    position: relative;
  }

  .form-control {
    padding-right: 40px;
  }
}

.login__extra-action,.login__extra-join {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 33px 0;
  p {
    margin: 33px 0 56px 0;
  }
  .msg-return {
    margin:0px !important;
    margin-top:20px !important;
  }
}
.login__extra-join {
  background:#fff;
  display:block;
  line-height:50px;
}

.login__footer {
  font-size: 13px;
  text-align: center;

  a {
    color: #777;
    font-size:13px;
    font-weight: 400;
  }

  li {
    display: inline;
    list-style: none;
  }

  li:after {
    color: #999;
    content: '|';
    margin: 20px;
  }

  li:last-child:after
  {
    content: '';
    margin: 20px;
  }
}

/*******************
  Signup Page
*******************/

.login h5 {
  color:#fff;
  font-size:27px;
  font-weight: 500;
  margin-bottom:30px;;
  margin-top:0px;
  @media (max-width: $small) {
    font-size:17px;
    margin-bottom: 15px;
  }
}



.invalid-email {
  border: solid 1px #D0021B;
  border-radius: 2px;
  color: #D0021B;
  display: none;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: -10px;
  margin-top: 20px;
  padding: 10px 12px 6px;
}
.signup-benefits {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  .col-xs-6 {
    line-height: 36px;
    @media (max-width: $small) {
      line-height: 28px;
    }
  }
  .fa {
    color :$orange;
  }
}
.signup__extra {
  color:$white;
  font-size:15px;
  font-weight: 500;
  margin-top:20px;
  a {
    color:$white;
  }
}

.help-block {
  color:$red;
  display:none;;
  font-size:13px;
  font-weight: 500;
  margin-top:10px !important;
}

/*******************
  Choose Page
*******************/
.choose-page {
  .choose__logo {
    height:48px;
    margin-top:40px;
  }
  h1 {
    color:#fff;
    font-size:27px;
    font-weight: 500;
    margin-bottom:40px;;
    margin-top:40px;
    @media (max-width: $small) {
      font-size:19px;
    }
  }
  .panel-choose {
    border:none;
    box-shadow: 0px 5px 12px rgba(0,0,0,0.3);
    opacity:1;
    .panel-heading {
      background:$orange;
      border-color:$orange;
      border-radius:0;
      .panel-title {
        color:$white;
        font-size:18px;
        line-height:32px;
        margin-top:0;
        text-align:center;
        small {
          font-size:13px;
        }
      }
    }
    .panel-body {
      padding:0;
      .requirement-title {
        margin-bottom:10px;
      }
      .requirement-title.retail-investor {
        line-height:30px;
      }
      .requirements {
        background:#f7f7f7;
        border-bottom:solid 1px #e8e8e8;
        min-height:160px;
        padding:20px;
        padding-bottom:5px;
        @media only screen and (min-width:979px) and (max-width: 1179px)  {
            min-height:206px;
        }
        @media (max-width: $small) {
          min-height:0px;
        }
        h4 {
          font-weight: 500;
        }
        ul li {
          font-size:13px;
          font-weight: 400;
          line-height:24px;
        }
        .radio-action {
          margin-top:20px;
          padding-left:40px;
        }
      }
      a:hover {
        cursor:pointer;
      }
    }
  }
  .action {
    padding:20px;
  }
}
.steps {
  color:$white;
  font-size:15px;
  font-weight: 500;;
  margin-bottom:40px;
  span {
    display:block;
    font-size:36px;
  }
  .steps-complete {
    color:$white;
    opacity:0.4;
  }
  .steps-active {
    color:$orange;
  }
}
.divider-steps {
  background:#aaa;
  display:block;
  height:1px;
  margin-top:40px;
}

#modal-wholesale {
  .modal-header {
    background:$orange;
    border-bottom:none;
    font-size:21px;
    padding:30px;
    h4 {
      color:$white;
    }
  }
  .modal-content {
    border-radius:2px;
    .modal-body {
      .panel-choose {
        border:none;
        box-shadow:none;
        margin-bottom:0;
        .panel-heading {
          background:none;
          h3 {
            line-height:50px;
            text-align:center;
          }
        }
        .panel-body {
          font-size:13px;
          font-weight: 400;
          line-height:28px;
          @media (max-width: 1179px) {
            line-height:24px;
          }
          .requirement-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom:20px;
          }
          ul:last-child {
            border-bottom:solid 1px #e8e8e8;
          }
          form.push {
            margin-top:255px;
            @media (max-width: $small) {
              margin-top:0px;
            }
          }
          form {
            .radio {
              line-height:20px;
            }
          }
          .radio-action {
            margin-bottom:30px;
            margin-top:40px;
            padding-left:30px;
          }
        }
      }
    }
  }
}
#modal-criteria {
  .modal-header {
    background:#fafafa;
    padding:30px;
  }
  .modal-content {
    border-radius:2px;
  }
  .modal-body {
    p {
      font-weight: 400;
    }
    ul {
      margin-bottom:20px;
      margin-top:20px;
      li {
        font-size:13px;
        font-weight: 400;
        line-height:26px;
      }
    }
  }
}

// Signup Modal
#modal-signup {
  .modal-header {
    background:#fafafa;
    padding:30px;
    text-align:center;
    .modal-title {
      font-size:22px;
      line-height:40px;
      a {
        color:$orange;
      }
    }
  }
  .modal-content {
    border-radius:2px;
  }
  .modal-body {
    padding:0px;
    .form-control {
      border-width: 2px;
    }
  }
  .signup-benefits-modal {
    margin:0 auto;
    margin-top:20px;
    padding-left:25px;
    .table {
      margin-bottom:0px;
    }
    .table tr td {
      border:none;
      color:#444;
      font-size:15px;
      font-weight: 400;
      padding-bottom:8px;
      padding-top:8px;
      @media (max-width: $small) {
        font-size:18px;
      }
      .fa {
        color:$orange;
      }
    }
  }
  .modal-form {
    padding:40px 100px;
    padding-top:20px;
    .form-control {
      border-width:2px;
    }
  }
  .modal-form-action {
    background:#f1f1f1;
    padding:40px;
  }
}
