@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.heading {
  color: $color--dark-text-on-light-bg;
  font-family: $font--bx-standard;
  font-size: $measurement--heading-text-size;
  font-weight: $measurement--heading-font-weight;
  letter-spacing: -0.4px;
  letter-spacing: -0.2px;
  line-height: $measurement--heading-line-height;
  margin: 0;
}

// Sizing is based on:
// docs.google.com/spreadsheets/d/1xgFKwgpmZZHEDMrEmzngZaW5VwO1Xj6hrfVgdbtn8L4
.heading--text-size-large {
  font-size: $measurement--large-heading-text-size;
}
.heading--text-size-small {
  font-size: $measurement--small-heading-text-size;
  font-weight: $measurement--small-heading-font-weight;
  letter-spacing: 0;
}
.heading--text-size-x-small {
  font-size: $measurement--x-small-heading-text-size;
  font-weight: $measurement--small-heading-font-weight;
  letter-spacing: 0;
}


.heading--align-center {
  text-align: center;
}
.heading--align-left {
  text-align: left;
}
.heading--align-right {
  text-align: right;
}

.heading--color-salmon {
  color: $color--brand-salmon;
}



// .heading--small in Jamie's designs:
.subHeading {
  font-size: $measurement--small-heading-text-size;
  font-weight: $measurement--small-heading-font-weight;
  letter-spacing: -0.2px;
}

// .heading--large in Jamie's designs
.heroText {
  font-size: $measurement--large-heading-text-size;
}

.pageHeading {
  font-weight: $measurement--small-heading-font-weight;
  padding: ($measurement--grid-unit * 8) 0 ($measurement--grid-unit * 6);
  text-align: center;

  @media (min-width: $breakpoint--small) {
    padding: ($measurement--grid-unit * 10) 0 ($measurement--grid-unit * 8);
  }
}
