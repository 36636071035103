@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/embellishments';


.home {
  // background: gold;
}

.innerContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: $measurement--wide-page-container-max-width;
}

// Header video banner
.headingVideoLinksRow {
  display: flex;
  flex-flow: column nowrap;
}

.headingVideoLinksRow--getStartedButton {
  white-space: nowrap;
}

.headingVideoLinksRow--learnMoreButton {
  margin-top: $measurement--grid-unit * 2;
  white-space: nowrap;
}

@media (min-width: $breakpoint--small) {
  .headingVideoLinksRow {
    flex-flow: row nowrap;
  }
  .headingVideoLinksRow--learnMoreButton {
    margin: 0 0 0 $measurement--grid-unit * 2;
  }
}


// Properties Banner
// --------------------------------------------------------------------------------

.propertiesBanner {
  padding-bottom: ($measurement--grid-unit * 16);
  padding-top: ($measurement--grid-unit * 16);
}

.propertyCardsImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.propertyBodyCtasRow {
  display: block;
}

.propertyBodyCta {
  margin-bottom: $measurement--grid-unit * 2;

  &:last-child {
    margin: 0;
  }
}

@media (min-width: $breakpoint--small) {
  .propertiesBanner {
    padding-bottom: ($measurement--grid-unit * 25);
    padding-top: ($measurement--grid-unit * 25);
  }

  .propertiesInner {
    display: grid;
    grid-column-gap: ($measurement--grid-unit * 2);
    grid-template-areas:
      "title sidebar"
      "main sidebar";
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
  }

  .propertiesTitle {
    grid-area: title;
    text-align: left;
  }

  .propertyBodyContent {
    grid-area: main;
  }

  .propertyCardsImage {
    align-self: center;
    grid-area: sidebar;
    justify-self: end;
    margin: 0;
  }

  .propertyBodyCtasRow {
    display: flex;
  }

  .propertyBodyCta {
    flex: 1;
    margin: 0 ($measurement--grid-unit * 2) 0 0;
    width: auto;
  }
}


// Why choose brickx
// --------------------------------------------------------------------------------
.whyChooseBrickX {
  background-color: $grey-100;
  padding: ($measurement--grid-unit * 15) 0;
  position: relative;
  text-align: center;

  & > * {
    position: relative;
  }
}

.whyChooseBrickXInner {
  padding: 0 ($measurement--side-gutter);
}

.threeIllustratedPoints {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

$illustration-size: 60px;

.illustratedPoint {
  margin-top: ($measurement--grid-unit * 10);
  min-height: $illustration-size;
  text-align: center;
}

.illustration {
  height: $illustration-size;
  margin: 0 auto ($measurement--grid-unit * 3);
}

.illustratedPointTitle {
  font-weight: 500;
  margin-bottom: $measurement--grid-unit * 1;
}

.illustratedPointFinePrint {
  color: inherit;
  display: block;
  margin-top: $measurement--grid-unit;
}

.chartedArea {
  background: white;
  border-radius: $measurement--standard-border-radius;
  border-top: 6px solid $color--brand-salmon;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.12);
  display: flex;
  flex-flow: column nowrap;
  margin: ($measurement--grid-unit * 16) 0 0;
  padding: ($measurement--grid-unit * 8) ($measurement--side-gutter);
  text-align: left;
}

.chartTitle {
  order: 1;
}

.chartImageFigure {
  order: 2;
  padding-top: $measurement--grid-unit * 10;
}

.chartImage {
  display: block;
  width: 100%;
}

.chartDisclaimer {
  order: 3;
}

$legend-color-dot-size: 12px;

.chartLegend {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0 0 ($measurement--grid-unit * 10);
  padding: ($measurement--grid-unit * 4) ($measurement--grid-unit * 1) 0;
}

.legendItem {
  color: $color--body-text-on-light-bg;
  font-size: $measurement--x-small-body-text-size;
  margin: 0 ($measurement--grid-unit * 4) ($measurement--grid-unit * 1) 0;

  &:before {
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: $legend-color-dot-size;
    margin: 5px ($measurement--grid-unit * 2) 0 0;
    vertical-align: top;
    width: $legend-color-dot-size;
  }
}

.legendResidential:before {
  background: $color--legend-coloring-residential;
}
.legendShares:before {
  background: $color--legend-coloring-shares;
}
.legendCash:before {
  background: $color--legend-coloring-cash;
}
.clearFix {
  clear: both;
  display: block;

  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .whyChooseBrickX {
    padding: ($measurement--grid-unit * 30) 0;
  }
  .threeIllustratedPoints {
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 $measurement--side-gutter;
  }
  .illustratedPoint {
    flex-basis: 28%;
    margin-top: $measurement--grid-unit * 20;
    padding: 0;
  }
  .illustratedPointTitle {
    font-weight: 700;
    margin-bottom: $measurement--grid-unit * 4;
  }
  .illustration {
    display: block;
    margin: 0 auto ($measurement--grid-unit * 3);
    position: static;
  }
  .chartedArea {
    display: block;
    padding:
      ($measurement--grid-unit * 15)
      ($measurement--grid-unit * 18)
      ($measurement--grid-unit * 8)
      ($measurement--grid-unit * 20);
  }
  .chartImageFigure {
    float: right;
    margin: 0 0 ($measurement--grid-unit * 4) $measurement--side-gutter;
    padding: 0;
    width: 45%;
  }
  .chartDisclaimer {
    margin-top: $measurement--grid-unit * 15;
  }
}

@media (min-width: $breakpoint--medium) {
  .chartedArea {
    padding:
      ($measurement--grid-unit * 20)
      ($measurement--grid-unit * 27)
      ($measurement--grid-unit * 13)
      ($measurement--grid-unit * 30);
  }
  .chartImageFigure {
    margin-left: ($measurement--grid-unit * 10);
    width: 50%;
  }
  .chartDisclaimer {
    margin-top: ($measurement--grid-unit * 27);
  }
}

@media (min-width: $breakpoint--large) {
  .chartImageFigure {
    margin-left: ($measurement--grid-unit * 13);
  }
  .chartDisclaimer {
    margin-top: ($measurement--grid-unit * 38);
  }
}



// Two Ways To Invest
// --------------------------------------------------------------------------------

.twoWaysSecondaryCta {
  text-align: center;
  width: 100%;
}


// BrickX Testimonials
// --------------------------------------------------------------------------------

.brickXTestimonials {
  margin: 0;
  padding-bottom: $measurement--grid-unit * 15;
  padding-top: $measurement--grid-unit * 15;
}

// feeFreeBanner
// --------------------------------------------------------------------------------
.feeFreeBanner {
  background: $color--no-fees-banner-background;
  border-radius: $measurement--standard-border-radius;
  margin: 0 auto ($measurement--grid-unit * 8);
  max-width: $measurement--thin-page-container-max-width;
  padding: ($measurement--grid-unit * 6) ($measurement--grid-unit * 8);
  text-align: center;
  width: 85%;
}

.feeFreeBanner--promo-label {
  border: 1px solid $color--brand-salmon;
  border-radius: $measurement--standard-border-radius;
  color: $color--brand-salmon;
  display: inline-block;
  font-weight: 500;
  margin: 0 auto ($measurement--grid-unit * 3);
  padding: ($measurement--grid-unit) ($measurement--grid-unit * 2);
}

.feeFreeBanner--heading {
  color: $white;
}

.feeFreeBanner--buy-bricks-button {
  min-width: 100%;
}

.feeFreeBanner--t-and-c-link {
  color: $white;
  display: block;
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .feeFreeBanner--buy-bricks-button {
    min-width: 200px;
    width: auto;
  }
}


// What about the fees?
// --------------------------------------------------------------------------------
.aboutTheFees {
  background: $grey-100;
  padding: ($measurement--grid-unit * 30) 0;
}

.aboutTheFeesHeading {
  margin-bottom: $measurement--grid-unit * 10;
}

.aboutTheFeesHeading--no-fee-period {
  margin-bottom: $measurement--grid-unit * 8;
}

.aboutTheFeesContent {
  margin: 0 auto;
  max-width: $measurement--thin-page-container-max-width;
  width: 85%;
}

.feesInfoCard {
  background: white;
  border-radius: $measurement--standard-border-radius;
  border-top: 6px solid $color--brand-salmon;
  box-shadow: $embellishment--shadow-depth-1;
  padding: $measurement--grid-unit * 10;
}

.bigNumberHeading {
  color: $color--brand-salmon;
  font-size: 65px;
  font-weight: 500;
  line-height: 1;
}

.textSubheading {
  color: $color--brand-salmon;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
}

.textSmallheading {
  color: $grey-600;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.1;
}

.feesBulletPoints {
  border-top: 1px solid $grey-200;
  color: $grey-500;
  list-style: none;
  margin: ($measurement--grid-unit * 10) 0 0;
  padding: ($measurement--grid-unit * 4) 0 0;
}

.feesBulletPointsItem {
  line-height: $measurement--body-list-line-height;
  margin: 0 0 ($measurement--grid-unit * 3) 0;
  padding: 0 0 0 ($measurement--grid-unit * 8);
  position: relative;

  &:before {
    content: "crossed_out_dollar";
    font-family: $font--brickicons;
    left: 0;
    position: absolute;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.feesInfoCard--no-pink {
  border-top: none;
  color: $color--body-text-on-light-bg;
}

.feesInfoBodyText {
  margin-top: ($measurement--grid-unit * 10);
  padding: 0 ($measurement--grid-unit);
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .aboutTheFeesHeading {
    margin-bottom: $measurement--grid-unit * 20;
  }

  .aboutTheFeesHeading--no-fee-period {
    margin-bottom: $measurement--grid-unit * 8;
  }

  .aboutTheFeesContent {
    display: flex;
  }

  .feesInfoBodyText {
    flex-basis: 45%;
  }

  .feesInfoCard {
    flex-basis: 55%;
    margin-right: ($measurement--grid-unit * 12);
  }

  .feesInfoBodyText {
    margin-top: ($measurement--grid-unit * 6);
  }

  .aprilPromoFeesSectionCard {
    margin-right: $measurement--grid-unit * 12;
  }
}
