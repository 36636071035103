.brick-price-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $color-light-border;
  padding: 10px;
  color: #1E4861;
}

.brick-price-box--blue {
  background-color: $color-initial-brick-price-box-background;
}

.brick-price-box--orange {
  background-color: $color-lowest-brick-price-box-background;
}

.brick-price-box__icon {
  width: 28px;
  margin-right: 10px;
}

.brick-price-box__title {
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 16px;
}

.brick-price-box__title--left-aligned {
  align-self: flex-start;
}

.brick-price-box__price {
  display: flex;
  align-items: center;
}

.brick-price-box__price-text {
  font-size: 24px;
}

.brick-price-box__price-text--large {
  font-size: 40px;
}
