


.extendible-name {
  .text {
    overflow: hidden;
  }
  a {
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 300;
    font-weight: 500;
    margin-top: 10px;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
