@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.howDoIMakeMoneyContainer {
  padding: ($measurement--grid-unit * 20) 0;
}

.headingImage {
  display: block;
  margin: 0 auto $measurement--grid-unit * 6;
  width: 64px;
}

.subHeading {
  text-align: center;
  margin: ($measurement--grid-unit * 6) 0 ($measurement--grid-unit * 4);
}

.subHeadingAndContent--item {
  margin: 0 0 ($measurement--grid-unit * 16) 0;

  &:last-child {
    margin: 0;
  }
}

.figureImage {
  display: block;
  max-width: 280px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $measurement--grid-unit * 4;
}

.secondaryCtaContent {
  max-width: 410px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  padding-top: $measurement--grid-unit * 15;
}

.secondaryCtaContent--tipText {
  display: inline-block;
  margin: 0 auto;
}

@media (min-width: $breakpoint--small) {
  .howDoIMakeMoneyContainer {
    padding: ($measurement--grid-unit * 30) 0;
  }

  .subHeadingAndContentContainer {
    display: flex;
    justify-content: space-between;
  }

  .subHeadingAndContent--item {
    flex: 1;
    margin: 0;
  }

  .figureImage {
    width: auto;
    height: 180px;
  }

  .subHeadingAndContent--item--inner {
    width: 80%;
    margin: 0 auto;
  }

  .subHeadingAndContent--item-withLeftBorder {
    border-left: 1px solid $color--hiw-border-left;
    position: relative;

    &::before {
      content: "&";
      font-size: $measurement--small-heading-text-size;
      line-height: 1;
      font-weight: 500;
      color: $color--hiw-border-left;
      background: $color--white;
      padding: $measurement--grid-unit * 2;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .subHeadingAndContent--darkBackground-ampersand::before {
    background: $color--landing-pages-grey-background;
  }

  .subHeading {
    min-height: 70px;
  }
}
