

.account-wallet {
  h1 {
    text-align: center;
    border-bottom: none;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 26px;
  }
  .wallet-summary {
    margin-bottom: 10px;
    table {
        margin-bottom: 0;
        tr {
            td {
                line-height: 30px;
                .icon {
                    padding-left: 2px;
                }
                &:last-child {
                    text-align: right;
                }
            }
            @media (max-width: $small) {
              font-size: 12px;
            }
        }
        tr:first-child {
            td {
                border-top: none;
            }
        }
        tr:last-child {
            td {
                border-top-color: #777;
                font-weight: 500;
            }
        }
    }
  }
  .wallet-actions {
    margin-bottom: 30px;
    .btn-deposit, .btn-withdraw {
        border-radius: $measurement--standard-border-radius;
        line-height: 22px;
    }
    .btn-deposit {
        background-color: $orange-active;
        border-color: $orange-active;
        color: $white;
        &:hover {
            background: $orange-active-hover;
            border-color: $orange-active-hover;
        }
    }
    .btn-withdraw {
        background-color: #e8e8e8;
        color: $off-black;;
    }
  }
  .card-cta {
      margin:40px 0;
      text-align:center;
      h4 {
        margin-bottom:0px;
      }
      p {
        margin-top:10px !important;
        font-size: 16px;
        margin-bottom:10px;
        font-weight: 400;
      }
      a {
        padding:4px 20px;
      }
    }

}

.funds-available-bar {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #DDD;
  font-weight: 400;
  padding: 0 0 10px 0;
  .funds-available-title {
    text-align: left;
  }
  .funds-available-value {
    position: relative;
    text-align: right;
  }
}