.table-orders {
    .bricks-sold-progress-bar {
        margin: 10px 0;
    }
    .bricks-discount-col {
        @media (max-width: 520px) {
            display: none !important;
        }
    }
    .header {
        font-size: 18px;
        padding: 10px;
    }

    .no-bricks {
        padding: 10px;
    }

    .nav-tabs {
        border: 0 !important;
        text-align: center;
        &:after {
            clear: both;
            content: " ";
            display: table;
        }
        .tab {
            background-color: #f5f5f5;
            border: 1px solid #ddd;
            border-bottom: 0 !important;
            border-top-width: 3px;
            cursor: pointer;
            display: block;
            float: left;
            padding: 15px 0;
            width: 50%;
            &:first-child {
                border-right: 0;
            }
            &:hover {
                background-color: #fff;
            }
            &.active {
                background-color: #fff;
                border-top-color: $orange;
            }
        }
    }

    .orders-trades-tab {
        display: none;
        &.active {
            display: block;
        }
    }
}
