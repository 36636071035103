.panel-financials-buy {
    color: $dark-blue;
    @media (max-width: $mobile-large) {
        .tab {
            display: none;
            &.active {
                display: block !important;
            }
        }
    }
    .order-book-expander {
        padding-bottom: 10px;
    }
    .place-order-panel {
        label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
        }
        #premium-price-second-alert-modal {
            .modal-footer {
                .button {
                    @media (max-width: $mobile-large) {
                        padding: 8px 20px;
                    }
                }
            }
        }
        .times-icon {
            margin: 12px 15px 0 15px;
            @media (max-width: $mobile-large) {
                display: none !important;
            }
        }
        .container-total {
            margin-left: 20px;
            @media (max-width: 520px) {
                display: none !important;
            }
        }
        .container-pre-order-input {
            display: inline-block;
            vertical-align: middle;
            padding: 0px 10px;
            width: 40%;
            @media (max-width: $mobile-large) {
                width: 100%;
            }
        }
        .container-trade-input {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            width: 30%;
            @media (max-width: $mobile-large) {
                width: 100% !important;
                display: block;
                margin: 0 !important;
                .white-box {
                    padding: 0 !important;
                    font-weight: bold;
                    font-size: 21px !important;
                    .quantity-to-be-buy .quantity-to-be-sell {
                        border: none;
                        float: right;
                        box-shadow: none;
                        box-shadow: none;
                        font-size: 30px;
                        color: $orange !important;
                        text-align: right;
                        width: 30% !important;
                        border-bottom: 1px solid #ddd;
                        padding: 0 5px;
                    }
                }
            }
        }
        .gray-box {
            padding: 20px 25px;
            background-color: $light-gray;
        }
        .note {
            margin-top: 15px;
        }
        .funds-available {
            line-height: 18px;
            border-bottom: 1px solid #ddd;
            text-align: right;
            padding-top: 10px;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
            color: $light-blue;
            border-bottom: 1px solid #ddd;
            &.sell {
                color: $salmon;
            }
            &.buy {
                color: $light-blue;
            }
        }
        .confirm-buy-button {
            @media (min-width: $small) {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
        .confirm-sell-button {
            @media (min-width: $small) {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
        .confirm-pre-order-button {
            @media (min-width: $small) {
                padding-left: 50px;
                padding-right: 50px;
            }
            color: #fff;
        }
        .col-inline {
            display: inline-block;
            vertical-align: middle;
            padding: 0px 10px;
        }
        .col-inline-1 {
            width: 8.33%;
        }
        .col-inline-2 {
            width: 16.66%;
        }
        .col-inline-3 {
            width: 25%;
        }
        .col-inline-4 {
            width: 33.33%;
        }
        .col-inline-5 {
            width: 41.66%;
        }
        .col-inline-6 {
            width: 50%;
        }
        .white-boxes-container {
            .decimal-input.dollar-input {
                &::before {
                    width: 20% !important;
                }
                input {
                    width: 50% !important;
                }
                &::after {
                    width: 30% !important;
                }
            }
        }
        .form-group {
            margin: 0;
        }
        .white-box {
            font-weight: 500;
            background-color: #fff;
            border-radius: 1px;
            padding: 15px 0;
            font-size: 13px;
            .value {
                color: $light-blue;
                font-size: 16px;
                font-weight: 500;
                &.sell {
                    color: $salmon;
                }
                &.buy {
                    color: $light-blue;
                }
            }
        }
    }

    .error {
        color: #d00;
        font-weight: 500;
        margin-bottom: 10px;
        a {
            text-decoration: underline;
        }
    }

    .body {
        padding: 20px 10px;
        .table-orders {
            font-weight: 500;
            .header {
                border-bottom: 0;
                border-top: 1px solid #ddd;
                background-color: #fff;
                color: $blue;
                font-size: 19px;
                font-weight: 500;
            }
            .note {
                font-weight: 400;
            }
            .show-button {
                cursor: pointer;
                text-align: center;
                text-decoration: underline;
            }
        }

        .table-striped > tbody > tr:nth-of-type(odd) {
            background-color: $light-gray;
        }
        .col-left {
            font-weight: 400;
        }
        .col-left,
        .col-right {
            line-height: 30px;
        }
        .col-right {
            font-weight: 500;
            font-size: 16px !important;
            color: $dark-blue !important;
        }
        .brick-price-summary {
            .col-left {
                font-weight: normal !important;
            }
            .col-right {
                font-weight: normal !important;
                font-size: 16px !important;
            }
        }
        #id_buy_quantity {
            background-color: $white;
            color: $dark-blue;
            display: inline-block;
            margin-left: 10px;
            width: 50%;
        }

        .action-buttons {
            text-align: right;
            button {
                margin-left: 15px;
                font-size: 20px;
                .fa {
                    font-size: 26px;
                }
            }
            .note {
                margin-top: 15px;
            }
        }
    }

    .sell-inputs {
        margin: 15px 0px;
    }
}

.panel-financials-invest-shadow {
    .panel-order-book-pre-order {
        border: none;
        box-shadow: none;
        min-height: auto !important;
    }
    .cancel-button {
        margin-right: 10px;
    }
}

@media (max-width: $mobile-large) {
    .error-insufficient-funds {
        text-align: left;
    }

    .fee-free-terms {
        margin-bottom: 12px;
    }

    .panel-financials-invest-shadow {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        .panel-financials-buy {
            position: relative;
            height: 100%;
            margin-bottom: 0;
            overflow: scroll;
        }
    }

    .panel-financials-buy {
        color: $dark-blue;
        .tab {
            display: none;
            &.active {
                display: block !important;
            }
        }

        .action-full-button {
            font-size: 17px !important;
            display: block !important;
            width: 100%;
            margin-top: 15px;
            margin-left: 0 !important;
        }
        .place-order-panel {
            background-color: #fff !important;
            padding: 0 !important;
        }
        .col-right {
            text-align: right !important;
        }
        .icn-medium {
            padding: 5px 15px;
        }
    }
    .table-orders {
        .header {
            font-size: 16px !important;
            padding: 15px !important;
        }
    }
    .input-group-sale-price-mobile {
        padding: 10px 0 !important;
        div {
            display: inline-block;
        }
        .white-box {
            display: block;
        }
        .decimal-input.dollar-input {
            width: 50%;
            float: right;
            input {
                border: none;
                background-color: #fff !important;
                padding: 0 5px;
                border-bottom: 1px solid #ddd !important;
                border-radius: 5px;
            }
            &:after,
            &:before {
                border: none;
                background-color: #fff;
                padding: 0 5px;
            }
        }
    }
}
