@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.headingVideoSection {
  margin-bottom: $measurement--grid-unit * 16;
  margin-top: $measurement--grid-unit * 10;
}

.heading {
  font-size: $measurement--small-heading-text-size;
  font-weight: 500;
}

.video {
  border-radius: $measurement--standard-border-radius;
  box-shadow: var(--bx-emb-shadow);
  overflow: hidden;
}

.headingParagraphContainer {
  margin: 0 0 ($measurement--grid-unit * 12) 0;
}

@media (min-width: $breakpoint--small) {
  .headingVideoSection {
    display: flex;
    margin-bottom: $measurement--grid-unit * 20;
    margin-top: $measurement--grid-unit * 20;
  }

  .heading {
    font-size: $measurement--large-heading-text-size;
    font-weight: 700;
  }

  .headingParagraphContainer {
    display: flex;
    flex: 1 0 0px;
    flex-flow: column nowrap;
    margin: 0 ($measurement--grid-unit * 10) 0 0;
  }

  .videoContainer {
    flex: 1 0 0px;
    margin: auto 0;
  }
}

@media (min-width: $breakpoint--medium) {
  .headingVideoSection {
    margin-bottom: $measurement--grid-unit * 30;
    margin-top: $measurement--grid-unit * 30;
  }
  .headingParagraphContainer {
    margin-right: ($measurement--grid-unit * 30);
  }
}


.newToBrickX {
  color: $color--dark-text-on-light-bg;
}

.newToBrickX--arrow {
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-140%, -85%) rotate(180deg);
  width: 15px;
}

@media (min-width: $breakpoint--small) {
  .newToBrickX--arrow {
    width: 18px;
  }
}
