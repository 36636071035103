@import 'src/styles/breakpoints';

.propertySummaryBanner {
  margin-bottom: 20px;
}

@media (min-width: $breakpoint--small) {
  .propertySummaryBanner {
    margin-bottom: 0;
  }
}
