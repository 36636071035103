@import '~src/styles/colors';

.suggestionInputContainer {
  position: relative;
}

.suggestionList {
  background-color: white;
  border: 1px solid $color--input-border;
  list-style: none;
  margin-top: -1px;
  max-height: 220px;
  overflow: scroll;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.suggestionItem {
  border-bottom: 1px solid $color--input-border;
}

.suggestionItem:hover {
  background-color: $pink-50;
  color: $color--brand-salmon;
}
