

.property-future {
  border-radius: var(--bx-ms---widget-border-radius);
  overflow: hidden;
  .header {
    height: 81px;
  }
  @media(max-width: $mobile-landscape) {
    .header {
      border-bottom: 1px solid $dark-blue;
      height: auto;
      .investors-box {
        display: none;
      }
    }
    .body {
      height: 225px;
      font-size: 16px;
      .overlay {
        top: 0;
      }
    }
    .property-hover {
      display: none;
    }
  }
}

.property-future .overlay {
  display: block;
  position: absolute;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  top: 30px;
  z-index: 1;
  height: 100%;
  padding-top: 30px;
}
.property-future .body-content {
  filter: blur(5px);
  -webkit-filter: blur(5px); /* Chrome, Safari, Opera */
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}
