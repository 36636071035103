@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  max-width: 1200px;
  height: 65px;
  margin-left: auto;
  margin-right: auto;
  transition: background 250ms ease-in-out;
}

.brickXLogo {
  margin: auto 0;
}

.menu {
  background: white;
  border: 1px solid #eeeeee;
  border-top: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  left: 100%;
  max-width: 425px;
  min-height: 100vh;
  position: absolute;
  top: 65px;
  transform: translateX(-100%);
  transition: transform 250ms ease-in-out;
  width: 100%;
  z-index: 900;
}

.menu_closed {
  transform: translateX(0%);
}

@media (min-width: 1100px) {
  .menu,
  .menu_closed {
    align-items: center;
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    max-width: none;
    min-height: 0;
    position: static;
    transform: none;
    width: auto;
  }
}
