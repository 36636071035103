.maintenance-page {
  background-color: white;
  margin-top: 0px;
  padding: 100px 0;
  img {
    display: block;
    margin: 0 auto;
    width: 50%;
  }
}
