

.bricks-sold-progress {
  color: $dark-blue;
  font-size: 13px;
  font-weight: 500;
  margin-top: 3px;
  text-align: center;
}
.bricks-sold-progress__bar {
  border: 1px solid #DDD;
  padding: 2px;
}
.bricks-sold-progress__bar-fill {
  background-color: $blue;
  height: 4px;
}
.bricks-sold-progress__bar-fill--disabled {
  background-color: $grey;
}
.bricks-sold-progress__bar-fill--new-property {
  background-color: $orange;
}
