.panel {
  border-radius:$standard-radius;
  border: $standard-border;
  box-shadow: $standard-shadow;
  background:#fff;
  .table {
    tr td {
      font-weight: 400;
    }
    tr td.text-right {
      font-weight: 500;;
    }
  }
  .panel-footer {
    font-weight: 500;;
    font-size:13px;
  }
  .panel-heading {
    .pull-right {
      small {
        font-size:13px;
      }
    }
  }
  .panel-body-padding {
    padding:20px;
  }
}

.panel-thumbnail {
  margin-top:20px;
  margin-bottom:40px;
  opacity:0.9;
  .header {
    position:relative;  
  }
  .body {
    padding:10px;
    text-align:center;
    font-size: 16px;
    line-height:24px;
    .property-title {
      min-height:48px;
    }
    .property-details {
      @include property-icons;
      ul {
        text-align:center;
        li {
          line-height:40px;
          font-size: 16px;
          &:last-child {
            margin-right:0px;
          }
        }
      }
    }
  }
  &:hover {
    cursor:pointer;
    opacity:1;
  }
}