.contact-page {
  font-weight: 400;
  padding-bottom: 100px;
  h1 {
    color: $dark-blue;
    font-size: 37px;
    font-weight: 300;
    font-weight: normal;
    margin: 30px 0;
    text-align: center;
  }

  .form-container {
    border-radius: var(--bx-ms---standard-border-radius);
    background-color: var(--bx-color---surface);
    margin-bottom: 65px;
    padding: 40px;
    @media (max-width: $mobile-large) {
      padding: 40px 20px;
    }
    .input-label {
      font-weight: 500;
    }
    .form-input {
      padding: 20px 0;
      position: relative;
      &:first-child {
        padding-top: 0px;
      }
    }

    label {
      display: block;
      margin-bottom: 10px;
      input {
        margin-right: 5px;
      }
    }
  }

  .contact-us {
    color: $dark-blue;
    .sub-contact-us {
      border-bottom: 1px solid #DDD;
      border-top: 1px solid #DDD;
      font-weight: 500;
      padding: 20px;
      text-align: center;
    }
    .title {
      font-weight: 500;
      margin-bottom: 20px;
      .icn {
        margin-right: 8px;
      }
    }
    .button {
      border-color: $dark-blue;
      color: $dark-blue;
      margin-top: 15px;
    }
    .contact-box {
        padding: 30px;
        @media (max-width: $mobile-large) {
          text-align: center;
        }
    }
    p {
      margin-bottom: 4px;
    }
  }

  .bx-input {
    border-radius: 5px;
  }

}
