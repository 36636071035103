@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.smartInvestContainer {

}

.innerContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: $measurement--wide-page-container-max-width;
}

.buildHomeDepositFaster {
  padding: $measurement--side-gutter;
  padding-top: ($measurement--grid-unit * 30);
  position: relative;
  text-align: center;

  &:before {
    background: $color--why-choose-brickx-background;
    content: "";
    height: 70%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > * {
    position: relative;
  }
}

.testimonialsArea {
  padding: ($measurement--grid-unit * 30) 0;
}

@media (min-width: $breakpoint--small) {

}
