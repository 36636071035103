.trade-panel-pre-order__promo-banner {
  margin: 25px 25px 0 25px;
}

.property {

  @media (max-width: $mobile-large) {
    .property-hover {
      padding: 0;
      background: rgba(50,50,50, 0.1);
      border-radius: 40px;
    }
  }

  .coming-soon-property.panel-financials-blue-header {
    .header {
      background-color: $blue !important;
    }
  }
  .order-book-pre-order.panel-financials-blue-header .header {
    background-color: $blue !important;
  }

  .pre-order-panel {
    .trade-button {
      width: 100%;
    }
  }

  .coming-soon-panel {
    padding-bottom: 20px;
    .trade-button {
      width: 100%;
    }
  }
  .flag-container {
    position: relative;
  }
  .flag {
    z-index: 9;
  }
  .white-boxes-container {
    font-weight: 500;
    margin-bottom: 15px;
  }
  .critical-news {
    margin-bottom: 20px;
  }
  .property-hover {
    padding: 8px;
    .icn {
      padding: 0 13px;
    }
    .debt {
      position: static;
      top: 10px;
    }
  }

  .text-off-black {
    color: #666;
  }

  .panel-yield-calculations {
    .math-formulas {
      position: relative;

      .equal {
        position: absolute;
        right: -22px;
        top: 37%;
      }
    }
  }

  .panel-acquisition-costs {
    .note {
      padding-bottom: 0;
    }
  }


  .equal::after {
    content: "=";
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
  }
}
