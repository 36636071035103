

$astral-blue: #346985;

.sub-header {
  background-color: $dark-blue;
  color: #FFF;
  font-weight: 400;
  padding: 15px 0px;

  .step {
    text-align: center;
    color: $light-blue;
    &.next-to-complete {
      .step-top {
        .circle {
          border-color: $orange;
        }
      }
      .description {
        color: #FFF;
        div {
          color: $orange;
        }
      }
    }
    &.complete {
      color: #FFF;
      .circle {
        border: none !important;
        background-image: url(/static/images/icons/icn_progress_tick.png);
        background-size: contain;
      }
    }
    .step-top {
      position: relative;
      .circle {
        height: 30px;
        width: 30px;
        border: 4px solid $astral-blue;
        border-radius: 50%;
        background-color: $dark-blue;
        display: inline-block;
        position: relative;
        z-index: 1;
      }
      .icn {
        height: 30px;
        width: 30px;
        position: relative;
        display: inline-block;
        z-index: 1;
        background-color: $dark-blue;
      }
      .dashed-line {
        position: absolute;
        width: 100%;
        top: 37%;
        background-image: url(/static/images/dash.png);
        height: 6px;
        background-size: 13px 4px;
      }

    }
    &:first-child {
      .dashed-line {
        right: 0;
        width: 50%;
      }
    }
    &:last-child {
      .dashed-line {
        width: 50%;
      }
    }
    .description {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      div {
        font-size: 16px;
        @media (max-width: $small) {
          display: none;
        }
      }
    }
  }


}
