#deposit {
  color: $dark-blue;
  font-weight: 400;
  background-color: #fff;

  .dollar-input {
    input {
      padding: 9px 14px;
    }
    &::before {
      padding: 9px !important;
    }
  }
  .payment-options {
    padding-top: 10px;
    height: 350px;
    @media (max-width: $small) {
      height: auto;
    }
  }
  .error-message {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
    color: $red;
    text-align: center;
  }
  .poli-disclaimer {
    font-size: 13px;
    margin-bottom: 20px;
    padding: 0 35px;
  }

  .form-row {
    @media (max-width: $small) {
      text-align: center !important;
      .text-right {
        text-align: center !important;
        margin-bottom: 10px;
      }
    }
  }
  .payment-options {
    margin-top: 30px;
  }
  .payment-option {
    font-size: 16px;
    padding: 0 35px;
    &.poli-option {
      border-right: 1px solid #ddd;
    }
    &.poli-option-learn-more {
      min-height: 220px;
      margin-bottom: 15px;
      border-right: 1px solid #ddd;
      @media (max-width: $small) {
        min-height: auto;
      }
    }
    label {
      font-weight: normal;
      cursor: pointer;
    }
    input {
      margin-right: 10px;
    }
    img {
      display: block;
      height: 40px;
      margin: 15px 0;
    }
    .button::after {
      font-size: 22px;
    }
    .button {
      font-size: 16px !important;
      padding: 13px 50px;
      @media (max-width: $mobile-large) {
        width: 100%;
      }
    }
    .radio-group {
      padding-top: 10px;
      input {
        vertical-align: top;
      }
    }
    .learn-more-about-payment {
      font-size: 16px;
      .estimate-arrival {
        font-size: 20px;
        padding: 8px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
        span {
          color: $orange;
          text-transform: uppercase;
        }
      }
      ul {
        padding: 16px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .blue-bar {
    background-color: $blue;
    padding: 30px 0;
    margin-top: 20px;
    color: #fff;
    text-align: center;
    .small-italic {
      font-size: 12px;
      font-style: italic;
      line-height: 14px;
    }
    .cred-icon {
      img {
        height: 50px;
        margin-bottom: 6px;
      }
    }
    .cred-icon-deloitte {
      img {
        height: 40px;
        margin-top: 10px;
      }
    }

    .carousel.slide {
      .item {
        height: 100px;
        img {
          display: inline;
        }
      }
      .carousel-indicators {
        bottom: -47px;
        li {
          width: 12px;
          height: 12px;
          margin: 10px;
          background-color: $dark-blue;
          border-color: $dark-blue;
          &.active {
            background-color: #fff;
            border-color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: $small) {
    h1 {
      margin-bottom: 10px;
    }
    .button {
      width: 100%;
      font-size: 18px;
    }
  }
}

.modal-poli {
  margin: 0 auto;
  padding: 0;

  border: 1px solid gray;
  overflow: hidden;
  border: none;
  -webkit-overflow-scrolling: touch;
  @media (max-width: $mobile-large) {
    height: 100%;
  }
  .modal-content {
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    border-width: 0px;
  }
  iframe {
    width: 900px;
    height: 90vh;
    @media (max-width: $small) {
      width: 100%;
    }
  }
  .modal-dialog {
    margin: 0px auto;
    padding-top: 15px !important;
  }
}

.account-deposit {
  h1 {
    text-align: center;
    border-bottom: none;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .panel-deposit {
    .panel-heading {
      font-size: 15px;
      line-height: 40px;
      padding: 10px 20px;
      h3 {
        line-height: 40px;
        font-weight: 500;
        font-weight: normal;
        font-size: 12px;
      }
      .pull-right {
        font-weight: 500;
      }
    }
    .panel-body {
      padding: 20px;

      p {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 20px;
        color: $off-gray;
      }
      .form-control {
        height: 40px;
        font-size: 15px;
      }
      .input-group-addon {
        padding: 10px 20px;
      }
      .btn-lg {
        font-size: 16px;
      }

      .terms {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0;
        margin: 20px 0;
      }
    }
  }
  .information {
    padding: 15px 16px 6px;
    border: solid 1px #e8e8e8;
    @media (max-width: $small) {
      display: none;
    }
    p {
      line-height: 48px;
      font-weight: 500;
      font-size: 11px;
      margin: 0;
    }
    img {
      margin-right: 30px;
      height: 40px;
    }
  }
  .deposit-calculator {
    .form-important {
      margin-bottom: 0;
    }
  }
}
