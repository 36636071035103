.property-card-feature {
  position: relative;
}

.property-card-feature__image-container {
  overflow: hidden;
}

.property-card-feature__image {
  height: 200px;
  background-size: cover;
  background-position: center;
  transition: transform .2s $transition--standard-function;
}

.property-card-feature__image:hover {
  transform: scale(1.1);
}

@media (min-width: $medium-large) {
  .property-card-feature__image {
    height: 160px;
  }
}
