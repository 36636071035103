@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/components/header/commonConstants';

.spasticCenteredTickedBulletList {
  margin-left: auto;
  margin-right: auto;
  max-width: 370px;
  width: 90%;
}

.spasticCenteredTickedBulletList--item {
  margin-left: 0 !important;

  &:nth-child(3),
  &:nth-child(4) {
    display: none;
  }
}

@media (min-width: $breakpoint--small) {
  .spasticCenteredTickedBulletList--item {
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
}
