


.about-page {
  .hero {
    background-color: $very-dark-blue;
    background-image: url("/static/images/about3.jpg");
    background-position:  center;
    background-repeat:  no-repeat;
    .gradient {
      background: -webkit-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)); /* For Firefox 3.6 to 15 */
      background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)); /* Standard syntax (must be last) */
    }
    .our-story {
      height: 500px;
      position: relative;
      .text-bottom {
        bottom: 0;
        padding-bottom: 40px;
        position: absolute;
        .title {
          color: $orange;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        .description {
          color: #FFF;
          font-size: 20px;
          font-weight: 300;
          @media (max-width: $mobile-large) {
            min-height: auto;
          }
        }
      }
    }
  }



  .our-values {
    color: $dark-blue;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .box-values {
    margin-top: 40px;
    .value-item {
      margin-bottom: 30px;

      .icon {
        height: 75px;
        img {
          height: 100%;
        }
        &.icon_cred, &.icon_empow {
          padding: 4px 0;
        }
      }
      .title {
        color: $orange;
        font-size: 24px;
        margin: 18px 0 14px;
      }
      .description {
        font-size: 15px;
        line-height: 24px;
        min-height: 210px;
        @media (max-width: $medium-large) {
          min-height: 240px;
        }
        @media (max-width: $medium) {
          min-height: 200px;
        }
        @media (max-width: $mobile-large) {
          min-height: auto;
        }
      }
    }
  }



}
