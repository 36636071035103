.question-list__list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: item;
}

.question-list__list-item {
  counter-increment: item;
  margin-bottom: 30px;
}

.question-list__list-item:last-child {
  margin-bottom: 0;
}

$size: 50px;
$circleWidth: 4px;

.question-list__number-icon {
  float: left;
  position: relative;
  margin-top: 5px;
  margin-right: 15px;
  width: $size;
  height: $size;
  text-align: center;
  border: $circleWidth solid black;
  border-radius: 50%;
  font-size: $size;
}

.question-list__number-icon::before {
  content: '';
  width: 32.5%;
  height: $circleWidth;
  background-color: black;
  position: absolute;
  left: 65%;
  top: 100%;
  transform-origin: right;
  transform: rotate(45deg);
}

.question-list__number-icon::after {
  content: counter(item);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: $size - 10;
  text-align: center;
  font-size: 0.5em;
}

.question-list__question {
  margin-top: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.question-list__answer {
  font-size: 16px;
  white-space: pre-line; // allow answers to have newlines in them but not accidental extra spaces
}

.question-list__title--contrast,
.question-list__question--contrast {
  color: black;
}

.question-list__number-icon--contrast {
  color: white;
  border-color: white;
}

.question-list__number-icon--contrast::before {
  background-color: white;
}

.question-list__answer--contrast {
  color: white;
}

.question-list__title--dark {
  color: black;
}

.question-list__question--dark {
  color: $blue;
}

.question-list__number-icon--dark {
  color: $blue;
  border-color: $blue;
}

.question-list__number-icon--dark::before {
  background-color: $blue;
}

.question-list__answer--dark {
  color: black;
}

@media (min-width: 768px) {
  .question-list__list-item {
    display: flex;
  }

  .question-list__number-icon {
    flex: 0 0 auto;
    margin-right: 25px;
  }
}
