@import '~src/styles/colors';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';
@import '~src/styles/measurements';

// @TODO: these classes are repeated for now to increase specificity sufficiently,
// just to over-ride shitty global styles else where

.textInput.textInput {
  border: 1px solid $color--input-border;

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color--input-placeholder;
  }

  &:focus {
    border-color: $color--input-border-focus;
  }

  &.highlighted {
    border-color: $color--input-border-focus;
  }

  &.errorHighlighted {
    border-color: $color--input-border-error;
  }
}

.textInput__font.textInput__font {
  font-size: $measurement--large-body-text-size;
}

.textInput__spacing.textInput__spacing {
  padding: ($measurement--grid-unit * 3.5);
  //                                   ^
  // To ensure that text inputs mirror the default sizing of our Primary Buttons,
  // they need to have 14px padding vertically.
  // 14 / 4 = 3.5
}

.textInput__colors.textInput__colors {
  background: transparent;
  color: $color--input-text;
}
