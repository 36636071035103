.invest-panel-pre-order__views-selldown-container {
  margin: 20px 0;
  font-size: 1.5em;
  text-align: center;
}

.summary {
  .disclaimer {
    font-size: 13px;
  }
  .return-calculator-results__disclaimer {
    font-weight: 400;
  }
  .purchase-date {
    .box-financials-value {
      font-size: 50px;
      line-height: 90px;
    }
  }
  .panel-financials-title {
    font-size: 18px !important;
  }
  .asterisk-info {
    margin-top: 10px;
    display: inline-block;
  }

  .panel-financials-invest, .panel-financials-latest-value, .panel-financials-investment-yield {
    min-height: 467px !important;
  }

  .panel-order-book-pre-order {
    @media (max-width: $mobile-large) {
      min-height: 320px !important;
    }
  }

  .panel-financials-invest {
    .buy-button {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .sell-button {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .place-order-panel {
    .white-box {
      &.quantity {
        @media(max-width: $small) {
          text-align: right !important;
          font-size: 18px !important;
        }
      }
    }
  }

  .lowest-available-price-box {
    @media(max-width: $small) {
      border: 1px solid #ddd;
        padding: 10px;
    }
  }

  .invest-panel, .pre-order-panel {
    .brick-valuation-bar {
      @media(max-width: $small) {
        margin-bottom: 10px;
      }

    }
    .bricks-sold-progress-bar {
      margin-top: 15px;
    }
    .mobile {
      button {
        display: block;
        margin-top: 10px;
        width: 100%;
        font-size: 24px;
        padding: 9px 28px;
        &:after {
          font-size: 29px;
          margin-left: 16px;
        }
      }
      .bricks-sold-last-month {
        font-weight: 500;
        color: #777;
        font-size: 12px;
        .bricks {
          color: $orange;
        }
      }
    }
  }
  .pre-order-panel {
    .mobile {
      button {
        margin-bottom: 20px;
        font-size: 23px;
        padding: 9px 0px;
      }
    }
  }
  @media (max-width: $mobile-large) {
    .data-info {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      .icn {
        padding: 1px 14px;
      }
      .value {
        line-height: 1;
        font-size: 24px;
        font-weight: 400;
        color: #777;
      }
    }
  }
}
