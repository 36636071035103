

.prev-next-bar {
  margin-bottom: 20px;
  .button {
    font-weight: 500;
    padding: 6px 20px;
    .fa {
      font-size: 20px;
    }
  }
  .prev-button {
    .fa {
      margin-right: 9px;
    }
  }
  .next-button {
    .fa {
      margin-left: 9px;
    }
  }
}
