@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.expandingBox {
  border: 1px solid #e7e7ec;
  border-left: none;
  border-right: none;
  color: #57575c;
  display: flex;
  flex-flow: column nowrap;
  font-size: 20px;
  padding: 0 8px;
  position: relative;
}

.expandButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 1.1;
  padding: 20px 0;
}

.caret {
  color: #41414b;
  font-size: 24px;
  transition: transform 250ms ease-in-out;
}

.flipped {
  transform: rotate(180deg);
}

.content {
  overflow: hidden;
  transition: max-height 250ms ease-in-out;
}

.contentInner {
  padding-bottom: 20px;
}
