@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';
@import '~src/styles/embellishments';

// TALK TO BRICKX SECTION -----------------------------------

.talkToBrickX {
  background: $grey-800;
  border-radius: $measurement--standard-border-radius;
  padding: ($measurement--grid-unit * 10) ($measurement--grid-unit * 6);
  text-align: center;
}

.talkToBrickX--paragraph {
  color: $white;
  margin-left: auto;
  margin-right: auto;
  max-width: $measurement--thin-page-container-max-width;
}

@media (min-width: $breakpoint--small) {
  .talkToBrickX {
    padding: ($measurement--grid-unit * 14) ($measurement--grid-unit * 6);
  }
}

// WHAT OUR CUSTOMERS ARE SAYING SECTION -----------------------------------
.testimonialListing {
  display: flex;
  flex-flow: column nowrap;
}

$image-size: 90px;

.testimonial {
  background: white;
  border: 1px solid $color--line-on-white;
  border-radius: $measurement--standard-border-radius;
  box-shadow: $embellishment--shadow-depth-1;
  display: flex;
  flex-flow: column nowrap;
  margin: ($measurement--grid-unit * 23) 0 0;
  padding: ($measurement--grid-unit * 8) ($measurement--grid-unit * 6);
  padding-top: 0;
}

.testimonial--image {
  margin: (-$image-size * 0.5) auto 0;
  width: $image-size;
}

.testimonial--name {
  border-bottom: 1px solid $color--line-on-white;
  color: $grey-500;
  margin-bottom: ($measurement--grid-unit * 6);
  margin-left: (-$measurement--grid-unit * 6);
  margin-right: (-$measurement--grid-unit * 6);
  padding: ($measurement--grid-unit * 4) 0 ($measurement--grid-unit * 6);
}

.testimonial--message {
  text-align: left;
}

@media (min-width: $breakpoint--small) {
  .testimonialListing {
    flex-flow: row nowrap;
    justify-content: center;
  }
  .testimonial {
    flex: 1;
    margin-top: ($measurement--grid-unit * 30);
    max-width: 420px;
    padding-bottom: ($measurement--grid-unit * 14);
    padding-left: ($measurement--grid-unit * 13);
    padding-right: ($measurement--grid-unit * 13);

    &:first-child {
      margin-right: $measurement--grid-unit * 14;
    }
  }

  .testimonial--name {
    margin-bottom: ($measurement--grid-unit * 8);
    margin-left: (-$measurement--grid-unit * 13);
    margin-right: (-$measurement--grid-unit * 13);
  }
}
