@import 'src/styles/breakpoints';

.smartInvestSignupDriver {
  margin-bottom: 25px;
}

.noFeesPreOrderBanner {
  margin-bottom: 20px;
}

@media (min-width: $breakpoint--small) {
  .noFeesPreOrderBanner {
    margin-bottom: 0;
  }
}
