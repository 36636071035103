@import '~src/styles/breakpoints';

.root {
  display: block;
  width: 110px;
}

@media (min-width: $breakpoint--medium) {
  .root {
    width: 120px;
  }
}
