@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.notFoundPage {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  height: 100%;
}

.notFoundPage--container {
  display: flex;
  flex: 1;
  padding: ($measurement--grid-unit * 10) 0;
}

.notFoundPage--innerContainer {
  margin: auto;
  text-align: center;
}
