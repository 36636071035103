.closeable-panel__title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: $very-dark-blue;
}

.closeable-panel__title {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.closeable-panel__close-button {
  width: 30px;
  height: 30px;
  background-color: $light-blue;
  border: none;
  border-radius: 50%;
  color: $very-dark-blue;
}

.closeable-panel__content {
  padding: 15px;
}
