

.popover-container {
  color: $gray;
  display:inline;
  margin:0 2px;

  .fa {
    font-size: 18px;
  }
}

.popover {
  border: $standard-border;
  border-radius:$standard-radius;
  box-shadow:$standard-shadow;
  font-size:13px;
  font-weight: 500;
  max-width:280px;
  min-width:280px;;
  text-align:left !important;
}

.popover-title {
  display:none;
}
