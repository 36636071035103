.property-hero {
  background: #151f30;
  position: relative;
  @media (max-width: $small) {
    min-height: 200px;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.property-hero__property-details {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 46px;
  width: 100%;
  padding: 0 10px;
  pointer-events: none;
  // background: rgba(0, 61, 105, 0.7);
}

.property-hero__property-stats-container {
  position: relative;

  @media (max-width: $just-below-bootstrap-container-smallest) {
    width: 100%;
  }
}

.property-hero__modal-image {
  width: 100%;
}

.property-hero__carousel-item-click-wrapper {
  cursor: pointer;
}

.property-hero-expand-carousel-cta__container {
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0;
  bottom: 46px;
  width: 100%;
  margin-bottom: 16px;
}

.property-hero-expand-carousel-cta {
  display: flex;
  background-color: $white;
  border-radius: 999px;
  padding: 0.3em 0.6em;
  line-height: 1;
}

.property-hero-expand-carousel-cta__icon-camera {
  height: 1em;
  width: 1.4em;
}

.property-hero-expand-carousel-cta__icon-open {
  height: 0.8em;
  width: 1em;
}

.property-hero-expand-carousel-cta__number-of-items {
  margin: 0 0.3em;
  font-weight: 500;
}

@media (min-width: $mobile-large-plus-1) {
  .property-hero__property-details {
    height: 50%;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 40%,rgba(0, 0, 0, 0.534) 100%);
  }

  .property-hero-expand-carousel-cta__container {
    bottom: 62px;
    font-size: 18px;
  }
}

@media (min-width: $small) {
  .property-hero__property-details {
    padding: 0;
  }

  .property-hero-expand-carousel-cta__container {
    bottom: 0;
  }
}


