@import '~src/styles/breakpoints';
@import '~src/styles/colors';
@import '~src/styles/fonts';
@import '~src/styles/measurements';

.settings {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0 auto;
  max-width: 560px;
}

.termsList {
  margin: 0 0 ($measurement--grid-unit * 4);
  padding: 0;
}

.termItem {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  line-height: 2;
}

.termName {
  color: $color--dark-text-on-light-bg;
  flex: 0 0 30%;
  font-family: $font--bx-standard;
  font-weight: 500;
  line-height: inherit;
}

.termValue {
  color: $color--body-text-on-light-bg;
  flex: 0 0 60%;
  line-height: inherit;
}

.areYouSureSubHeading {
  color: $color--brand-salmon;
  text-align: center;
}

.yesNoBtns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 370px;

  & > * {
    flex-basis: 49%;
  }
}

.settingsBulletList {
  list-style: none;
  margin: 0;
  padding: ($measurement--grid-unit * 2) 0 0;
}

.settingsBulletListItem {
  line-height: $measurement--body-list-line-height;
  margin: 0 0 ($measurement--grid-unit * 1) ($measurement--grid-unit * 3);
  padding: 0 0 0 ($measurement--side-gutter);
  position: relative;

  &:before {
    content: "\2022";
    left: 0;
    position: absolute;
  }
}

.bankDetailsInputArea {
  display: flex;
  flex-flow: column nowrap;
}

.bankDetailsForm {
  display: flex;
  flex-flow: column nowrap;
  padding: $measurement--side-gutter;
}

.deactivateModalContent {
  margin: auto;
  max-width: 450px;
  text-align: center;
}


// Desktop layout:
@media (min-width: $breakpoint--medium) {
  .bankDetailsFormContainer {
    display: flex;
    flex-flow: row nowrap;
  }
  .bankDetailsFormInfoBox {
    align-self: flex-start;
    flex: 0 0 45%;
    margin: ($measurement--grid-unit * 3) 0 0 $measurement--side-gutter;
    order: 2;
  }
  .bankDetailsFormInputsArea {
    flex: 1;
  }
}
