.form-control {
  font-weight: 500;;
}

.form-help {
  font-weight: 400;
  font-size:12px;
  color:#777;
  b {
    font-weight: 500;;
    font-weight:normal;
  }
}
.control-label {
  font-weight: 500;;
  font-weight:normal;
}
.form-message__container {
  .form-message__item {
    color:$red;
    font-size:12px;
    margin-top:8px;
    font-weight: 500;
  }
}
.form-control-static {
  padding: 7px;
  background: #f1f1f1;
  border-radius: 2px;
  font-weight: 400;
}
.form-group.has-error {
  .control-label {
    color: $red;
  }
  .form-control {
    border-color: $red;
  }
}

.form-message__item_error {
  color:$red;
}