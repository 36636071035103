@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.whiteSpaceAboveFooter {
  padding-bottom: $measurement--grid-unit * 20;
}

.twoWaysSecondaryCtaContainer {
  text-align: center;
}

.twoWaysExpandable__list {
  padding: 0;
}

.twoWaysExpandable__itemContainer {
  align-items: flex-start;
  display: flex;
}

.twoWaysExpandable__itemCounter {
  margin-right: $measurement--grid-unit * 2;
  margin-top: $measurement--grid-unit + 2; // to align it with the top of the item text
}

@media (min-width: $breakpoint--small) {
  .whiteSpaceAboveFooter {
    padding-bottom: 0;
  }
}
