.portfolio-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.portfolio-widget__total-value {
  margin-bottom: 32px;
}

.portfolio-widget__no-bricks-placeholder {
  width: 100%;
  max-width: 448px;
  margin-bottom: 32px;
}

.portfolio-widget__chart {
  width: 100%;
}
