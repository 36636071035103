@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/embellishments';

.root {
  padding: ($measurement--grid-unit * 20) 0;
}

.linkBreakToNewLine {
  display: block;
}

.headingImage {
  display: block;
  margin: 0 auto $measurement--grid-unit * 6;
  width: 64px;
}

.headingSpaceBelow {
  margin-bottom: $measurement--grid-unit * 10;
}

.subHeadingAndContentItem {
  margin: 0 0 ($measurement--grid-unit * 10) 0;

  &:last-child {
    margin: 0;
  }
}

@media (min-width: $breakpoint--small) {
  .root {
    padding: ($measurement--grid-unit * 27) 0;
  }

  .headingSpaceBelow {
    margin-bottom: $measurement--grid-unit * 12;
  }

  .subHeadingAndContentContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around
  }

  .subHeadingAndContentItem {
    flex: 0 0 40%;
    margin-bottom: 0;
  }
}
