@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.modals {
  padding: 0;
  margin: 0;
}

.modal {
  background: rgba(255, 255, 255, 0.95);
}

.modal :global {
  animation: moveUpAndFadeIn 350ms ease-in-out;
  animation-delay: 0ms;
  animation-fill-mode: both;
}

.exitAnimation :global {
  animation: moveDownAndFadeOut 350ms ease-in-out;
  animation-delay: 0ms;
  animation-fill-mode: both;
}

.modal--inner {
  display: flex;
  min-height: calc(100vh - 65px);
  padding: 24px;
}

.modal--inner--centeredContent {
  margin: auto;
}

.modal--closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  cursor: pointer;
  color: #b3b3b8;
}

.modal--closeButton:hover {
  color: #ff8282;
  text-decoration: none;
}

.modal--closeButton:active {
  color: #6eb4f0;
}

.modal--closeButton--icon {
  font-size: 28px;
}
