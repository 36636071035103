.expiry-date-fieldset {

  .inputs-row {
    display: flex;
    flex-flow: row nowrap;
  }

  .select-box {
    &:nth-child(2) {
      margin: 0 8px;
    }

    select {
      min-width: 62px;
    }
  }

  .error-msging {
    display: none;
    padding: 5px 0 0;

    &.active {
      color: $red;
      display: block;
    }
  }
}
