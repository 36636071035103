

/***************
  All Pages
***************/

// .account {
//   padding: 0 0 40px 0;
// }

.card-cta {
  margin:40px 0;
  text-align:center;
  h4 {
    margin-bottom:0px;
  }
  p {
    font-size: 16px;
    margin-bottom:20px;
    margin-top:10px !important;
  }
  a {
    padding:4px 20px;
  }
}

/***************
  Index Page
***************/
// .account-note {
//   background:#d9edf7;
//   border-radius:$standard-radius;
//   border:solid 1px #5f9dba;
//   padding:12px;
//   font-weight: 500;
//   font-size:13px;
//   margin-bottom:20px;
//   color:#31708f;
//   &.account-success {
//     color:#3c763d;
//     background:#dff0d8;
//     border-color:#5cb85c;
//   }
//   .fa {
//     margin-right: 5px;
//   }
// }

.modal-poli {
  .modal-content {
    padding:40px;
  }
  h4 {
    margin-bottom:0px;
    margin-top:40px;
    .fa {
      color:$orange;
    }
  }
}
.my-details-not-provided {
  color:#aaa;
  font-size:13px;
}

/***************
  Settings
***************/
// .tfn-status {
//   font-weight: 500;
//   padding: 10px;
//   border: solid 1px #ddd;
// }


/***************
  Common/Personal
***************/
// .personal-details-button {
//   margin-bottom: 20px;
// }
