


#faq-page {
  background-color: #FEFFFF;
  padding-bottom: 100px;

  .question-link {
    cursor: pointer;
  }
  .search-bar {
    margin-bottom: 15px;
    position: relative;
    input {
      color: $blue;
      font-weight: 500;
    }
    .icon-search {
      position: absolute;
      right: 15px;
      top: 5px;
      width: 35px;
    }
  }
  .menu {
    top: 10px;
    ul {
      list-style: none;
      padding: 0;
      li {
        font-weight: 500;
        a {
          color: $dark-blue;
          cursor: pointer;
          display: block;          
          padding: 8px 0;
          padding-left: 15px;
          position: relative;
          .fa {
            display: none;
            font-weight: bold;
            left: 0;
            position: absolute;
            top: 11px;
          }
          &.selected {
            color: $blue;
            .fa {
              display: inline-block;
            }
          }
        }
      } 
    }
  }
  .menu-select {
    position: relative;
    select {
        background: $blue;
        border-radius: 0;
        color: #FFF;
      margin-bottom: 15px;
        width: 100%;
    }
    .fa {
      color: #FFF;
      font-size: 25px;
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }

  .call-support-team {
    font-weight: 500;
    margin-top: 40px;
    max-width: 262px;
    img {
        display: block;
        margin-bottom: 5px;
      width: 40px;
    }
    &.left-side-icon {
      max-width: 100%;
      padding-left: 50px;
    }
  }
  .faq-panel {
    border: 1px solid #DDD;
    padding: 20px;

    @media(max-width: $small) {
      border: none;
      padding: 0;
    }

    .panel-breadcrumb {
      color: $dark-blue;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 15px;
      margin-left: 15px;
      span {
        border-left: 2px solid #DDD;
        color: $blue;
        padding-left: 4px;
      }
    }
    .panel {
      border-radius: 0;
      margin: 0;

      .panel-heading {
        padding: 0;
      }


      .panel-title {
        background-color: $light-blue;
        color: $dark-blue;
        cursor: pointer;
        display: block;
        font-size: 16px;          
        font-weight: 500;
        padding: 14px 15px;
        position: relative;
        .fa-angle-up {
          display: block;
        }
        .fa-angle-down {
          display: none;
        }
        .fa-angle-up, .fa-angle-down {
          font-size: 22px;
          position: absolute;
          right: 15px;
          top: 10px;
        }
        &:focus, &:hover {
          text-decoration: none;
        }
      }

      &.collapsed {
        .panel-collapse {
          display: none;
        }
        .panel-title {
          background-color: #f5f5f5;
          .fa-angle-up {
            display: none;
          }
          .fa-angle-down {
            display: block;
          }
        }
      }
      .formula {
        background:#fafafa;
        border:dotted 1px #ddd !important;
        color:#222;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top:20px;
        padding:15px;
        .left {
          font-weight: 500;
          line-height:60px;;
        }
        .right {
          font-weight: 500;
          line-height:30px;;
          .numerator {
            border-bottom:solid 1px #ccc;
            font-weight: 500;;
          }
        }
      }
    }
  }
}