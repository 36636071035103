@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.footer {
  background: $charcoal;
  padding: ($measurement--grid-unit * 10) 0;
}

.metaNavColumns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.logoContainer {
  flex-basis: 100%;
  margin: 0 0 ($measurement--grid-unit * 10);
  @media (max-width: $breakpoint--medium) {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.socialContainer {
  margin-top: 200px;
  color: $color--footer-link-text;
  .socialTitle {
    font-weight: 800;
    letter-spacing: 1px;
  }

  .socialLinkContainer {
    display: flex;
    align-items: center;
  }

  .socialLink {
    color: $color--footer-link-text;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 5px;
    text-decoration: none;
  }

  .socialLink:hover {
    cursor: pointer;
    color: $charcoal;
    background-color: $color--footer-link-text;
  }

  @media (max-width: $breakpoint--medium) {
    & {
      margin-top: 0px;
    }
    .socialTitle {
      display: none;
    }
    .socialLink {
      color: $charcoal;
      background-color: $color--footer-link-text;
    }
  }
}



.linkList {
  flex-basis: 50%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: $measurement--grid-unit * 10;
}

.linkListHeading {
  color: white;
  margin: 0 0 ($measurement--grid-unit * 6);
}

.linkListItem {
  color: $color--footer-link-text;
  font-size: $measurement--small-body-text-size;
  padding: 0 0 ($measurement--grid-unit * 2);
  text-decoration: none;

  &:hover {
    color: $color--footer-link-text-hover;
  }
}

.termsText {
  color: $color--footer-terms-text;
}




// 3 columns layout:
@media (min-width: $breakpoint--small) {
  .logoContainer {
    flex-basis: 100%;
  }

  .linkList {
    flex-basis: 33%;

    &:nth-last-child(2),
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .metaNavColumns {
    justify-content: flex-start;
  }
}

// 5 columns layout:
@media (min-width: $breakpoint--medium) {
  .logoContainer {
    flex-basis: 40%;
    margin: 0;
  }

  .metaNavColumns {
    flex-flow: row nowrap;
  }

  .linkList {
    margin-bottom: 0;
  }
}
