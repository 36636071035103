.experiment--AB--19-Sept-2018-homepage-video-change,
.experiment--AB--24-sept-2018-homepage-redesign,
.experiment--AB--24-oct-2018-hiw-page-redesign {
  .control {
    display: block;
  }
  .variant {
    display: none;
  }

  &.variant-active {
    .control {
      display: none;
    }
    .variant {
      display: block;
    }
  }
}
