@import '~src/styles/colors';
@import '~src/styles/measurements';

.selectList {
  background-color: white;
  border: 1px solid $color--input-border;
  list-style: none;
  margin-top: $measurement--grid-unit;
  max-height: 220px;
  overflow: scroll;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.selectList:focus {
  border-color: $color--input-border-focus;
  outline: none;
}

.selectList--item {
  border-bottom: 1px solid $color--input-border;
  cursor: pointer;
}

.selectList--item:hover,
.selectList--item-highlighted.selectList--item-highlighted {
  background-color: $pink-50;
  color: $color--brand-salmon;
}
