@import "~src/styles/z-index";

$property-card-header-padding: 15px;
$property-card-flag-width: 44px;

.property-card-header {
  display: flex;
  justify-content: space-between;
  padding: $property-card-header-padding;
  font-family: $font--bx-standard;
}

.property-card-header--future {
  background-color: $color--brand-salmon;
}

.property-card-header--market-open {
  background-color: $color-property-card-header;
}

.property-card-header--pre-launch {
  background-color: $color-property-card-pre-launch-header;
}

.property-card-header--coming-soon {
  background-color: $color-property-card-coming-soon-header;
}

.property-card-header__property-code {
  font-size: 1em;
  // color: $property-code-title-color;
  color: $white;
}

.property-card-header__location {
  font-size: 1.5em;
  color: white;
}

.property-card-header__flag-container {
  position: relative;
  width: $property-card-flag-width;
  margin-top: -19px;
}

.property-card-header__flag-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $z-index--property-card-flag;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
}

.property-card-header__flag-container::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px; // to avoid sub-pixel rendering issue resulting in bleeding of the flag color underneath this
  border: ($property-card-flag-width/2) solid transparent;
}

.property-card-header__flag-container--pre-launch::after {
  border-bottom-color: $color-property-card-pre-launch-header;
}

.property-card-header__flag-container--market-open::after {
  border-bottom-color: $color-property-card-header;
}

.property-card-header__flag-container--coming-soon::after {
  border-bottom-color: $color-property-card-coming-soon-header;
}
