@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

$bottom-background-image-height: 480px;

.subFooter {
  padding: 0 $measurement--side-gutter;
  position: relative;
}

.innerContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: $measurement--wide-page-container-max-width;
}


// @TODO: this boxed area style seems to be common.
// This should maybe be extracted into it's own component...
.ctaBoxedArea {
  align-items: stretch;
  background: white;
  border-radius: var(--bx-radius);
  border-top: var(--bx-emb-widget-top-highlight);
  box-shadow: var(--bx-shadow);
  display: flex;
  flex-flow: column nowrap;
  order: 1;
  overflow: hidden;
  padding: ($measurement--grid-unit * 8);
  position: relative;
}

.getStartedButton {
  margin-top: $measurement--grid-unit * 2;
  width: 100%;
}

.backgroundImage {
  background: $color--sub-footer-image-background;
  height: $bottom-background-image-height;
  margin-left: -$measurement--side-gutter;
  margin-top: -15%;
  object-fit: cover;
  object-position: 85% 100%;
  order: 2;
  width: 100vw;
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .subFooter {
    height: 600px;
    padding-bottom: 0;
  }
  .ctaBoxedArea {
    align-items: flex-start;
    margin: auto 0;
    max-width: 520px;
    width: 65%;
  }
  .ctaTitle {
    width: 100%;
  }
  .inputsRow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }

  // @TODO stupid motherfucking global styles make us need this extra "input"
  input.emailInput {
    flex: 1 1 65%;
    margin-right: $measurement--grid-unit * 2;
    width: auto;
  }

  .getStartedButton {
    flex-basis: 35%;
    margin-top: 0;
  }

  .backgroundImage {
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
  }
}
