

.up-down {
  margin: 0 5px;
  &.up {
    color: $green;
  }
  &.down {
    color: $red;
  }
  .fa {
    margin-right: 5px;
  }
}
