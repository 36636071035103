


#tfn-panel {
  .compact-layout {
    @media (max-width: $medium-large) {
      .settings-blue-notification {
        margin: 0;
        margin-top: 15px;
      }
    }
    label {
      font-size: 16px;
    }
    .settings-blue-notification .col-info-description {
      font-size: 16px;
    }
    input[type=text] {
      margin: 12px 0 5px;
      padding: 12px 14px !important;
    }
  }
}
