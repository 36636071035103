$nav-background-active: $color--brand-salmon;

.property-nav {
  position: relative;
  height: 110px;
  background: var( --bx-color---light-gray);
  @media (max-width: $mobile-large) {
    height: 90px;
  }
  .property-nav-wrapper {
    padding: 10px 0;
    &.affix {
      top: $property-breadcrumbs-height;
      width: 100%;
      z-index: 100;
    }
    ul {
      // background: $white;
      border-radius: var(--bx-ms---standard-border-radius);
      // box-shadow: var(--bx-emb-shadow);
      list-style-type: none;
      margin: 0;
      padding: 5px;
      display: flex;

      & > li:not(:first-child) {
        border-left: 5px solid transparent;
      }
      & > li {
        flex-grow: 1;
        display: inline-block;
        text-align: center;
        padding: 0;
        width: 20%;
        .icn-container {
          margin-top: 10px;
          margin-bottom: 6px;
        }
        & > a {
          position: relative;
          display: block;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
          color: #999;
          text-decoration: none !important;
          padding: 12px 0 13px;
          border-radius: var(--bx-ms---standard-border-radius);
          @media (max-width: $small) {
            padding: 14px 0 8px;
            line-height: 20px;
          }
          @media (max-width: $mobile-large) {
            padding: 8px 0 8px;
          }
          .fa {
            display: block;
            margin-bottom: 5px;
            font-size: 20px;

            @media (max-width: $small) {
              font-size: 16px;
              margin-bottom: 0;
            }
          }
          .icn-house {
            background-image: url(/static/images/icons/icn_house_grey.png);
          }
          .icn-coins {
            background-image: url(/static/images/icons/icn_coins_grey.png);
          }
          .icn-line-chart {
            background-image: url(/static/images/icons/icn_line_chart_grey.png);
          }
          .icn-details {
            background-image: url(/static/images/icons/icn_details_grey.png);
          }
          .tab-name {
            font-size: 13px;

            @media (max-width: $small) {
              // font-size: 8px;
            }
            @media (max-width: $mobile-large) {
              display: none;
            }
          }
          &:hover {
            cursor: pointer;
            text-decoration: none;
            background: #f7f7f7;
            color: #777;
          }
          &.active {
            position: relative;
            background: $orange;
            color: #fff;
            .icn-house {
              background-image: url(/static/images/icons/icn_house_wht.png);
            }
            .icn-coins {
              background-image: url(/static/images/icons/icn_coins_white.png);
            }
            .icn-line-chart {
              background-image: url(/static/images/icons/icn_line_chart_wht.png);
            }
            .icn-details {
              background-image: url(/static/images/icons/icn_details_wht.png);
            }
          }
        }
      }
    }
  }

  @media (max-width:  $mobile-large) {
    .property-nav-wrapper {
      ul {
        & > li {
          .icn-container {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  // Mobile Fixed Tabs
  @media (max-width:  $small) {
    .property-nav-wrapper.affix {
      position: fixed;
      top: $property-breadcrumbs-height-mobile;
      width: 100%;
      z-index: 13;
      background: var( --bx-color---light-gray);
      .container {
        padding: 0;
        ul {
          margin: 0px;
          li a {
            background: var( --bx-color---light-gray);
            border-bottom: solid 1px #ccc;
            padding-bottom: 6px;
            &.active {
              background: $nav-background-active;
              border-bottom: solid 1px $nav-background-active;
            }
          }
        }
      }
    }
  }
}
