@import '~src/styles/colors';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';
@import '~src/styles/measurements';

.formLabel {
  display: block;
  font-family: $font--bx-standard;
  font-size: $measurement--small-body-text-size;
  font-weight: 400;
  margin-bottom: ($measurement--grid-unit * 2);
  text-align: left;
}
