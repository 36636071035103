$property-card-divider-style: 1px solid $color-gentle-divider;

.property-card-body {
  display: flex;
  padding: 15px;
}

.property-card-stats-container {
  display: flex;
  flex-direction: column;
  flex: 2 1 0px;
}

.property-card-stats-container__top,
.property-card-stats-container__bottom {
  display: flex;
  flex: 1 1 auto;
}

.property-card-stats-container__top {
  border-bottom: $property-card-divider-style;
  padding-bottom: 7.5px;
}

.property-card-stats-container__bottom {
  padding-top: 7.5px;
}

.property-card-stats-container__stat-box {
  flex: 1 1 0px;
  text-align: center;
}

.property-card-stats-container__stat-box--right-border {
  border-right: $property-card-divider-style;
}

.property-card-primary-stat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0px;
  border-left: $property-card-divider-style;
}

.property-card-primary-stat-container__stat-box {
  margin-left: 15px;
  text-align: left;
}

@media (min-width: $mobile-large) {
  .property-card-body {
    font-size: 1.1em;
  }
}

@media (min-width: $extra-small--up) {
  .property-card-body {
    font-size: 1.3em;
  }

  .property-card-primary-stat-container__stat-box {
    text-align: center;
  }
}

@media (min-width: $breakpoint--small) {
  .property-card-body {
    flex-direction: column;
  }

  .property-card-stats-container,
  .property-card-primary-stat-container {
    flex: 1 1 auto;
  }

  .property-card-primary-stat-container {
    height: 65px;
    margin-top: 7.5px;
    border-top: $property-card-divider-style;
    border-left: none;
  }

  .property-card-primary-stat-container__stat-box {
    margin: 15px 0 0 0;
  }
}
