@import '~src/styles/colors';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';
@import '~src/styles/measurements';


.formSelect.formSelect {
  display: block;
}

.formSelect__select.formSelect__select {
  background: transparent;
  border: 1px solid $color--form-select-border;
  border-radius: var(--bx-radius);
  color: $color--form-select-text;
  font-family: $font--bx-standard;
  font-size: $measurement--large-body-text-size;
  line-height: inherit;
  padding: ($measurement--grid-unit * 3.5);
  padding-right: ($measurement--grid-unit * 8);

  &:invalid {
    color: $color--input-placeholder;
  }

  &:focus {
    border-color: $color--form-select-border-focus;
  }

  option {
    &:disabled {
      color: $color--input-placeholder;
    }
  }
}

.formSelect_errorHighlight.formSelect_errorHighlight {
  border-color: $color--input-border-error;
}

.fullWidth.fullWidth {
  width: 100%;
}
