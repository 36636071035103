@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';

.backgroundContainer {
  background-color: $white;
}

.offWhiteBackground {
  background-color: $color--landing-pages-grey-background;
}

.root {
  padding:
    ($measurement--grid-unit * 25)
    ($measurement--side-gutter)
    ($measurement--grid-unit * 25);
  text-align: center;
}

.brandedHeading {
  color: $color--brand-salmon;
  display: inline-block;
  line-height: 1.1;
  margin-left: auto;
  margin-right: auto;
  padding: ($measurement--grid-unit * 4) ($measurement--grid-unit * 5);
  position: relative;

  &:before,
  &:after {
    font-family: $font--brickicons;
    font-size: 16px;
    line-height: 1;
    position: absolute;
  }

  &:before {
    content: "left_corner";
    left: 0;
    top: 0;
  }

  &:after {
    bottom: 0;
    content: "right_corner";
    right: 0;
  }
}

.illustratedPoints__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.illustratedPoints__counter {
  margin-bottom: 8px;
}

$item-spacing: $measurement--grid-unit * 7;

.illustratedPoints__item {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: $item-spacing;
  width: 50%;
}

.illustratedPoints__item_text {
  margin: 0 auto;
  max-width: 200px;
}

.illustratedPoints__image {
  margin: 0 auto ($measurement--grid-unit * 3);
  max-width: 64px;
}


.illustratedPoints__truncatedText {
  display: none;
}

@media (min-width: $breakpoint--small) {
  .illustratedPoints__item {
    max-width: 200px;
  }

  .illustratedPoints__truncatedText {
    display: inline;
  }
}
