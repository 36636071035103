.flex-row {
    display: flex;
    flex-direction: column;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

@media (min-width: #{$ipad + 1px}) {
    .flex-row {
        flex-direction: row;
    }
}

.flex-column {
    .panel-financials-table,
    .panel-financials-table .body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.panel-financials-table {
    background-color: $white;
    border: $embellishment--border-standard;
    box-shadow: $embellishment--shadow-standard;
    margin-bottom: 25px;
    border-radius: var(--bx-radius);
    // overflow: hidden;
    h4 {
        background: #fafafa;
        border-bottom: 1px solid #d5d9dd;
        color: #232226;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 12px;
        padding-bottom: 10px;
    }
    .header {
        border-radius: var(--bx-radius) var(--bx-radius) 0 0;
        background-color: var(--bx-color---light-gray);
        border-bottom: 1px solid #d5d9dd;
        color: #232226;
        font-size: 12px;
        font-weight: 500;
        padding: 15px 25px;
        text-align: left;
        .panel-financials-title {
            color: $charcoal;
            font-weight: bold;
            font-size: 20px;
            padding-right: 0px;
            a {
                color: $very-dark-blue;
                text-decoration: underline;
            }
        }
        .link {
            font-size: 13px;
            margin-top: 5px;
            padding-left: 0px;
        }
        .icn {
            margin-right: 10px;
        }
    }

    .fee-free-terms {
        margin-top: 12px;
    }

    .body {
        font-size: 16px;
        font-weight: 400;
        padding: 20px;
        border-radius: 0 0 var(--bx-radius) var(--bx-radius);
        .gray-line {
            background-color: $gray;
            height: 1px;
        }
        .black-line {
            background-color: #000;
            height: 2px;
        }
    }

    .description {
        border-radius: var(--bx-radius);
    }

    .my-row {
        padding: 10px 0px;
    }

    .no-line-height-ml {
        @media (max-width: $medium-large) {
            line-height: normal !important;
        }
    }

    .close-panel {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        position: relative;
        font-size: 20px;
        margin-top: -4px;
        .close-icon {
            border-radius: 50%;
            color: $dark-blue;
            display: inline-block;
            font-size: 20px;
            height: 36px;
            line-height: 34px;
            position: absolute;
            right: 0px;
            text-align: center;
            top: -12px;
            width: 36px;
        }
    }
    .close-panel:hover {
        color: $light-blue;
        .close-icon {
            opacity: 0.9;
            color: $light-blue;
        }
    }
}

// Custom Panels
.panel-financials-no-padding {
    .body {
        padding: 0;
    }
}

// Primary Panels
.panel-financials-with-link {
    .header {
        background: $white;
        border-bottom: 0px solid $white;
        .panel-financials-title .linkText {
            font-size: 12px;
            text-decoration: underline;
        }
    }
    .body {
        background: $white;
    }
}

.panel-financials-transparent-header {
    .header {
        background-color: var(--bx-color---light-gray);
        border-bottom: 1px solid #d5d9dd;
        .panel-financials-title {
            color: $dark-blue;
        }
        .link a {
            color: $dark-blue;
            font-size: 16px;
            text-decoration: underline;
        }
    }
    .body {
        background: $white;
    }
}

.panel-financials-blue-header {
    .header {
        background-color: $dark-blue;
        .panel-financials-title {
            color: #fff;
        }
        .close-panel {
            color: #fff;
        }
    }
}

.panel-financials-salmon-header {
    .header {
        background-color: $salmon;
        .panel-financials-title {
            color: $dark-blue;
        }
        .close-panel {
            color: $dark-blue;
        }
    }
}

.panel-financials-light-blue-header {
    .header {
        background-color: $light-blue;
        .panel-financials-title {
            color: $dark-blue;
        }
        .close-panel {
            color: $dark-blue;
        }
    }
}

.panel-unit-cash-flow {
    .body {
        position: relative;
        .total-expenses {
            border: solid 1px #e8e8e8;
            font-size: 16px;
            font-weight: 500;
            padding: 10px;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 1;
            small {
                color: #777;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.panel-financials-table.panel-financial-video {
    overflow: hidden;
    iframe {
        border: none;
        height: 298px;
        width: 100%;
        @media (max-width: $medium-large) {
            height: 242px;
        }
        @media (max-width: $medium) {
            height: 376px;
        }
        @media (max-width: $small) {
            height: 193px;
        }
    }
}

.panel-financials-monthly-updates {
    min-height: 411px;
}

.panel-financials-table.panel-financials-invest {
    font-weight: 400;
    .table-row {
        margin-bottom: 3px;
        margin-top: 10px;
    }
    .copy {
        font-size: 11px;
    }
    .my-row {
        margin-top: 12px;
    }
    .col-left,
    .col-right {
        font-weight: 500;
        line-height: 29px;
    }
    .col-right {
        font-size: 16px !important;
        text-align: right;
    }
    .trade-error {
        color: grey;
        font-size: 12px;
        margin-top: 3px;
        text-align: center;
    }
    .bricks-sold-last-month {
        display: inline-block;
        font-size: 11px;
        .bricks {
            color: $orange !important;
            font-size: 13px;
            font-weight: bold;
        }
    }
    .trade-button {
        cursor: pointer;
        display: block;
        font-size: 20px;
        font-weight: 500;
        font-weight: normal;
        text-align: center;
        .fa {
            font-size: 23px;
            margin-left: 6px;
        }
    }
}

.panel-financials-reasons-to-invest {
    .body .grey-box {
        background-color: var(--bx-color---light-gray);
        padding: 25px;
        border-radius: var(--bx-radius);
    }
}

.panel-financials-latest-value,
.panel-financials-investment-yield {
    min-height: 392px;
    @media (max-width: $small) {
        min-height: auto;
    }
}

.panel-financials-monthly-distribution {
    min-height: 407px;
    @media (max-width: $small) {
        min-height: auto;
    }
}

.historical-purchase-panel,
.debt-breakdown-panel,
.panel-acquisition-costs {
    min-height: 391px;
    @media (max-width: $small) {
        min-height: auto;
    }
}

.panel-financials-table-icon {
    &.panel-financials-table {
        .body {
            .table-row {
                padding: 10px 0px;
            }
            .col-left {
                font-weight: 500;
                letter-spacing: -0.5px;
                line-height: 26px;
            }
            .col-right {
                color: $dark-gray;
                font-size: 25px;
                text-align: right;
            }
            .row-with-icon {
                .col-right {
                    position: relative;
                    top: 18px;
                }
            }
            .row-without-icon {
                .col-left {
                    line-height: 36px;
                }
            }

            .small {
                .col-left {
                    font-size: 12px;
                    line-height: 23px;
                }
                .col-right {
                    font-size: 18px;
                    top: 22px !important;
                }
                &.row-without-icon {
                    .col-right {
                        top: 0px !important;
                    }
                }
            }
        }
    }
}

.pre-order-panel {
    .trade-error {
        color: grey;
        font-size: 12px;
        margin-bottom: 10px;
        text-align: center;
    }
}

// Shared
.financials-panel__text {
    letter-spacing: -0.5px;
}

.financials-panel__text--orange {
    color: $orange;
}

// Section Title
.financials-panel__section-title {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}

.financials-panel__section-title-icon {
    margin-right: 8px;
}

// Row
.financials-panel-row {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.financials-panel-row--no-padding-top {
    padding-top: 0;
}

.financials-panel-row__title {
    font-weight: 500;
    line-height: 26px;
}

.financials-panel-row__title--no-icon {
    align-self: center;
}

.financials-panel-row__value {
    color: $dark-gray;
    font-size: 25px;
}

// Horizontal Selector
.financials-panel-horizontal-selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.financials-panel-horizontal-selector--reverse {
    flex-direction: row-reverse;
}

.financials-panel-horizontal-selector__option {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 19%;
}

.financials-panel-horizontal-selector__text {
    color: $disabled-text;
    font-weight: 500;
    margin-bottom: 8px;
}

.financials-panel-horizontal-selector__bar {
    background-color: $disabled-background;
    height: 4px;
    width: 100%;
}

.financials-panel-horizontal-selector__text--selected {
    color: inherit;
}

.financials-panel-horizontal-selector__bar--selected {
    background-color: $orange;
}
