.pre-order-info-box, .coming-soon-info-box {
  font-size: 16px;
  .general-info {
    padding: 20px 0px;
    text-align: center;
    min-height: 96px;
    font-weight: 500;
    font-size: 13px;
    color: $dark-blue;

    @media (max-width: $small) {
      padding-bottom: 0;
      min-height: auto;
    }
    img {
      height: 20px;
      display: block;
      margin: 0 auto;
    }
  }
  .general-info::after {
    content: " ";
    display: table;
    clear: both;
  }
}
.pre-order-info-box__funds-available {
  text-align: center;
  margin-top: 40px;
}
hr {
  border-color: #DDD;
}
.text-off-black {
  color: #666 !important;
}
