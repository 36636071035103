.property-card__button-container {
  display: none;
  padding: 0 15px 15px 15px;
}

.property-card__button-container-background--blue {
  background-color: $color--no-fees-pre-order-card-banner-background;
}

.property-card__button {
  width: 100%;
}

@media (min-width: $mobile-landscape--up) {
  .property-card__button-container {
    display: block;
  }
}
