.property-card-flag {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: $property-card-header-padding;
  font-size: 0.8em;
  text-align: center;
}

.property-card-flag--pre-order {
  background-color: white;
  color: $blue;
  line-height: 1;
  letter-spacing: -1px;
}

.property-card-flag--emphasized-text {
  font-size: 1.5em;
}

.property-card-flag--emphasized-text-md {
  font-size: 1.2em;
}

.property-card-flag--new-property {
  background-color: $orange;
  color: white;
  line-height: 1;
  letter-spacing: -1px;
}

.property-card-flag--coming-soon {
  background-color: white;
  color: $dark-green;
  line-height: 1;
  letter-spacing: -1px;
}

.property-card-flag--grey {
  background-color: $grey;
  color: white;
  line-height: 1;
  letter-spacing: -1px;
}
