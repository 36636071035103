.expanding-carousel__modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
}

.expanding-carousel__modal--shown {
  visibility: visible;
}
