


.annual-individual-statement {

  .download-icon {
    text-align: right;
    margin: 22px 10px;
    margin-right: 0px;
  }
  .download-icon a {
    color: #000;
    text-decoration: none;
    background-color: #eee;
      padding: 8px 12px;
      border-radius: 3px;
      border: 1px solid #d5d9dd;
    font-weight: 500;
      font-size: 16px;
      margin-left: 10px;

  }
  .download-icon a:hover {
    color: $orange;
  }
  .download-icon i {
    font-size: 20px;
    margin-left: 5px;
    position: relative;
    top: 3px;
  }
}
