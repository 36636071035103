@import '~src/styles/colors';

.selectionButtonFullWidth {
  width: 100%;
}

.textInputErrorBorderColor {
  border-color: $color--input-border-error;
}

.minimumAmountErrorColor {
  color: $color--form-label-text-error;
}
