.dollar-text-input {
  display: flex;
}

.dollar-text-input__appendage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $color-text-input-border;
  padding: 0 16px;
}

.dollar-text-input__prefix {
  border-right: none;
}

.dollar-text-input__suffix {
  border-left: none;
}

.dollar-text-input__appendage--disabled {
  color: $disabled-text;
}
