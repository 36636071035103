

.terms-page {
  padding:40px 0;
  h1 {
    border-bottom:solid 1px #ddd;
    font-size:30px;
    font-weight: 500;
    margin-bottom:20px;;
    margin-top:0px;
    padding-bottom:20px;
  }
  h2 {;
    font-size:20px;
    font-weight: 500;
    margin:20px 0;
  }
  p {
    font-size:15px;
    font-weight: 400;
    line-height:30px;
  }
  ul {
    li {
      font-size:13px;
      font-weight: 400;
      line-height:26px;
    }
  }

  .indented {
    padding-left: 40px;
  }
  .terms-banner {
    margin-bottom: 20px;
    text-align: center;
  }
}

.banner {
  padding: 20px 0;
  position: relative;
}