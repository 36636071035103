


.latest-value-panel {
  position: relative;
  .blue-label {
    background-color: $light-blue;
    border-radius: 18px;
    color: $very-dark-blue;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 5px 25px;
  }

  .icn-medium {
    padding: 10px 20px;
  }

  .my-row {
    padding: 15px 0px;
  }

  .col-left {
    color: $blue;
    font-size: 30px;
    padding-right: 0px;
    text-align: center;
    text-align: right;
  }
  .col-right {
    color: $dark-blue;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    .popover-container {
      position: absolute;
      right: 18px;
      top: 0px;
    }
    .value {
      color: $dark-gray;
      font-size: 18px;
      font-weight: normal;
      font-weight: 300;
    }
  }
  .total {
    color: $dark-blue;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .see-faq {
    margin-top: 40px;
    a {
      text-decoration: underline;
    }
  }

}

.investment-case-panel {
  .description {
    ul {
      list-style: none;
      padding: 0;
      li::before {
        color: $orange ;
        content: "\2022 ";
      }
    }
  }
}
