@import '~src/styles/measurements';
@import '~src/styles/colors';

.container {
  max-width: 400px;
  margin: 0 auto;
  border-radius: $measurement--standard-border-radius;
  overflow: hidden;
}

.reactModalOverrides {
  color: $color--body-text-on-light-bg;
  background-color: white;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ($measurement--grid-unit * 8) ($measurement--grid-unit * 6);
}

.imageContainer {
  display: none;
}

.howToClaimListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ($measurement--grid-unit * 6);
  background-color: $color--partnership-how-to-claim-list-background;
}

.howToClaimList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.howToClaimList__item {
  display: flex;
}

.howToClaimList__number {
  margin-right: $measurement--grid-unit * 2;
}

.howToClaimList__item + .howToClaimList__item {
  margin-top: $measurement--grid-unit * 2;
}

@media (min-width: 1120px) {
  .container {
    display: flex;
    max-width: none;
  }

  .contentContainerWrapper,
  .imageContainer {
    flex: 1 0 0px;
  }

  .contentContainer {
    padding: $measurement--grid-unit * 8;
  }

  .imageContainer {
    display: block;
    background-color: $color--partnership-how-to-claim-list-background;
  }

  .image {
    width: 100%;
  }
}
