

.suburb-chart {
  .suburb-statistics {
    padding: 20px 0;

    .title {
      font-weight: normal;
      text-align: center;
    }

    .form-inline .form-group {
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 80%;
    }

    .form-control {
      border: solid 2px $orange;
      width: 100%;
    }

    .location-profiles-chart {
      margin: 20px auto 0;
    }
  }
  .disclaimer {
    color: #777;
    font-weight: 400;
    line-height: 40px;
    margin-top: 20px;
    text-align: right;
  }
}
