p#errorText, p#successText {
  margin: 0;
  font-size: 16px;
}

//** Custom: Alerts **//

@keyframes alert-appear {
  from { transform: translateY(10px); opacity: 0; }
  to {transform: translateY(0px); opacity: 1; }
}

@keyframes alert-failure-shaking {
  from { transform: translateY(10px); opacity: 0; }
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
  to {transform: translateY(0px); opacity: 1; }
}

.alerts {
  position:fixed;
  right:0;
  left:0;
  top: 230px;
  width:400px;
  z-index:100000;
  margin-right:auto;
  margin-left:auto;
  padding:0;
  
  li {
    list-style-type:none;
  }

  .alert {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border: 1px solid transparent;
    border-radius: 0;
    margin: 40px auto 0;
    font-size: $measurement--small-body-text-size;
    animation: alert-appear 0.2s;
    overflow: hidden;
    padding: 0;
    border-radius: var(--bx-radius);
  }

  .alert h4 {
    margin-top: 0;
    color: inherit;
  }
  .alert .alert-link {
    font-weight: 500;
  }
  .alert > p,
  .alert > ul {
    margin-bottom: 0;
  }
  .alert > p + p {
    margin-top: 5px;
  }
  .alert-message {
    padding: 25px;
    text-align: center;
  }
  .alert-dismissable,
  .alert-dismissible {

  }
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
  .alert-success {
    color: $white;
    background-color: $color--brand-azure;
    border-color: transparent;
  }
  .alert-success hr {
    border-top-color: $blue-400;
  }
  .alert-success .alert-link {
    color: $white;
  }
  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
  .alert-info hr {
    border-top-color: #a6e1ec;
  }
  .alert-info .alert-link {
    color: #245269;
  }
  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }
  .alert-warning hr {
    border-top-color: #f7e1b5;
  }
  .alert-warning .alert-link {
    color: #66512c;
  }
  .alert-danger {
    animation: alert-failure-shaking 0.3s;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-danger hr {
    border-top-color: #e4b9c0;
  }
  .alert-danger .alert-link {
    color: #843534;
  }
  .close-btn {
    text-align: center;
    margin: 15px 0 0;
    padding: 8px;
    color: inherit;
    transition: all .3s;
    user-select: none;
    border-top: 1px solid rgba(100, 100, 100, 0.1);
  }
  .close-btn:hover {
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.1);
  }
  .close {
    float: right;
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    color: $white;
    text-shadow: none;
    opacity: 1;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
  .close:hover,
  .close:focus {
    opacity: 0.6;
  }

  @media (max-width: $small) {
    width: 100%;
    top: 0;
    .alert {
      border-radius: 0;
      margin-top: 0;
      box-shadow: 0 1px 2px rgba(0,0,0,0.4);
      p {
        // font-size: 12px;
        // line-height: 22px;
      }
    }
  }
}
