

.properties-list-placeholder {
  padding: 0 15px;
}

.property-card-placeholder {
  border: 1px solid #d5d9dd;
  margin-bottom: 15px;
  border-radius: var(--bx-ms---widget-border-radius);
  overflow: hidden;
  .header-placeholder {
    height: 281px;
    width: 100%;
    position: relative;
    img {
      left: 0;
      right: 0;
      margin: auto;
      top: 20%;
      position: absolute;
      height: 60%;
    }
    @media(max-width: $small) {
      height: 243px;
    }
  }
  .body {
    position: relative;
    .body-content-placeholder {
      padding: 15px;
      @media(max-width: $small) {
        max-height: 73px;
        padding: 10px 15px;
      }
    }
    .financial-info-placeholder {
      line-height: 15px;
      margin-bottom: 15px;
      padding: 16px 4px;
    }
    .general-info-placeholder {
      padding: 20px 0px;
      min-height: 96px;
      @media(max-width: $small) {
        padding: 0px 0px;
        min-height: 73px;
      }
    }
    .brick-price-info-box-placeholder {
      padding: 44px 4px;
    }
    .button-placeholder {
      padding: 23px 4px;
      margin: 14px 0 0 0;
      @media(max-width: $small) {
        padding: 20px 4px;
        margin: 10px 0 0 0;
      }
    }
  }
}