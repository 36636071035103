

.panel-coming-soon {
  position: relative;
  .coming-soon-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-align: center;
    p {
      text-align: center;
      margin: 120px 0 0;
      color: $white;
      font-weight: 500;
      border: solid 2px $white;
      display: inline-block;
      padding: 10px;
    }
  }
}