.widget {
  border: 1px solid #d5d9dd;
  border-bottom-color: #c7ccd1;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  background: $white;
  padding: 20px;
  text-align: center;
  position: relative;
  margin: 30px 0 0;
  h3 {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: normal;
  }
  .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .hide-cta {
    visibility: hidden !important;
  }
  &.widget-hover:hover {
    border-color: $orange;
    cursor: pointer;
    h3 {
      color: $orange;
    }
  }
  &.widget-notification {
    background: #fff2f3;
    .notification {
      position: absolute;
      top: -16px;
      right: -16px;
      height: 50px;
      width: 50px;
      border-radius: $standard-radius;
      padding: 9px 15px;
      font-size: 21px;
      border-radius: 500px;
      color: $white;
      background: $red;
      @media (max-width: $small) {
        top: -9px;
        right: -9px;
        height: 30px;
        width: 30px;
        font-size: 13px;
        padding: 6px 9px;
      }
    }
    &.widget-hover:hover {
      border-color: $red;
      h3 {
        color: #bf000a;
      }
    }
  }
}
