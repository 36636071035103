$property-card-stat-label-line-height: 1.2;

.property-card-stat__label {
  height: $property-card-stat-label-line-height * 2em;
  color: $color-dark-blue-text;
  font-family: $font--bx-standard;
  font-size: 0.7em;
  line-height: $property-card-stat-label-line-height;
}

.property-card-stat__label--primary {
  height: auto;
}

.property-card-stat__value {
  color: $color-light-secondary-text;
  font-size: 1.2em;
}

.property-card-stat__value--primary {
  color: $color-dark-blue-text;
  font-family: $font--bx-standard;
}
