@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/embellishments';

.propertySmartInvestSignupDriver__card {
  border-top: var(--bx-emb-widget-top-highlight);
  border-radius: var(--bx-ms---widget-border-radius);
  padding: $measurement--grid-unit * 8;
  box-shadow: var(--bx-emb-shadow);
  background-color: $white;
}

.propertySmartInvestSignupDriver__heading {
  font-weight: 600;
}

.propertySmartInvestSignupDriver__text {
  margin-bottom: $measurement--grid-unit * 4;
}

.propertySmartInvestSignupDriver__ctas {
  display: flex;
  flex-direction: column;
}

.propertySmartInvestSignupDriver__signupButton {
  margin-bottom: $measurement--grid-unit * 2;
}

@media (min-width: $breakpoint--medium) {
  .propertySmartInvestSignupDriver__content {
    display: flex;
  }

  .propertySmartInvestSignupDriver__text,
  .propertySmartInvestSignupDriver__ctas {
    flex: 1 0 0px;
  }

  .propertySmartInvestSignupDriver__text {
    margin-bottom: 0;
  }

  .propertySmartInvestSignupDriver__ctas {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .propertySmartInvestSignupDriver__signupButton {
    margin-bottom: 0;
    margin-right: $measurement--grid-unit * 2;
  }
}
