


#property-investment {
  padding: 30px 0 0 0;

  h1 {
    margin-bottom: 20px;
  }
  .consider-pds {
    margin-bottom: 20px;
    font-size: 13px;
    color: #999;
    font-style: italic;
    a {
      text-decoration: underline;
    }
    @media (max-width: $mobile-large) {
      font-size: 12px;
      margin-bottom: 30px;
    }
  }

  .section {
    padding: 50px 0;
  }

  .sign-up-top {
    background-color: $very-dark-blue;
    font-size: 22px;
    color: #FFF;
    text-align: center;
    padding-top: 15px;
    font-weight: 400;
  }

  .video {
    text-align: center;
    margin-top: 15px;
    iframe {
      height: 300px !important;
    }
  }

  h1 {
    margin-top: 0 !important;
    @media (max-width: $small) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .how-does-it-work-simple {
    .button {
      display: none;
    }
  }

  .signup__extra {
    display: none;
  }

  @media (max-width: $medium) {
    padding: 10px 0;
    .video {
      margin-top: 0;
      iframe {
        width: 100%;
        height: 250px !important;
      }
    }
  }
  @media (max-width: $small) {
    h1 {
      font-size: 25px;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
    .sign-up-top {
      margin-top: 15px;
    }
  }


  &.property-investment-in {
    .content {
      margin-top: 30px;
    }
    .property-card {
      .button.orange-button, .blue-button {
        display: none;
      }
      .discount {
        display: none;
      }
      .picture-box {
        cursor: default;
        .picture {
          transition: none;
          transform: none;
        }
      }
    }
    .img-under-signup {
      width: 100%;
      margin-top: 7px;
    }
    .signup-form {
      padding: 30px 20px;
    }

    @media (max-width: $small) {
      .sign-up-top {
        margin-top: 0;
      }
    }

    @media (min-width: $small) {
      .more-padding-right {
        padding-right: 50px !important;
      }

      .more-padding-left {
        padding-left: 50px !important;
      }
    }
  }
}
