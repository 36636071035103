@import '~src/styles/breakpoints';

.onboarding-screen {
  padding: ($measurement--grid-unit * 4);
  min-height: calc(100vh - #{$measurement--header-height});
  display: flex;
}

.onboarding-screen__inner-centered {
  margin: auto;
}

.onboarding-screen__inner-vertical-centered {
  margin-left: auto;
  margin-right: auto;
}

.onboarding-screen__header {
  max-width: 720px;
  text-align: center;
}

.onboarding-screen__content {
  max-width: 480px;
  padding-bottom: 50px;
}

.onboarding-screen__content--wide {
  max-width: 560px;
}

.onboarding-screen__header,
.onboarding-screen__content {
  margin: 0 auto;
}

.onboarding-screen__form-element--margin-8 {
  margin-bottom: 8px;
}

.onboarding-screen__form-element--margin-16 {
  margin-bottom: 16px;
}

.onboarding-screen__form-element--margin-24 {
  margin-bottom: 24px;
}

.onboarding-screen__form-element--centered {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.onboarding-screen__form-select {
  margin-bottom: 8px;
}

@media (min-width: $breakpoint--small) {
  .onboarding-screen__form-field-container {
    display: flex;
    align-items: center;
  }

  .onboarding-screen__form-field-label {
    flex: 1;
    margin: 0;
    font-size: 16px;
  }

  .onboarding-screen__form-field {
    flex: 2;
    margin: 0;
  }
}