#property-team {

  color: $dark-blue;
  font-weight: 400;

  .intro {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 80px;
    text-align: center;
  }

  .bottom {
    background-color: #EEE;
    margin-top: 60px;
    padding: 60px 0;
    text-align: center;

    .image {
      .icn {
        line-height: 90px;
        padding: 45px;
      }
    }

    .title {
      color: $orange;
      font-size: 22px;
      margin: 15px 0;
    }

    .description {
      font-size: 16px;
      line-height: 26px;

      @media (max-width: $mobile-large) {
        margin-bottom: 30px;
      }
    }
  }

  .card {
    font-size: 16px;
    margin-bottom: 25px;

    @media (max-width: $mobile-large) {
      margin-bottom: 60px;
    }

    .identity {
      background-color: #F2F2F2;
      border: 1px solid #eee;
      margin-bottom: 15px;
      .bio {
        padding: 15px;
      }
    }

    .title {
      color: $color--brand-salmon;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .description {
      height: 160px;
      line-height: 26px;
      margin-bottom: 35px;

      @media (max-width: $medium-large) {
        height: 180px;
      }

      @media (max-width: $medium) {
        height: 155px;
      }

      @media (max-width: $mobile-large) {
        height: auto;
        margin-bottom: 15px;
      }
    }
    .photo {
      background-image: url(/static/images/people/markus_color.jpg);
      background-position: center -22px;
      background-size: cover;
      height: 290px;
      position: relative;

      .infos {
        bottom: 0;
        font-size: 16px;
        padding: 10px 15px;
        position: absolute;
        width: 100%;

        .infos-inner {
          background-color: rgba(255, 255, 255, 0.8);
          padding: 10px 15px;

          .name {
            font-weight: 500;
          }

          .title {
            font-size: 16px;
            margin-bottom: 5px;
          }

          .read-bio {
            cursor: pointer;
            font-size: 13px;

            a {
              color: $dark-blue;
            }

            .fa {
              color: $orange;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
