#returns-calculator {
  padding-bottom: 40px;
  .clickable {
    text-decoration: underline;
    color: $orange;
    cursor: pointer;
  }
  .description {
    margin-bottom: 40px;
  }

  .info-box-disclosures {
    .settings-blue-notification {
      background-color: transparent;
      .col-info-description {
        color: $charcoal;
      }
      @media (max-width: $mobile-large) {
        margin-bottom: 0;
      }
    }
  }

  .blue-background {
    background-color: $dark-blue;
    color: #FFF;
  }
  .calculator {
    padding-bottom: 20px;
  }

  .very-blue-background {
    background-color: $very-dark-blue;
    @media (max-width: $mobile-large) {
      background-color: transparent;
    }
  }

  .filters {
    padding: 15px;
    h3 {
      color: $orange;
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      @media (max-width: $mobile-large) {
        font-size: 15px;
        margin-top: 0;
        line-height: 1.3;
      }
    }
    .form-group {
      padding-top: 15px;
      .label-input {
      }
      @media (max-width: $mobile-large) {
        padding-top: 0;
      }
    }
  }

  .dollar-input {
    input {
      border-left: 0;
      padding: 12px 14px;
      width: 85% !important;
      @media (max-width: $mobile-large) {
        padding: 7px 14px;
      }
    }
    &::before {
      padding: 12px 8px;
      width: 15%;
      @media (max-width: $mobile-large) {
        padding: 7px 8px;
      }
    }
    width: 180px;
    font-size: 18px;
    @media (max-width: $mobile-large) {
      width: 100%;
      margin-top: 5px;
    }
  }

  .select-box {
    font-weight: 400;
    @media (max-width: $mobile-large) {
      width: 100%;
    }
  }
  select {
    font-size: 18px;
    padding: 15px 14px;
    border-radius: 0;
    min-width: 180px;
    height: auto;
    padding: auto 15px;
    @media (max-width: $mobile-large) {
      min-width: 100%;
      width: 100%;
      padding: 11px 14px;
      margin-top: 5px;
    }
  }

  hr {
    border-color: $dark-blue;
  }

  .disclaimer {
    padding: 25px 0;
    // color: #BBB;

    @media (max-width: $mobile-large) {
      font-size: 13px;
      line-height: 1.3;
    }


  }

  .invite {
    text-align: center;
    margin: 40px 0 60px;
    h3 {
      display: inline-block;
      @media (max-width: $mobile-large) {
        font-size: 16px;
      }
    }
    .button {
      margin-left: 20px;
      font-size: 18px;
    }
  }

  .how-do-i-make-money {
    margin-top: 30px;
  }

  .calculator-form-fields {
    margin: 30px 0;
    // border: 1px solid $grey-200;
    border-radius: var(--bx-radius);
    box-shadow: var(--bx-shadow);
    
  }
}


.return-calculator-results {
  padding: 50px 20px;
  border-radius: var(--bx-radius);
  box-shadow: var(--bx-shadow);
  @media (max-width: $mobile-large) {
    padding: 0;
  }
  .disclaimer {
    padding: 25px;
  }
}

.return-calculator-returns-info__spaces {
  padding-top: 55px;
  @media (max-width: $mobile-large) {
    padding-top: 15px;
  }
}
.return-calculator-returns-info__estimated-return {
  margin-top: 20px;
  border-top: 1px solid $grey-200;
  font-size: 20px;
  text-align: center;
  padding: 10px 0;
  @media (max-width: $mobile-large) {
    font-size: 18px;
    margin-top: 0;
  }
  .value {
    font-size: 50px;
    font-weight: 300;
    color: $orange;
    line-height: 56px;
    @media (max-width: $mobile-large) {
      font-size: 35px;
    }
  }
  .up-down {
    font-size: 20px;
  }
}
.return-calculator-returns-info__initial-investment {
  border-top: 2px solid $grey-200;
  padding: 10px 0;
  font-weight: 500;
  .value-right {
    float: right;
  }
  @media (max-width: $mobile-galaxy-s5) {
    font-size: 12px;
  }
}
.return-calculator-returns-info__details {
  border-top: 2px solid $grey-200;
  padding: 10px 0;
  .value-right {
    float: right;
  }
  &>div {
    padding: 4px 0;
  }
  @media (max-width: $mobile-galaxy-s5) {
    font-size: 12px;
  }
}
.return-calculator-returns-info__account-value-at-exit {
  border-top: 2px solid $grey-200;
  padding: 10px 0;
  font-weight: 500;
  .value-right {
    float: right;
  }
  @media (max-width: $mobile-galaxy-s5) {
    font-size: 10px;
  }
}

.return-calculator-returns-info__negative-account-value-disclosures {
  padding: 10px 0;
}

.return-calculator-returns-info__total-fees {
  border-top: 2px $grey-200;
  padding: 10px 0;
  .value-right {
    float: right;
  }
  @media (max-width: $mobile-galaxy-s5) {
    font-size: 10px;
  }
}

.return-calculator-results-overview {
  display: flex;
  background-color: $white;
  color: $dark-blue;
  padding: 0 0 20px 0;
  @media(max-width: $mobile-large) {
    flex-direction: column;
    padding: 10px 0;
  }
}

.return-calculator-results-overview__left {
  width: 40%;
  border-right: 1px solid $grey-200;
  padding: 0px 20px;
  font-size: 12px;

  .value-right {
    float: right;
    color: $dark-gray;
  }
  &>div {
    padding: 4px 0;
  }
  @media(max-width: $mobile-large) {
    width: 100%;
    border-right: 0px;
    border-bottom: 1px solid $grey-200;
  }
}

.return-calculator-results-overview__capital-return-rate, .return-calculator-results-overview__investment-amount, .return-calculator-results-overview__investment-period {
  display: flex;
}

.return-calculator-results-overview__main-title {
  text-decoration: underline;
}

.return-calculator-results-overview__title {
  width: 80%;
  @media(max-width: $mobile-large) {
    width: 75%;
  }
}

.return-calculator-results-overview__value {
  width: 20%;
  @media(max-width: $mobile-large) {
    width: 25%;
  }
  text-align: right;
  color: $dark-gray;
}

.return-calculator-results-overview__estimated-return {
  font-size: 18px;
  color: $blue;
}
.return-calculator-results-overview__estimated-return-value {
  font-size: 30px;
  color: $dark-gray;
}

.return-calculator-results-overview__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  @media(max-width: $mobile-large) {
    width: 100%;
  }
}

.return-calculator-results-overview__returns-estimation {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.return-calculator-results-overview .return-calculator-results-overview__returns-estimation {
  width: 58%;
  padding: 0 20px;

  @media(max-width: $mobile-large) {
    width: 100%;
    padding: 20px;
  }
}

.return-calculator-results-overview__estimated-return-rates {
  display: inline-flex;
  width: 100%;
  @media(max-width: $mobile-large) {
    font-size: 12px;
  }
}

.return-calculator-results-overview__estimated-return-rate {
  width: 45%;
  color: $white;
}
.return-calculator-results-overview__estimated-return-rate--positive {
  background-color: $green;
}
.return-calculator-results-overview__estimated-return-rate--negative {
  background-color: $red;
}

.return-calculator-results-overview__estimated-return-annualised-rate {
  width: 55%;
}
.return-calculator-results-overview__estimated-return-annualised-rate--positive {
  color: $green;
}
.return-calculator-results-overview__estimated-return-annualised-rate--negative {
  color: $red;
}

.return-calculator-results-details {
  display: flex;
  @media(max-width: $mobile-large) {
    flex-direction: column-reverse;
  }
}

.return-calculator-results-details__info {
  width: 50%;
  padding: 0 20px;
  @media(max-width: $mobile-large) {
    width: 100%;
  }
}

.return-calculator-results-details__graph {
  width: 50%;
  padding: 0 20px;
  @media(max-width: $mobile-large) {
    width: 100%;
  }
}

.return-calculator__graph {
  margin-bottom: 15px;
}

.return-calculator-results-details__graph,
.return-calculator__graph {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.return-calculator__highchart {
  flex-grow: 1;
}

.return-calculator-results__disclaimer {
  padding: 0 20px;

  @media(max-width: $mobile-large) {
    padding-bottom: 20px;
  }
}
