


.panel-orders {
  border: 0;
  color: $dark-blue;
  .panel-heading {
    border: 0;
    padding: 0;
    .panel-title {
      background-color: $charcoal;
      color: $white;
      font-size: 15px;
      font-weight: 500;
      padding: 12px 15px;
      .icn {
        margin-right: 7px;
      }
    }
  }

  .pending-sell-orders-table, .pending-pre-orders-table {
    border: 1px solid #DDD;

    thead {
      background-color: $very-light-blue;
    }
    th {
      text-align: center;
      vertical-align: middle !important;
      &:first-child {
        width: 20%;
      }
    }
    td {
      text-align: right;
      vertical-align: middle;
    }
    td,th {
      font-size: 12px;
    }
    tfoot {
      td {
        font-size: 16px;
        font-weight: 700;
        &:first-child {
          padding: 12px 20px !important;
        }
      }
    }
    a {
      color: $dark-blue;
      text-decoration: underline;
    }
  }

  .button {
    border-color: $dark-blue;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 6px;
  }
}
