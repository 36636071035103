


.general-info-item {
  color: $dark-blue;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  img {
    display: block;
    height: 20px;
    margin: 0 auto;
  }
  .blue-text {
    color: $blue;
  }
}
