

.notifications {
  background:$white;
  position:absolute;
  right: 0;
  z-index:6;
  box-shadow:$standard-shadow;
  border:$standard-border;
  border-radius:$standard-radius;
  border-top:none;
  width:300px;
  .header {
    padding:5px 10px;
    background:#f7f7f7;
    border-bottom:solid 1px #e8e8e8;
    font-weight: 500;;
  }
  .body {
    font-weight: 400;
    font-size:13px;
    padding: 5px;
    ul {
      padding: 0;
      li {
        padding: 5px;
        list-style-type: none;
        line-height: 26px;
        a {
          opacity: 0.9;
          &:hover {
            background:transparent;
            opacity: 1;
          }
        }
        span {
          line-height: 20px;
        }
        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }
}
.notification-icon {
  a {
    line-height:50px;
    color: $white;
    display:inline-block;
    .fa {
      font-size:16px;  
    }
    .badge {
      position: absolute;
      right: 5px;
      bottom: 5px;
      background:#a64500;
      font-weight: 500;;
      font-size:11px;
      padding:3px 6px;
    }
    &:hover {
      background-color: $orange-hover;
    }
    &.active {
      background-color: $orange-active;
    }
  }
}
