@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.moreOnSiContainer {
  padding: ($measurement--grid-unit * 15) $measurement--side-gutter;
}

.innerContainer {
  margin: 0 auto;
  max-width: 960px;
  text-align: center;
}

.icon {
  width: 50px;
}

.linkListing {
  display: flex;
  flex-flow: column nowrap;
}

.externalLinkButton {
  margin-bottom: $measurement--grid-unit;
  width: 100%;

  &:after {
    content: "arrow_forward";
  }
}

@media (min-width: $breakpoint--small) {
  .moreOnSiContainer {
    padding-bottom: ($measurement--grid-unit * 25);
    padding-top: ($measurement--grid-unit * 27);
  }

  .linkListing {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .externalLinkButton {
    flex-basis: calc(33.33% - #{$measurement--side-gutter});
    margin-bottom: $measurement--side-gutter;
    margin-right: $measurement--side-gutter;
  }
}
