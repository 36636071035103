.account-withdraw {
  h1 {
    @media (max-width: $mobile-large) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 30px;
    }
    @media (max-width: $mobile-medium) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 30px;
    }
    @media (max-width: $mobile-small) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
  .withdraw-note {
    margin: 10px 0;
    padding: 25px 30px;
    background: #fff;
    .fa {
      margin-right: 4px;
      color: #d00;
    }
  }
  .panel-withdraw {
    padding: 30px 30px 50px;
    @media (max-width: $mobile-large) {
      padding: 20px 16px 20px;
    }
    background-color: #fff;
    .funds-available {
      padding: 20px;
      background-color: var(--bx-color---primary);
      color: #fff;
      border-radius: var(--bx-radius);
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $mobile-large) {
        padding: 20px;
        flex-direction: column;
      }
      @media (max-width: $mobile-medium) {
        padding: 10px;
      }
      @media (max-width: $mobile-small) {
        padding: 10px;
        margin-bottom: 0px;
      }

      .title {
        font-weight: 500;

        @media (max-width: $mobile-large) {
          font-size: 15px !important;
        }

        @media (max-width: $mobile-medium) {
          font-size: 15px !important;
        }

        @media (max-width: $mobile-small) {
          font-size: 15px !important;
        }
      }
      .amount {
        font-size: 45px;

        @media (max-width: $mobile-large) {
          font-size: 35px !important;
        }
        @media (max-width: $mobile-medium) {
          font-size: 35px !important;
        }
        @media (max-width: $mobile-small) {
          font-size: 28px !important;
        }
      }
    }

    .button {
      width: 100%;
      padding: 14px;
      text-align: center;
      font-size: 18px;
      margin-top: 15px;

      &::after {
        font-size: 26px;
        margin-left: 8px;
      }

      @media (max-width: $mobile-large) {
        margin-top: 0px;
      }
    }

    .input {
      .title {
        color: #888;
        width: 160px;
        display: inline-block;
      }
    }

    .input,
    input {
      background-color: #eee;
      display: block;
      padding: 15px 20px;
      font-size: 18px;
      margin-top: 14px;
      border-radius: var(--bx-radius);

      @media (max-width: $mobile-large) {
        padding: 10px 20px;
      }

      .value {
        font-weight: 800;
        font-family: "Courier New", Courier, monospace;
      }
    }

    .form-group {
      position: relative;

      &::before {
        content: "$";
        position: absolute;
        top: 21px;
        font-size: 16px;
        font-weight: 500;
        left: 20px;
        color: #333;
      }
    }

    input.light-blue-input {
      background-color: #eee;
      border: none;
      padding-left: 40px;
      font-size: 30px;
      margin-top: 30px;
    }

    input.light-blue-input:hover {
      background-color: #ddd;
    }

    .error {
      margin-top: 3px;
      margin-bottom: 10px;
      display: inline-block;
      color: #d00;
      font-weight: 500;
      font-size: 19px;

      @media (max-width: $medium-large) {
        font-size: 15px;
      }
      @media (max-width: $small) {
        font-size: 16px;
      }
      @media (max-width: $mobile-large) {
        font-size: 13px;
      }
      @media (max-width: $mobile-medium) {
        font-size: 12px;
      }
      @media (max-width: $mobile-galaxy-s5) {
        font-size: 11px;
      }
      @media (max-width: $mobile-small) {
        font-size: 10px;
      }
    }
  }
}

.withdraw-success {
  background-color: white;
  padding-bottom: 70px;
  h1 {
    @media (max-width: $mobile-large) {
      font-size: 19px;
      font-weight: bold;
    }
    @media (max-width: $mobile-medium) {
      font-size: 17px;
    }
    @media (max-width: $mobile-small) {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  h3 {
    @media (max-width: $mobile-large) {
      font-size: 20px;
    }
    @media (max-width: $mobile-medium) {
      font-size: 18px;
    }
    @media (max-width: $mobile-small) {
      font-size: 15px;
    }
  }
  .info-copy {
    @media (max-width: $mobile-large) {
      font-size: 16px;
    }
    @media (max-width: $mobile-medium) {
      font-size: 15px;
    }
    @media (max-width: $mobile-small) {
      font-size: 16px;
    }
  }
  .white-box {
    background-color: #fff;
    padding: 30px 15px;
    padding-bottom: 50px;
    @media (max-width: $mobile-large) {
      padding: 5px 10px;
      padding-bottom: 30px;
    }

    .title {
      margin-bottom: 30px;
      @media (max-width: $mobile-small) {
        margin-bottom: 10px;
      }
      color: $dark-blue;
      .amount {
        color: $salmon;
      }
    }
    img {
      max-width: 200px;
      display: block;
      margin: auto;
      @media (max-width: $medium) {
        width: 100%;
      }
    }
    .right-arrow-button::after {
      font-size: 24px !important;
    }
    .button {
      margin-top: 40px;
      margin-bottom: 50px;
      font-size: 17px;
      padding: 12px 85px;
      @media (max-width: $mobile-large) {
        padding: 12px 10px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 25px;
      }
      @media (max-width: $mobile-small) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .settings-blue-notification {
      font-size: 16px;
      .icn {
        padding: 25px 29px;
        line-height: 60px;

        @media (max-width: $mobile-large) {
          padding: 13px 17px;
          line-height: 40px;
        }
      }
    }
  }
}
