@charset "UTF-8";
.BulletList {
  list-style: none;
  margin-left: 0;
  padding: 8px 0 0;
}

.BulletListItem {
  line-height: 1.4;
  position: relative;
}

.BulletListItem:before {
  color: #ff8282;
  content: "⚬";
  left: 0;
  position: absolute;
}

.TickedBulletListItem:before {
  content: "tick";
  font-family: "brickicons";
}

.oneColumn .BulletListItem {
  margin: 0 0 12px 12px;
  padding: 0 0 0 32px;
  width: 100%;
}

.oneColumn .BulletListItem-noLeftMargin {
  margin-left: 0;
}

.twoColumns {
  display: flex;
  flex-flow: row wrap;
}

.twoColumns .BulletListItem {
  margin-left: 0;
  width: 50%;
}

.threeColumns {
  display: flex;
  flex-flow: row wrap;
}

.threeColumns .BulletListItem {
  margin-left: 0;
  width: 33%;
}

@media (min-width: 768px) {
  .oneColumn--breakpoint-small .BulletListItem {
    margin: 0 0 12px 12px;
    padding: 0 0 0 32px;
    width: 100%;
  }
  .oneColumn--breakpoint-small .BulletListItem-noLeftMargin {
    margin-left: 0;
  }
  .twoColumns--breakpoint-small {
    display: flex;
    flex-flow: row wrap;
  }
  .twoColumns--breakpoint-small .BulletListItem {
    margin-left: 0;
    width: 50%;
  }
  .threeColumns--breakpoint-small {
    display: flex;
    flex-flow: row wrap;
  }
  .threeColumns--breakpoint-small .BulletListItem {
    margin-left: 0;
    width: 33%;
  }
}

@media (min-width: 992px) {
  .oneColumn--breakpoint-medium .BulletListItem {
    margin: 0 0 12px 12px;
    padding: 0 0 0 32px;
    width: 100%;
  }
  .oneColumn--breakpoint-medium .BulletListItem-noLeftMargin {
    margin-left: 0;
  }
  .twoColumns--breakpoint-medium {
    display: flex;
    flex-flow: row wrap;
  }
  .twoColumns--breakpoint-medium .BulletListItem {
    margin-left: 0;
    width: 50%;
  }
  .threeColumns--breakpoint-medium {
    display: flex;
    flex-flow: row wrap;
  }
  .threeColumns--breakpoint-medium .BulletListItem {
    margin-left: 0;
    width: 33%;
  }
}

@media (min-width: 1100px) {
  .oneColumn--breakpoint-large .BulletListItem {
    margin: 0 0 12px 12px;
    padding: 0 0 0 32px;
    width: 100%;
  }
  .oneColumn--breakpoint-large .BulletListItem-noLeftMargin {
    margin-left: 0;
  }
  .twoColumns--breakpoint-large {
    display: flex;
    flex-flow: row wrap;
  }
  .twoColumns--breakpoint-large .BulletListItem {
    margin-left: 0;
    width: 50%;
  }
  .threeColumns--breakpoint-large {
    display: flex;
    flex-flow: row wrap;
  }
  .threeColumns--breakpoint-large .BulletListItem {
    margin-left: 0;
    width: 33%;
  }
}
