@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/colors';

.investGrowWithdrawContainer {
  padding: ($measurement--grid-unit * 10) ($measurement--side-gutter) ($measurement--grid-unit * 15);
  max-width: 960px;
  margin: 0 auto;
}

.tabPanel {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: ($measurement--grid-unit * 10);
}

.tabPanel--contentContainer {

}

.tabPanel--image {
  max-width: 300px;
  width: 80%;
  margin: ($measurement--grid-unit * 12) auto ($measurement--grid-unit * 10);
}

.tabPanel--learnMoreLink {
  display: inline-block;
  width: 100%;
  margin-left: $measurement--grid-unit * 11;
}

@media (min-width: $breakpoint--small) {
  .investGrowWithdrawContainer {
    padding-top: ($measurement--grid-unit * 25);
    padding-bottom: ($measurement--grid-unit * 28);
  }

  .tabPanel {
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    padding-top: ($measurement--grid-unit * 15);
  }

  .tabPanel--image {
    margin: 0 auto;
    flex-basis: 40%;
  }

  .tabPanel--contentContainer {
    flex-basis: 50%;
  }

  .tabPanel--learnMoreLink {
    width: auto;
  }
}
