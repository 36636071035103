.box-financials {
    .gray-box {
        background-color: $light-gray;
        padding: 24px 10px;
        text-align: center;
        border-radius: var(--bx-radius);
        @media (max-width: $medium) {
            margin-bottom: 15px;
            padding: 25px 5px;
        }
    }
    .box-financials-title {
        text-transform: uppercase;
        .icn {
            margin-right: 8px;
            padding: 14px;
        }
    }
    .box-financials-value {
        color: $orange;
        font-size: 58px;
        font-weight: 300;
        line-height: 70px;
        @media (max-width: $medium-large) {
            font-size: 50px;
            line-height: 55px;
        }
    }
    .box-financials-description {
        .fa {
            color: #aaa;
            margin-right: 5px;
        }
    }
}

.financials-box-small {
    .gray-box {
        padding: 20px 10px;
        .box-financials-value {
            font-size: 36px;
            line-height: 34px;
            margin-top: 7px;
        }
    }
}

.financials-box-subtitle {
    font-size: 20px;
    font-weight: 700;
}

.financials-inner-row {
    margin-bottom: 10px;
}
