@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.menuItemContainer {
  border-bottom: 1px solid #eeeeee;
}

.menuItemContent {
  padding-bottom: 16px;
  padding-top: 16px;
  border-radius: 0;
}

.menuItemDropDownRoot {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.menuItemTopLevelIndent {
  padding-left: 24px;
  padding-right: 24px;
}

.menuItemSecondLevelIndent {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 0;
}

.promoButtonContainer {
  padding: 24px;
}

.menuItemDropDownText_open {
  color: #ff8282;
}

.dropdown_icon {
  color: #b3b3b8;
  margin-left: 4px;
  transition: 250ms ease-in-out;
  transition-property: color, transform;
}

.dropdown_icon_open {
  color: #ff8282;
  transform: rotate(180deg);
}

.hoverTrigger:hover .hoverTargetColor, .hoverTrigger:focus .hoverTargetColor, .hoverTrigger:active .hoverTargetColor {
  color: #ff8282;
}

.hoverTrigger:hover.hoverTargetColor, .hoverTrigger:focus.hoverTargetColor, .hoverTrigger:active.hoverTargetColor {
  color: #ff8282;
}

.hoverTrigger:hover .hoverTargetBackgroundColor, .hoverTrigger:focus .hoverTargetBackgroundColor, .hoverTrigger:active .hoverTargetBackgroundColor {
  background-color: #ffeef1;
}

.hoverTrigger:hover.hoverTargetBackgroundColor, .hoverTrigger:focus.hoverTargetBackgroundColor, .hoverTrigger:active.hoverTargetBackgroundColor {
  background-color: #ffeef1;
}

@media (min-width: 1100px) {
  .subMenu {
    position: absolute;
    top: 55px;
    z-index: 900;
  }
  .menuItemContent {
    padding: 8px;
    border-radius: 7px;
  }
  .subMenuInner {
    background: white;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    overflow: hidden;
  }
  .menuItemTopLevelIndent {
    padding-left: 12px;
    padding-right: 12px;
  }
  .menuItemSecondLevelIndent {
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0;
  }
  .menuItemContainer {
    border-bottom: none;
  }
  .makeMenuItemSameHeightAsButton {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
  }
  .promoButtonContainer {
    padding: 0;
  }
}
