@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

$two-ways-max-width: 1000px;

.coloredAngularBackground {
  padding: ($measurement--grid-unit * 18) $measurement--side-gutter ($measurement--grid-unit * 5);
  position: relative;
  overflow: hidden;
  text-align: center;

  // diagonal background color:
  &::before {
    content: "";
    width: 125%;
    height: 400px;
    position: absolute;
    top: 0; left: 0;
    transform: rotate(-12deg);
    transform-origin: 0% 100%;
  }
}

.coloredAngularBackground-coral-background::before {
  background: $coral;
}

.coloredAngularBackground-blue-background::before {
  background: $blue-50
}

.coloredAngularBackground-pink-50-background::before {
  background: $pink-50;
}

.coloredAngularBackground--innerContainer {
  display: flex;
  flex-flow: column nowrap;
  max-width: $measurement--wide-page-container-max-width;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.coloredAngularBackground--subHeading {
  color: $color--two-ways-text;
  width: 50%;
  margin: ($measurement--grid-unit * 3) auto ($measurement--grid-unit * 10);
}

@media (min-width: $breakpoint--small) {
  .coloredAngularBackground {
    padding-top: $measurement--grid-unit * 24;

    &:before {
      height: 660px;
    }
  }

  .coloredAngularBackground--subHeading {
    max-width: 480px;
    margin: ($measurement--grid-unit * 6) auto ($measurement--grid-unit * 12);
  }
}
