


.lowest-available-price-box {
  background-color: $light-orange;
  border: 1px solid #CCCCCD;
  text-align: center;
  color: $dark-blue;
  font-size: 15px;
  padding: 12px 4px;
  font-weight: 500;
  @media (max-width: $small) {
    font-size: 13px;
  }
  img {
    width: 28px;
    margin-right: 6px;
  }
  .value {
    font-size: 23px;
    line-height: 1;
    margin-top: 6px;
    font-weight: 400;
    font-weight: 400;
    @media (max-width: $mobile-small) {
      margin-top: 0;
      font-size: 25px;
    }
  }
  .larger-text {
    font-size: 39px;
  }
  .no-bricks {
    font-size: 15px;
  }

  .brick-value:hover {
    text-decoration: none;
  }
}

.lowest-available-price-box__light-blue-bg {
  background-color: $very-light-blue;
}


.no-bricks-currently-available-box, .lowest-available-price-box {

  @media (max-width: $small) {
    margin-top: 0;
    border: none;
    padding: 0;

    .icn {
      display: block;
      float: left;
      margin-bottom: 20px;
      top: 2px;
    }
  }
}
