@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.heading {
  margin-bottom: 64px;
}

.card {
  align-items: center;
  border: 1px solid #d7d7dc;
  border-radius: var(--bx-ms---standard-border-radius);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  text-align: center;
}

.smartInvestCard {
  padding-top: 64px;
}

.badge {
  background-color: #ff8282;
  color: white;
  left: 50%;
  line-height: 40px;
  margin-left: -74px;
  position: absolute;
  top: 0;
  width: 148px;
}

.graphic {
  margin-bottom: 24px;
}

.notSureYet--container {
  position: relative;
}

.notSureYet--button {
  border: 1px solid #d7d7dc;
  border-radius: var(--bx-ms---standard-border-radius);
  color: inherit;
  display: block;
  padding: 14.4px 24px;
  text-align: center;
  text-decoration: none;
}

.notSureYet--button-menuOpen {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.notSureYet--tellUsMoreContainer {
  background: white;
  padding-top: 28px;
  position: relative;
}

.notSureYet--answerItem {
  margin-bottom: 8px;
  text-align: left;
  white-space: normal;
}

.notSureYet--customTextAnswer {
  padding-top: 8px;
}

.notSureYet--submitButton {
  margin-top: 8px;
  opacity: 0;
  transition: 250ms ease-in-out;
  transition-property: opacity;
}

.notSureYet--submitButton-active {
  opacity: 1;
}

@media (min-width: 768px) {
  .card {
    flex-direction: row;
    padding: 32px;
  }
  .smartInvestCard {
    padding-top: 72px;
  }
  .graphic {
    margin-bottom: 0;
  }
  .cardContent {
    margin-left: 32px;
    text-align: left;
  }
}
