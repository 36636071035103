@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';


// BrickX statistics bar
// --------------------------------------------------------------------------------
.brickXStatisticsBar {
  background: $color--statistics-row-background;
  padding: ($measurement--grid-unit * 10) ($measurement--side-gutter * 2);
}

.brickXStatisticsBarInner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: $measurement--thin-page-container-max-width;
}

.statistic {
  flex-basis: 40%;
}

.statisticValue {
  color: $white;
  font-weight: 400;
}

.statisticName {
  color: $color--statistics-row-label;
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .brickXStatisticsBar {
    justify-content: center;
  }
  .statistic {
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-flow: row nowrap;

    &:first-child {
      margin-right: $measurement--grid-unit * 30;
    }
  }
  .statisticName {
    margin-left: $measurement--grid-unit * 4;
  }
}
