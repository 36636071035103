.deposit-success {
  background-color: white;
  padding-bottom: 70px;
}

.deposit-success__settings-blue-notification {
  margin-top: 18px;
  margin-bottom: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  .col-info-description {
    @media (min-width: $medium) {
      width: 85%;
    }
  }
}

.deposit-success__title {
  text-align: center;
  margin-top: 10px;
  margin-top: 10px;
  font-size: 40px;
  // font-family: "GothamRounded-Light";
  font-weight: 300;
  color: $blue-900;
}

.deposit-success__sub-title {
  text-align: center;
}

.deposit-success-body {
  background-color: #fff;
  padding: 30px 15px;
  padding-bottom: 50px;
  margin-top: 15px;
}

.deposit-success-bpay__title {
  font-size: 30px;
  @media (min-width: $small) {
    font-size: 40px;
  }
}

.deposit-success-bpay__sub-title {
  margin-bottom: 10px;
  @media (min-width: $small) {
    margin-bottom: 20px;
  }
}

.deposit-success-bpay-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 0;

  @media (min-width: $small) {
    flex-direction: row;
  }
}

.deposit-success-bpay-block-wrap {
  flex: 1;
  padding: 5px;
  @media (min-width: $small) {
    padding: 5px 10px;
  }
}
.deposit-success-bpay-block {
  color: $charcoal;
  padding: 10px;
  @media (min-width: $small) {
    margin-top: 15px;
    padding: 25px;
  }
}

.deposit-success-bpay-block__details {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 5px 0;

  @media (min-width: $small) {
    display: inherit;
    padding: 5px;
  }
}

.deposit-success-bpay-block__separator {
  margin: 8px 0;
}

.deposit-success-bpay-logo__image {
  height: auto;
  width: 33px;
  margin-right: 5px;

  @media (min-width: $small) {
    height: 150px;
    float: left;
    width: auto;
    margin-right: 10px;
  }
}

.deposit-success-bpay-block__info-text {
  font-size: 17px;
  @media (min-width: $small) {
    font-size: 22px;
  }
}
.deposit-success-bpay-block__description-contact {
  font-size: 12px;
  margin-top: 4px;
}

.deposit-success-bpay-other-details {
  flex: 1;
  padding: 5px 10px;
}

.deposit-success-bpay-estimated-arrival {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  font-size: 15px;
  margin-top: 15px;

  padding: 5px 0px;
  @media (min-width: $small) {
    padding: 8px 5px;
  }
}

.deposit-success-bpay-estimated-arrival__image {
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.deposit-success-bpay-estimated-arrival__date {
  text-transform: uppercase;
}

.deposit-success-bpay-learn-more-link {
  cursor: pointer;
  font-weight: 500;
  color: $very-dark-blue;
  margin-top: 10px;
  margin-left: 8px;
  display: inline-block;
}

.deposit-success-bpay-learn-more-link__arrow {
  color: $orange;
  font-size: 18px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.deposit-success-bpay-good-points {
  padding: 10px 18px;
  margin: 0;
}

.deposit-success-bpay-bank-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
  @media (min-width: $small) {
    padding: 10px;
  }
}

.deposit-success-bpay-bank-info__navigate-title {
  margin-bottom: 15px;
  font-size: 35px;
}

.deposit-success-bpay-bank-info__navigate-sub-title {
  margin-bottom: 30px;
  font-size: 16px;
}

.deposit-success-bpay-bank-info__banks {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: $ipad) {
    flex-direction: row;
    justify-content: center;
  }
}

.deposit-success-bpay-bank-info__bank-logo {
  display: inline-block;
  height: 60px;
  margin: 0 20px;
}

.deposit-success-bpay-notes {
  padding-top: 10px;
}

.deposit-success-bpay-notes__message {
  font-size: 16px;
  padding-bottom: 20px;
}

.deposit-success-bpay-notes__funds-available-time {
  font-size: 16px;
}

.deposit-success-poli__title {
  font-size: 30px;
  @media (min-width: $small) {
    font-size: 40px;
  }
}

.deposit-success-poli-info {
  background-color: #fff;
  padding: 30px 15px;
  padding-bottom: 50px;
  margin-top: 15px;
}

.deposit-success-poli-info-greeting {
  margin-bottom: 30px;
  color: #1e4861;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}

.deposit-success-poli-info-greeting__amount {
  color: $blue;
}

.deposit-success-poli-info-image {
  width: 98%;
  height: auto;
  margin-left: 1%;
  margin-right: 1%;
}

.deposit-success-poli-info-link {
  text-align: center;
}

.deposit-success-poli-info-link__button {
  margin-top: 40px;
  margin-bottom: 50px;
  font-size: 17px;
  padding: 12px 10px;
  width: 100%;
  @media (min-width: $mobile-large) {
    padding: 12px 85px;
    text-align: center;
    width: 30%;
  }
  @media (min-width: $small) {
    padding: 12px 85px;
    text-align: center;
    width: 50%;
  }
}
