@import 'src/styles/measurements';
@import 'src/styles/breakpoints';

.referralLoginDriver {
  margin-bottom: $measurement--grid-unit * 12;
}

@media (min-width: $breakpoint--medium) {
  .twoPanelsContainer {
    display: flex;
    justify-content: center;
  }

  .panelReferralDriver {
    margin-left: $measurement--grid-unit * 20;
    order: 2;
  }

  .panelContent {
    margin-right: $measurement--grid-unit * 20;
    order: 1;
  }

  .panelReferralDriver,
  .panelContent {
    flex: 1 0 0px;
  }

  .twoPanelsContainer--referralDriverHidden {
    display: block;

    .panelReferralDriver,
    .panelContent {
      margin: 0;
    }
  }
}
