@keyframes moveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes moveDownAndFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes pulsing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes eggTimer {
  0% {
    transform: rotate(180deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wipeDownwards {
  0% {
    height: 100%;
  }
  40% {
    height: 0%;
  }
  60% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scaleBulge {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes bobUpAndDown {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}

.heroMenuDropDownContainer {
  padding: 8px 24px 24px 24px;
}

.heroMenuItemContainer {
  border: 1px solid #eeeeee;
  display: block;
  margin-bottom: 16px;
  padding: 16px;
}

.heroMenuItemContainer:last-child {
  margin-bottom: 0;
}

.smartInvestMenuItemContainer {
  display: flex;
  justify-content: space-between;
}

.smartInvestmenuItemTitleContainer {
  margin-right: 16px;
}

.buttonsContainer {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.button {
  margin: 0 0 8px 0;
  width: 100%;
}

.buildMyOwnMenuItemCta {
  color: #6eb4f0;
}

.menuItemLogin {
  text-align: center;
  color: #ff8282;
  border: 1px solid #ff8282;
  border-radius: 5px;
  margin-bottom: 8px;
}

.menuItemSignup {
  text-align: center;
  border: 1px solid #5e5e5e;
  border-radius: 5px;
  margin-bottom: 8px;
}

@media (min-width: 1100px) {
  .heroMenuDropDownContainer {
    padding: 0;
  }
  .heroMenuItemContainer {
    border: none;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .buttonsContainer {
    display: flex;
    border-left: 1px solid #e7e7ec;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 8px;
    padding-left: 8px;
    padding-right: 0;
  }
  .button {
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 5px;
    font-weight: 500;
    margin: 0;
    width: auto;
  }
  .menuItemLogin {
    color: #41414b;
    border: none;
    margin: 0;
    margin-right: 5px;
  }
  .menuItemSignup {
    background-color: #ff8282;
    border: none;
    margin: 0;
    margin-right: 5px;
  }
}
