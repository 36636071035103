#properties {
    #video-link-how-to-reasearch-properties {
        margin-bottom: 15px;
        text-align: right;
    }

    h1 {
        position: relative;
    }

    .filters-sorters-controls {
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 10px;
        position: relative;

        @media (max-width: $small) {
            background-color: #ddd;
            margin-bottom: 20px;
        }

        .edit-icn {
            color: $blue;
            font-size: 11px;
            position: absolute;
            right: 15px;
            text-align: center;
            top: 7px;
            img {
                display: block;
                height: 25px;
            }
        }
        .mobile-filters-sort-box {
            @media (max-width: $small) {
                padding: 10px 0;
            }
            .grey-title {
                color: #777;
                display: inline-block;
                font-size: 13px;
                margin-right: 4px;

                @media (max-width: $small) {
                    width: 70px;
                }
            }
        }

        .viewing-properties {
            border-right: 1px solid $very-dark-blue;
            color: $very-dark-blue;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            @media (max-width: $small) {
                border: none;
                color: $blue;
                line-height: 1;
                padding: 18px 15px;
                padding-bottom: 8px;
                text-align: left;
            }
        }

        .drop-down-group-box {
            text-align: center;
            @media (max-width: $small) {
                text-align: left;
            }
            .drop-down-group {
                color: #999;
                display: inline-block;
                font-weight: 500;
                position: relative;
                @media (max-width: $small) {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                }
                select {
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 1px;
                    color: #000;
                    cursor: pointer;
                    height: auto;
                    margin-left: 10px;
                    padding: 10px 15px;
                    padding-right: 30px;
                    @media (max-width: $small) {
                        width: 75%;
                    }
                    &.drop-down-sort-by {
                        font-size: 11.5px;
                    }
                }

                .fa-angle-down {
                    font-size: 17px;
                    position: absolute;
                    right: 10px;
                    top: 9px;
                }
            }
        }
    }

    .mini-footer {
        background-color: #fff;
        padding: 30px 0;
        .col {
            float: left;
            width: 20%;
            @media (max-width: $mobile-large) {
                height: 105px;
                margin-bottom: 15px;
                width: 33%;
            }
        }

        .good-points {
            border-bottom: 1px solid #ddd;
            margin-bottom: 40px;
            padding-bottom: 40px;
            text-align: center;
            img {
                display: block;
                margin: auto;
                margin-bottom: 5px;
                width: 70px;
            }
        }

        .more-about-brickx-properties {
            text-align: center;
            @media (max-width: $medium) {
                box {
                    height: auto;
                    margin-top: 40px;
                    img {
                        width: 50%;
                    }
                    buttonBox {
                        position: static;
                    }
                }
            }
            h1 {
                margin-top: 20px;
            }
            box {
                display: block;
                height: 360px;
                position: relative;
                img {
                    width: 100%;
                }
                title {
                    color: $orange;
                    display: block;
                    font-size: 18px;
                    margin-bottom: 5px;
                }
                buttonBox {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    .button {
                        display: block;
                        margin: auto;
                        margin-top: 15px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

/*********************
    List Page
*********************/
.properties-list-page {
    padding: 20px 0;
    background: var(--bx-color---background);
    h1 {
        color: $dark-blue;
        font-size: 34px;
        font-weight: 300;
        font-weight: normal;
        margin-bottom: 10px;
        margin-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        @media (max-width: $small) {
            margin-bottom: 0px;
            padding-bottom: 15px;
        }
    }
}
.property-disclaimer {
    border: solid 1px #ddd;
    color: $off-gray;
    font-size: 12px;
    font-weight: 400;
    margin: 20px 0;
    padding: 12px;
    text-align: center;
    @media (max-width: $small) {
        margin-top: 20px;
    }
}

.properties-index__property-card-container {
    margin-bottom: 30px;
    @media (min-width: $mobile-landscape--up) {
    }
}

.properties-list-page-filter-and-sort {
    display: grid;
    margin-bottom: 5px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 24px;
}

.properties-list-page-filter-and-sort__container {
    flex: 1;
    padding: 10px;
}

.properties-list-page-filter-and-sort__label {
    margin-bottom: 5px;
}

.properties-list-page-filter-and-sort__select {
    width: 100%;
}

.properties-list-page-filter-and-sort__label,
.properties-list-page-filter-and-sort__select {
    font-size: 0.7em;
}

@media (min-width: $extra-small--up) {
    .properties-list-page-filter-and-sort__container {
        align-items: center;
        display: flex;
    }

    .properties-list-page-filter-and-sort__label {
        flex: 1;
        margin-bottom: 0;
    }

    .properties-list-page-filter-and-sort__select {
        flex: 4;
    }

    .properties-list-page-filter-and-sort__label,
    .properties-list-page-filter-and-sort__select {
        font-size: 0.8em;
    }
}

@media (min-width: $mobile-landscape--up) {
    .properties-list-page-filter-and-sort {
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 10px;
    }

    .properties-list-page-filter-and-sort__container {
        padding: 15px;
    }

    .properties-list-page-filter-and-sort__label,
    .properties-list-page-filter-and-sort__select {
        font-size: 0.9em;
    }
}

@media (min-width: $small--up) {
    .properties-list-page-filter-and-sort__label,
    .properties-list-page-filter-and-sort__select {
        font-size: 1em;
    }
}

/*********************
    Show Page
*********************/

.property-overview {
    background: var(--bx-color---background);
    padding-top: 20px;
    @media (max-width: $small) {
        padding-top: 20px;
    }
    @media (max-width: $mobile-large) {
        padding-top: 0px;
    }
    .investment-case-panel {
        .description {
            background-color: #f5f5f5;
            padding: 15px 20px;
        }
    }
    .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .details-section {
        border-bottom: solid 1px #e8e8e8;
        font-weight: 400;
        margin-bottom: 40px;
        padding-bottom: 40px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
        h3 {
            border-bottom: solid 1px $off-black;
            color: $off-black;
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            font-weight: normal;
            margin-bottom: 40px;
            margin-top: 10px;
            padding-bottom: 10px;
        }
        .document {
            color: #000;
            font-size: 15px;
            font-weight: 500;
            line-height: 40px;
            @media (max-width: $small) {
                font-size: 13px;
                line-height: 26px;
            }
            .fa {
                color: $blue;
                margin-right: 10px;
            }
        }
        .inactive {
            color: #aaa;
            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
        .note {
            color: $off-gray;
            font-size: 12px;
            font-weight: 500;
            margin-top: 20px;
        }
        p {
            font-size: 13px;
            font-weight: 400;
            line-height: 26px;
            margin-top: 0;
            padding: 0;
            white-space: pre-line;
            strong {
                font-weight: normal;
                font-weight: 500;
            }
        }
        .no-news {
            color: #777;
        }
    }
    .suburb-statistics {
        padding-top: 0;
    }
    .no-distributions {
        text-align: center;
        @media (max-width: $large) {
            .icon {
                margin-top: 91px;
            }
        }
        @media (max-width: $small) {
            .icon {
                font-size: 60px;
                margin-top: 33px;
            }
            h5 {
                margin-bottom: 30px;
                margin-top: 20px;
            }
        }
        .icon {
            color: #ccc;
            display: block;
            font-size: 149px;
            margin-top: 73px;
        }
        h5 {
            color: #999;
            font-weight: 400;
            font-weight: normal;
            margin-bottom: 90px;
            text-align: center;
        }
    }
}

/*********************
    Orderbook Page
*********************/
$medium-screen: 991px;
$large-screen: 1200px;
.property-orderbook-tab {
    background: $white;
    padding: 40px 0;
    @media (max-width: $small) {
        padding-top: 0;
    }
    .account-note {
        background: transparent;
        border: solid 1px #c7690c;
        color: #c7690c;
        line-height: 54px;
        margin-bottom: 40px;
        padding: 0px 10px 0 20px;
        @media (max-width: $small) {
            font-size: 12px;
            line-height: 54px;
            margin-top: 20px;
            padding: 10px;
        }
    }
    .available-funds {
        @media (max-width: $small) {
            display: block !important;
            float: none !important;
            margin: -20px auto 20px;
        }
    }
    .no-orderbook {
        background: url("/static/images/tb3.jpg") no-repeat center;
        height: 580px;
        position: relative;
        @media (max-width: $large-screen) {
            background: url("/static/images/tb2.jpg") no-repeat center;
            height: 380px;
        }
        @media (max-width: $medium-screen) {
            background-size: cover;
        }
        @media (max-width: $small) {
            background: none;
            height: 250px;
        }
        .orderbook-cta {
            color: #aaa;
            font-size: 64px;
            left: 50%;
            margin-left: -220px;
            position: absolute;
            text-align: center;
            top: 40%;
            width: 440px;
            z-index: 3;
            @media (max-width: $small) {
                left: 50%;
                margin-left: -170px;
                padding: 20px;
                top: 40px;
                width: 340px;
            }
            p {
                color: $off-gray;
                font-size: 20px;
                font-weight: 400;
                margin-top: 20px;
                @media (max-width: $small) {
                    font-size: 23px;
                }
                a {
                    color: #c7690c;
                    font-weight: 500;
                }
            }
        }
    }
}
