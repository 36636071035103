@import '~src/styles/fonts';
@import '~src/styles/colors';
@import '~src/styles/measurements';
@import '~src/styles/breakpoints';
@import '~src/styles/z-index';

$image-height: 65vh;
$info-overlay-vert-padding: ($measurement--grid-unit * 4);

.featuredProperties {
  position: relative;
  min-height: $image-height;
  background: $color--featured-property-background;

  :global(.owl-carousel) {
    position: static;
  }
}

.propertyImage {
  display: block;
  width: 100%;
  height: $image-height;
  object-fit: cover;
}

.propertyInfoOverlay {
  display: flex;
  justify-content: space-between;
  padding: $info-overlay-vert-padding ($measurement--grid-unit * 6);
  padding-right: ($measurement--grid-unit * 4);
  color: white;
  background: $color--featured-property-overlay-background;
  overflow: hidden;
}

.propertyInfoColumn {
  flex: 1 1 auto;
}

.propertyInfoLabel {
  color: $color--featured-property-label-text;
}

.propertyAddress {
  color: $white;
  min-height: 2.1em; // this heading should always be atleast 2 lines of the current type size. + line-height
}

$dot-size: $measurement--grid-unit * 2;

.carouselDots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 0 0 auto;
  margin-left: $measurement--grid-unit * 4;
}
.carouselDot {
  width: $dot-size;
  height: $dot-size;
  margin-right: $dot-size;
  background: $color--featured-property-dot-background;
  border-radius: 50%;

  &:last-child {
    margin: 0;
  }
}

.carouselDotActive {
  background: $color--carousel-active-dot-background;
}

// Desktop layout:
@media (min-width: $breakpoint--small) {
  .propertyInfoOverlay {
    z-index: $z-index--properties-carousel-info-overlay;
    position: absolute;
    padding: ($measurement--grid-unit * 6) ($measurement--grid-unit * 8);
    left: ($measurement--side-gutter * 3);
    top: 0;
    transform: translateY(-50%);
    border-radius: 2px;
    width: 600px;
  }
}
