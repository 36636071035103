

.premium-discount-component {
  color: $orange;
  .premium-discount-text {
    .premium-discount {
      font-weight: normal;
      font-size: 13px;
      vertical-align: middle;
      margin-left: 8px;
      font-weight: 500;
      @media (max-width: $mobile-small) {
        margin-left: 6px;
        font-size: 11px;
      }
    }
  }
  .premium-discount-bar {
    padding-top: 15px;
    .premium-discount-bar-title {
      font-weight: bold;
      font-size: 16px;
    }
    .premium-discount-bar-value {
      font-size: 16px;
      font-weight: bold;
      position: relative;
      font-weight: 400;
      text-align: right;
    }
  }
}