@import '~src/styles/measurements';
@import '~src/styles/breakpoints';

.welcomeBack {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ($measurement--grid-unit * 8) 0;
  text-align: center;
}

.continueButton {
  width: 100%;
}

@media (min-width: $breakpoint--small) {
  .continueButton {
    width: auto;
  }
}
