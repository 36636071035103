

.property-hover {
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: right;
  width: 100%;
  .icn-small {
    padding: 0px 10px;
  }
  ul {
    display: inline-block;
    font-weight: 500;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: #FFF;
      display: inline-block;
      padding: 3px 10px;
      text-align: center;
      .icon {
        font-size: 22px;
      }
    }
  }
  .debt {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}
