

.signup-form {
  color: #FFF;
  border-radius: 1px;
  background-color: $very-dark-blue;
  padding: 30px 40px;
  input {
    border: 1px solid $light-blue !important;
    border-radius: 1px !important;
    background-color: $dark-blue !important;
    color: white !important;
  }
  ::-webkit-input-placeholder {
    color: #FFF;
    font-weight: 400;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #FFF;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #FFF;
    font-weight: 400;
  }
  :-ms-input-placeholder {
    color: #FFF;
    font-weight: 400;
  }

  button {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-top: 24px;
    text-transform: uppercase;
    padding: 10px;
    .fa {
      font-size: 21px;
    }
  }
  input[type=checkbox] {
    visibility: hidden;
  }
  .checkbox {
    position: relative;
    padding-left: 25px;
    font-size: 13px;
    a {
      color: $light-blue;
      text-decoration: underline;
    }
    label {
      cursor: pointer;
      position: absolute;
      width: 16px;
      min-height: 16px;
      top: 0;
      left: 0;
      padding: 0;
      background-color: #3A5A69;
      border: 1px solid $light-blue;
      border-radius: 1px;

    }
    input[type=checkbox]:checked + label {
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 6px;
        background: transparent;
        top: 3px;
        left: 2px;
        border: 3px solid #E8E8E8;
        border-top: none;
        border-right: none;
        border-radius: 2px;
        transform: rotate(-45deg);
      }
    }
  }
  .popover {
    font-weight: 400;
    background-color: $light-blue;
    border: 0;
    border-radius: 1px;
    color: $very-dark-blue;
    .popover-content {
      ul {
        margin: 0;
        padding-left: 20px;
      }
    }
    .arrow:after {
      border-top-color: $light-blue;
    }

  }

  @media (max-width: $small) {
    padding: 30px 13px;
    button {
      font-size: 12px !important;
    }
  }
}

.sign-up-header {
  position: relative;
  font-weight: 400;
  background-color: rgba(11, 97, 140, 0.80);
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 40px;
  padding-bottom: 20px;
  padding-top: 1px;
  h2 {
    font-weight: 400;
    color: #C1DCEF;
    @media (max-width: $medium) {
      font-size: 27px;
    }
  }
  p {
    margin-top: 20px;
  }
  .benefit {
    padding-left: 30px;
    position: relative;
    color: #FFF;
    font-size: 16px;
    margin-top: 10px;
  }
  .fa {
    position: absolute;
    top: 2px;
    left: 0;
    color: $orange;
  }
  .info {
    display: inline-block;
  }
  @media (max-width: $small) {
    padding: 1px 15px;
    padding-bottom: 20px;
    h2 {
      font-size: 20px;
    }
    .benefit {
      font-size: 16px;
      padding-left: 20px;
    }
  }
}
