.slider-label {
  color: #000;
  .popover-container {
    color: $blue;
  }
}

.slider-group {
  padding: 0 15px;

  .slide-select .rc-slider .rc-slider-handle {
    margin-top: -10px;
  }
  .slide-select .rc-slider .rc-slider-rail {
    background-color: $grey-200;
  }
  .slide-select .rc-slider .rc-slider-track {
    margin-top: -5px;
  }
  img {
    margin-right: 10px;
    width: 25px;
  }

  .percent-input {
    margin-bottom: 3px;
  }

  .slide-value {
    float: right;
    padding: 2px 10px;
    position: relative;
    top: -3px;
    width: 70px;
  }

  &:after {
    clear: both;
    content: " ";
    display: table;
  }

  .gearing-effect {
    padding-top: 30px;

    .value {
      font-weight: bold;
    }
  }
}

.slide-select {
  margin-top: 10px;

  .rc-slider {
    background-color: $grey-200;
    height: 10px;
    margin-top: 5px;

    .rc-slider-track {
      background-color: $orange;
    }

    .rc-slider-track {
      height: 10px;
      margin-top: -2px;
    }

    .rc-slider-handle {
      border: solid 2px $orange;
      height: 20px;
      margin-top: -10px;
      width: 20px;
    }

    .rc-slider-dot {
      background: $orange-active;
      border: solid 2px $orange-active;
      border-radius: 0;
      height: 14px;
      width: 1px;

      &.rc-slider-dot-active {
        border-color: $orange-active;
      }
    }

    .rc-slider-mark {
      display: none !important;
      font-size: 11px;
      top: 16px;
    }
  }

  .rc-description {
    color: #aaa;
    font-size: 11px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
  }
  .error {
    color: red;
    font-size: 15px;
    padding: 30px 0 0 0;
    text-align: right;
  }
}
