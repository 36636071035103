  .react-shape, .rect-shape, .round-shape, .text-row, .text-block, .media-block {
    animation: react-placeholder-pulse 1.5s infinite;
  }

  @keyframes react-placeholder-pulse {
    0% {
      opacity: .6; }
    50% {
      opacity: 1; }
    100% {
      opacity: .6; }
  }

