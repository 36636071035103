.contentContainer {
  margin: 0 auto;
  max-width: 700px;
}

.image {
  display: block;
  margin: 0 auto;
  max-width: 500px;
}
