

// ToDo: Card for Global Colours/Sort Out Colour Pallette
$table-green: #63d970;
$table-blue: #05b1fb;
$table-red: #f60221;
$table-grey: #ddd;
.panel-financials-table.panel-monthly-distributions {
    min-height: 517px;
    @media (max-width: $small) {
      min-height: auto;
    }
}
.panel-financials-table.panel-rental-distributions {
    min-height: 517px;
    @media (max-width: $small) {
      min-height: auto;
    }
    .body {
        padding: 0;
        .table-responsive {
            font-weight: 500;
            padding: 20px;
            .table tr:last-child td {
              border-top: 1px solid #000;
            }
            .table tr td .fa-fw {
                &.green {
                    color: $table-green;
                }
                &.blue {
                    color: $table-blue;
                }
                &.red {
                    color: $table-red;
                }
                &.grey {
                    color: $table-grey;
                }
            }
            @media (max-width: $large) {
                .table {
                    tr td {
                        font-size: 13px;
                    }
                }
            }
            @media (max-width: $small) {
                margin-bottom: 0;
            }
        }
        .rental-yields {
            background: #f7f7f7;
            padding: 20px;
            border-top: solid 1px #ccc;
            text-align: center;
            font-weight: 500;
            color: $off-black;
            font-size: 20px;
            small {
                margin-top: 7px;
                display: block;
                margin-bottom: 10px;
                color: #777;
                font-size: 13px;
                font-weight: 400;
                @media (max-width: $small) {
                    font-size: 11px;
                }
            }
            @media (max-width: $large) {
                .col-xs-6 {
                    padding: 0;
                }
            }
        }
        .rental-stats {
            padding: 20px;
            border-top: solid 1px #ccc;
            img {
                @media (min-width: $medium) and (max-width: $large - 1) {
                    margin: 8px 0 4px;
                }
            }
            .col-xs-8 {
                font-weight: 500;
                color: $off-black;
                font-size: 17px;
                small {
                    margin: 14px 0 10px;
                    display: block;
                    color: $green;
                    text-transform: uppercase;
                    font-size: 12px;
                }
                @media (max-width: $small) {
                    font-size: 16px;
                    small {
                        margin: 6px 0;
                    }
                }
            }
        }
    }
}

.monthly-distributions {
  .asterisk-info, .disclaimer {
    display: block;
    text-align: center;
  }
  .asterisk-info {
    margin-top: 15px;
  }
}
