@import '~src/styles/colors';
@import '~src/styles/breakpoints';

.dashboard__portfolio-container {
  margin-bottom: 16px;
}

.dashboard__plan-totals {
  margin-top: 32px;
}

.text-number__title,
.text-number__number {
  margin-bottom: 8px;
}

@media (min-width: $breakpoint--small) {
  .dashboard__layout {
    display: flex;
    flex-flow: row wrap;
  }

  .dashboard__portfolio-container {
    display: flex;
    width: 60%;
    margin-bottom: 0;
    padding-right: ($measurement--grid-unit * 4);
  }

  .dashboard__wallet-widget-container {
    width: 40%;
    display: flex;
  }
}
