

$congratulation-bar: 250px;
$congratulation-bar-mobile-large: 630px;
$congratulation-bar-mobile-medium: 620px;
$congratulation-bar-mobile-small: 580px;

$congratulation-mobile-small: 140px;
$congratulation-mobile-medium: 160px;
$congratulation-mobile-large: 160px;

#trade-succeed {
  padding-bottom: 60px;

  @media (max-width: $medium) {
    .congratulations {
      line-height: 365px;
      width: 75%;
    }
  }

  .congratulation-bar {
    background-color: $very-dark-blue;
    height: $congratulation-bar;
    position: relative;
    @media (max-width: $mobile-large) {
      height: $congratulation-bar-mobile-large !important;
    }
    @media (max-width: $mobile-medium) {
      height: $congratulation-bar-mobile-medium !important;
    }
    @media (max-width: $mobile-small) {
      height: $congratulation-bar-mobile-small !important;
    }
    .property-image {
      background-position: center;
      background-size: cover;
      height: $congratulation-bar;
      position: absolute;
      right: 0;
      top: 0;
      width: 390px;
      @media (max-width: $medium) {
        display: none;
      }
      @media (max-width: $mobile-large) {
        background-position: center;
        background-size: cover;
        display: inline-block !important;
        position: absolute;
        width: 100%;
      }
    }

    img.trade-confirmation {
      display: inline-block;
      height: 150px;
      margin-right: 30px;
      vertical-align: middle;
      @media (max-width: $mobile-large) {
        display: inline-block;
        height: 100%;
        margin: 0;
        padding: 20px 30%;
        width: 100%;
      }
    }
    .congratulations {
      color: #FFF;
      display: inline-block;
      height: $congratulation-bar;
      line-height: 365px;
      vertical-align: middle;
      width: 52%;
      @media (max-width: $medium-large) {
        line-height: 400px;
        width: 45%;
      }
      @media (max-width: $medium) {
        line-height: 373px;
        width: 66%;
      }
      @media (max-width: $mobile-large) {
        font-size: 16px !important;
        height: $congratulation-mobile-large !important;
        line-height: 30px !important;
        width: auto;
      }
      @media (max-width: $mobile-medium) {
        height: $congratulation-mobile-medium !important;
      }
      @media (max-width: $mobile-small) {
        height: $congratulation-mobile-small !important;
      }
      .congratulations-text {
        display: inline-block;
        font-size: 24px;
        line-height: 40px !important;
        @media (max-width: $medium-large) {
          font-size: 26px;
        }
        @media (max-width: $mobile-large) {
          font-size: 24px !important;
          line-height: 35px !important;
          text-align: center;
        }
        @media (max-width: $mobile-medium) {
          font-size: 22px !important;
          line-height: 35px !important;
        }
        @media (max-width: $mobile-small) {
          font-size: 18px !important;
          line-height: 30px !important;
        }
      }
      .buy {
        @media (max-width: $mobile-large) {
          font-size: 22px !important;
        }
        @media (max-width: $mobile-medium) {
          font-size: 20px !important;
        }
        @media (max-width: $mobile-small) {
          font-size: 16px !important;
        }
      }
      .confirmation-email-text {
        display: block;
        font-size: 16px;
        line-height: 23px;
        margin-top: 15px;
        @media (max-width: $mobile-large) {
          font-size: 18px !important;
          line-height: 25px !important;
        }
        @media (max-width: $mobile-medium) {
          font-size: 16px !important;
          line-height: 22px !important;
        }
        @media (max-width: $mobile-small) {
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .actions {
    font-size: 16px;
    margin-top: 50px;
  }

  .tfn-box {
    border: 1px solid #DDD;
    margin-top: 40px;
    padding: 40px;

  }

  .facebook-share-on-purchase-succeed {
    text-align: center;
    .title {
      color: $dark-gray;
      font-size: 25px;
    }
    .image {
      height: auto;
      width: 100px;
    }
  }

}
