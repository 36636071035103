

.property-future {
  .header {
    height: 81px;
  }
  @media(max-width: $mobile-landscape) {
    .header {
      border-bottom: 1px solid $dark-blue;
      height: auto;
      .investors-box {
        display: none;
      }
    }
    .body {
      height: 225px;
      font-size: 16px;
      .overlay {
        top: 0;
      }
    }
    .property-hover {
      display: none;
    }
  }
}

.property-card {
  border: 1px solid #d5d9dd;
  .header {
    color: #FFF;
    background-color: $very-dark-blue;
    padding: 10px 16px;
    font-weight: 400;
    line-height: 17px;
      font-size: 13px;
    position: relative;
    .property-code {
      font-size: 26px;
      color: $light-blue;
      line-height: 27px;
    }
  }

  .picture-box {
    display: block;
    position: relative;
    overflow: hidden;
    .property-hover {
      background-color: rgba(0, 61, 105, 0.7);
    }
  }
  .picture {
    height: 200px;
    background-size: cover;
    background-position: center;
    transition: all .2s $transition--standard-function;

  }
  .picture-box:hover {
    .picture {
      transform: scale(1.1);
    }
  }

  .body {
    background-color: #FFF;
    position: relative;

    .body-content {
      padding: 15px;
    }


    .right-border {
      border-right: 1px solid #DDD;
    }
    .right-border__mobile {
      @media (max-width: $small) {
        border-right: 1px solid #DDD;
      }
    }
    .grey-line {
      margin: 14px 0;
    }

    .financial-info-item {

      .box-img {
        float: left;
        margin-right: 8px;
        margin-bottom: 20px;
        width: 28px;
        img {
          max-height: 20px;
          &.brick-bordered-icon {
            width: 28px;
          }
        }
      }


      .text {
        font-weight: 500;
        font-size: 12px;
        color: $dark-blue;
        line-height: 1;
        height: 30px;
        .sub-text {
          font-weight: 400;
        }
      }
      .text__brick-valuation {
        @media (max-width: $small) {
          padding: 0 20px;
        }
      }
      .text__settlement {
        padding-right: 30px;
        @media (max-width: $small) {
          padding: 0 22px;
        }
      }
      .text__historical-growth {
        @media (max-width: $small) {
          padding: 0 4px;
        }
      }
      .value {
        font-size: 18px;
        color: #777;
        line-height: 1;
      }


      @media (max-width: $small) {
        text-align: center;
      }

    }

    .checkout-info {
      @media (max-width: $small) {
        padding: 10px;
        border-top: 1px solid #DDD;
        border-bottom: 1px solid #DDD;
        position: relative;
        top: 1px;
      }
    }
    .checkout-info__light-blue {
      @media (max-width: $small) {
        background-color: $very-light-blue;
      }
    }
    .checkout-info__light-orange {
      @media (max-width: $small) {
        background-color: $light-orange;
      }
    }

    .general-info {
      padding: 20px 0px;
      text-align: center;
      min-height: 96px;
      .icn-brick-border {
        padding: 8px 14px !important;
          display: block;
          margin-bottom: 1px;
      }
    }
    .general-info::after {
      content: " ";
      display: table;
      clear: both;
    }


    .action-button{
      display: block;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      line-height: 26px;

      @media (max-width: $small) {
        margin: 0;
        padding: 5px 0;
        width: 100%;
      }
      .fa {
        position: relative;
        top: 2px;
        font-size: 25px;
        line-height: 0px;
        margin-left: 12px;

        @media (max-width: $small) {
          line-height: 1;
          top: 0;
          margin-left: 0;
        }
      }

    }

    .funded-value {
      color: $blue;
    }

    .investor-icon {
      display: block;
        height: 15px;
        margin: auto;
        margin-top: 3px;
    }
  }

  .overlay {
    display: none;
  }

  .very-bottom {
    font-weight: 500;
    font-size: 12px;
    padding: 10px 0;
    .bricks-sold-progress-bar {
      position: relative;
      top: 3px;
    }
  }


  @media(max-width: $mobile-landscape) {
    border-left: 0;
    border-right: 0;
    .header {
      font-size: 12px;
      .property-address {
        display: inline-block !important;
      }
      .property-code {
        display: inline-block;
        line-height: 1;
        font-size: 22px;
        margin-right: 4px;
      }

      .flag {
          top: 43px;
          left: 15px;
          z-index: 1;
          padding-top: 5px;
      }
      .investors-box {
        text-transform: uppercase;
          position: absolute;
          top: 10px;
          color: #FFF;
          right: 10px;
          line-height: 1;
          font-size: 9px;
        .investor-icon {
          height: 23px;
          float: left;
          color: $blue;
          margin-right: 4px;
        }
        div {
          font-size: 15px;
        }
      }
    }
    .body {
      .body-content {
        padding: 10px 15px;


        @media (max-width: $small) {
          padding-bottom: 0;
        }
        .financial-info {
          margin-bottom: 10px;
        }
      }
      .text {
        font-size: 11px;
        line-height: 1;
        font-weight: 400;
      }
      .col-xs-4 {
        padding: 0
      }
      .property-button {
        font-size: 11px;
        text-align: left;
        font-weight: 400;
        color: #FFF;
          margin-right: 5px;
          padding: 5px 6px;
          line-height: 1;
          position: relative;
          top: -5px;
          display: block;
          margin-top: 4px;
          &.orange {
          background-color: $orange;
          }
          &.blue {
          background-color: $blue;
          }
          .value {
            color: #FFF;
            padding: 0;
            font-size: 20px;
            margin-top: 6px;
            span {
              font-size: 16px;
            }
          }
        img {
          height: 26px;
          position: absolute;
          top: 10px;
            right: 5px;
        }
        &:hover {
          text-decoration: none;
          background-color: #ffa459;
        }
        .no-bricks {
          font-size: 11px;
        }
      }
    }
  }
}

.property-card__brick-price-box {
  margin-top: 14px;
  @media (max-width: $small) {
    margin-top: 0;
  }
}

.property-future .overlay {
  display: block;
  position: absolute;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  top: 30px;
  z-index: 1;
  height: 100%;
  padding-top: 30px;
}
.property-future .body-content {
  filter: blur(5px);
  -webkit-filter: blur(5px); /* Chrome, Safari, Opera */
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}
