@import '~src/styles/colors';
@import '~src/styles/breakpoints';
@import '~src/styles/fonts';
@import '~src/styles/measurements';

.bodyText {
  color: $color--body-text-on-light-bg;
  font-family: $font--bx-standard;
  font-size: $measurement--body-text-size;
  line-height: $measurement--body-text-line-height;
  margin: 0;
}

.bodyText--align-left {
  text-align: left;
}

.bodyText--align-center {
  text-align: center;
}

.bodyText--align-right {
  text-align: right;
}

.bodyText--align-justify {
  text-align: justify;
}

.bodyText--color-salmon {
  color: $color--brand-salmon;
}

.bodyText--color-white {
  color: $color--white;
}

.paragraph {
  line-height: $measurement--paragraph-text-line-height;
}

.bodyText--large-text {
  font-size: $measurement--large-body-text-size;
}
.bodyText--large-line-height {
  line-height: $measurement--large-body-text-line-height;
}

.bodyText--small-text {
  font-size: $measurement--small-body-text-size;
}
.bodyText--small-line-height {
  line-height: $measurement--small-body-text-line-height;
}

.bodyText--x-small-text {
  font-size: $measurement--x-small-body-text-size;
}
.bodyText--x-small-line-height {
  line-height: $measurement--x-small-body-text-line-height;
}

.centered {
  text-align: center;
}

.finePrint {
  color: $color--fine-print-text;
  display: block;
  font-size: $measurement--x-small-body-text-size;
  line-height: $measurement--x-small-body-text-line-height;
}
